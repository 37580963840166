import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../Store';

function ProductMiniSmall(props) {
  const { product } = props;

  const brandId = typeof product.brand === 'object' ? product.brand._id : product.brand;
  const categoryId = typeof product.category === 'object' ? product.category._id : product.category;

  const { state, dispatch: ctxDispatch } = useContext(Store);

  const {
    cart: { cartItems },
  } = state;


  const handleClose = () => {
    ctxDispatch({ type: 'SET_MODALSHOW_OFF' });
  };

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  const [categoriesSlug, setCategoriesSlug] = useState([]);
  const [brandsSlug, setBrandsSlug] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/categories');
        const data = await response.json();
        const currentCategory = data.find(category => category._id === categoryId);
        setCategoriesSlug(currentCategory.slug);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await fetch('/api/brands');
        const data = await response.json();
        const currentBrand = data.find(brand => brand._id === brandId);
        setBrandsSlug(currentBrand.slug);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, [brandId, categoryId]);

  return (
    <Card className="position-relative" bg="light">
      <Link to={`/product/${product.slug}`} onClick={handleClose} >
     
      <div className="row p-1">
          <div className="col-md-4">
            <img src={product.image} className="img-fluid rounded-start" alt={product.name} />
          </div>
          <div className="col-md-8 m-auto text-dark">
          <p className="card-text"><small>{product.name}</small></p>
          <p className='text-success fw-bold mb-0'>₹ {product.price}</p>
          </div>
         
      </div>
      </Link>
    </Card>
  );
}
export default ProductMiniSmall;

import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { Container } from 'react-bootstrap';
import SideBar from '../components/SideBar';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false };
    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function OrderListScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, orders, loadingDelete, successDelete }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
  }, [userInfo, successDelete]);

  const deleteHandler = async (order) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        dispatch({ type: 'DELETE_REQUEST' });
        await axios.delete(`/api/orders/${order._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('order deleted successfully');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: 'DELETE_FAIL',
        });
      }
    }
  };

  return (

    <Container className='ps-1 pe-3 mw-100'>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>
          <Row className="mt-3 mb-3">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Orders
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          <div className='text-dark bg-light p-3 rounded'>
            <Row>
              <Col>
                <h5>Orders</h5>
              </Col>
              <Col className="col text-end">
              
              </Col>
            </Row>

          

            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className='table-responsive  mt-3 text-dark'>
                <table className="table text-dark">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>USER</th>
                      <th>DATE</th>
                      <th>ITEMS</th>
                      <th>TOTAL</th>
                      <th>PAID</th>
                      <th>STATUS</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td>{order.orderId}</td>
                        <td>{order.user ? order.user.name : 'DELETED USER'}</td>
                        <td>{order.createdAt.substring(0, 10)}</td>
                        <td>{order.orderItems.reduce((a, c) => a + c.quantity, 0)}</td>
                        <td>{order.totalPrice.toFixed(2)}</td>
                        <td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>

                        <td>
                          {order.status}
                        </td>
                        <td>
                          <Button
                            type="button"
                            variant="success"
                            onClick={() => {
                              navigate(`/order/${order.orderId}`);
                            }}
                          >
                            Details
                          </Button>
                      
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
               
              </div>
            )}
          </div>
        </Col>

      </Row>

    </Container>

  );
}

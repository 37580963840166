import React, { useContext, useEffect, useState, useReducer } from 'react'
import { Modal, Button, Card, Form, Row, Col, ListGroup, Badge } from 'react-bootstrap';
import axios from 'axios';
import { Store } from "../Store";
import { toast } from 'react-toastify';
import { getError } from "../utils";
import LoadingBox from './LoadingBox';
import MessageBox from './MessageBox';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import ProductAttribute from './ProductAttribute';

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return { ...state, loading: false };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'UPDATE_REQUEST':
            return { ...state, loadingUpdate: true };
        case 'UPDATE_SUCCESS':
            return { ...state, loadingUpdate: false };
        case 'UPDATE_FAIL':
            return { ...state, loadingUpdate: false };
        case 'UPLOAD_REQUEST':
            return { ...state, loadingUpload: true, errorUpload: '' };
        case 'UPLOAD_SUCCESS':
            return {
                ...state,
                loadingUpload: false,
                errorUpload: '',
            };
        case 'UPLOAD_FAIL':
            return { ...state, loadingUpload: false, errorUpload: action.payload };

        default:
            return state;
    }
};





export default function Variant(props) {
    const { state } = useContext(Store);
   
    const { variant } = props;
    const { productId } = props;
    
    const { userInfo } = state;
    const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
        useReducer(reducer, {
            loading: false,
            error: '',
        });
    const [sku, setSku] = useState('');
    const [price, setPrice] = useState('');
    const [priceMrp, setPriceMrp] = useState('');
    const [image, setImage] = useState('');
    const [images, setImages] = useState([]);
    const [name, setName] = useState('');
    useEffect(() => {
        setName(variant.variant.name);
        setSku(variant.sku);
        setPrice(variant.price);
        setPriceMrp(variant.priceMrp);
        setImage(variant.image);
        setImages(variant.images);
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch({ type: 'UPDATE_REQUEST' });
            await axios.put(
                `/api/products/${productId}/variant`,
                {
                    sku,
                    image,
                    images,
                    price,
                    priceMrp,
                    variation: variant.variant._id,
                    type: 'update'
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            dispatch({
                type: 'UPDATE_SUCCESS',
            });
            toast.success('Variant Updated Successfully');

        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPDATE_FAIL' });
        }
    };
    const uploadFileHandler = async (e, forImages) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        try {
            dispatch({ type: 'UPLOAD_REQUEST' });
            const { data } = await axios.post('/api/upload', bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: 'UPLOAD_SUCCESS' });

            if (forImages) {
                setImages([...images, data.secure_url]);
            } else {
                setImage(data.secure_url);
            }

            toast.success('Image uploaded successfully.');
        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
        }
    };
    const deleteFileHandler = async (fileName, f) => {

        setImages(images.filter((x) => x !== fileName));
        toast.success('Image removed successfully');
    };

    const isActiveUpdateHandler = async (variant) => {

        let status = variant.isActive;
        if (status === true) {
            status = false;
        }
        else if (status === false) {
            status = true;
        }
        try {
            dispatch({ type: 'UPDATE_REQUEST' });
            await axios.put(
                `/api/products/${productId}/variant`,
                {
                    variation: variant.variant._id,
                    isActive: status,
                    type: 'statusActive'
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            dispatch({
                type: 'UPDATE_SUCCESS',
            });
            toast.success('status updated successfully');
            variant.isActive = status;
        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPDATE_FAIL' });
        }
    };


    return (
        <div className=' p-3 rounded'>



            <Card className="mt-3" bg="light">
                <Card.Header className='bg-light align-middle'>
                    <Button className="float-start" size='sm' variant='light' >
                        {variant.isActive === true && <BootstrapSwitchButton checked={true} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(variant)} />}
                        {variant.isActive === false && <BootstrapSwitchButton checked={false} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(variant)} />}
                    </Button>

                    <Button onClick={submitHandler} className="float-end" disabled={loadingUpdate} type="buttom" size="sm" variant='dark'>
                        Update
                    </Button>


                </Card.Header>
                <Card.Body >
                    <Form onSubmit={submitHandler}>
                        <Row className="mt-1 mb-3">
                            <Col md={6} sm={12}>
                                <Card className=" mb-3 p-0">
                                    <Card.Header className='bg-secondary text-light text-bold'>
                                        Variant Name : 
                                        <Badge className='fs-6 ms-3' bg="dark">{name}</Badge>

                                    </Card.Header>
                                    <Card.Body className='border-0 p-0'>
                                        <ListGroup className='border-0 p-0'>
                                            <ListGroup.Item className='bg-white text-dark'>
                                                <Row>
                                                    <Col className='m-auto' md={3}>M.R.P</Col>
                                                    <Col md={9}>
                                                        <Form.Control
                                                            value={priceMrp}
                                                            onChange={(e) => setPriceMrp(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item className='bg-white text-dark'>
                                                <Row>
                                                    <Col className='m-auto' md={3}>PRICE</Col>
                                                    <Col md={9}>
                                                        <Form.Group className="mb-3" controlId="price">
                                                            
                                                            <Form.Control
                                                                value={price}
                                                                onChange={(e) => setPrice(e.target.value)}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item className='bg-white text-dark'>
                                                <Row>
                                                    <Col className='m-auto' md={3}>SKU</Col>
                                                    <Col md={9}>
                                                        <Form.Control
                                                            value={sku}
                                                            onChange={(e) => setSku(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} sm={12}>
                                <Card className="mb-3">
                                    <Card.Header className='bg-secondary text-light'>
                                        Image
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={3}>
                                                <Form.Group className="mb-3" controlId="image">
                                                    <img
                                                        src={image}
                                                        className="img-fluid rounded"
                                                    ></img>{' '}


                                                </Form.Group>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Group className="mb-3" controlId="imageFile">
                                                    <Form.Label>Upload Image</Form.Label>
                                                    <Form.Control type="file" onChange={uploadFileHandler} />
                                                    {loadingUpload && <LoadingBox></LoadingBox>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} sm={12}>
                                <Card className="mb-3">
                                    <Card.Header className='bg-secondary text-light'>
                                        Additional Images
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group className="mb-3" controlId="additionalImage">
                                            {images.length === 0 && <MessageBox className="bg-light">No image</MessageBox>}
                                            <ListGroup variant="flush" className='row'>
                                                <Row>
                                                    {images.length > 0 && images.map((x) => (
                                                        <Col md={3} sm={12} className='mb-3'>
                                                            <div class="card" key={x}>
                                                                <img src={x} class="card-img" alt="..." />
                                                                <div class="card-img-overlay p-1 text-end">
                                                                    <Button className='btn-sm' variant="secondary" onClick={() => deleteFileHandler(x)}>
                                                                        <i className="fa fa-times-circle"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </ListGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="additionalImageFile">
                                            <Form.Label>Upload Aditional Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => uploadFileHandler(e, true)}
                                            />
                                        </Form.Group>


                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </Form>

                    <ProductAttribute variant={variant} productId={productId}/>

                </Card.Body>
                {/* <Card.Footer>
                    <Button className="float-end" disabled={loadingUpdate} type="submit" size="sm" variant='dark'>
                        Update
                    </Button>
                </Card.Footer> */}
            </Card>




        </div>
    )
}



import React, {useState, useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';
import { Container } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../../components/SideBar';
const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        promos: action.payload.promos,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreate: true };
    case 'CREATE_SUCCESS':
      return {
        ...state,
        loadingCreate: false,
      };
    case 'CREATE_FAIL':
      return { ...state, loadingCreate: false };

    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false, successDelete: false };

    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function PromoCodeListScreen() {
  const [
    {
      loading,
      error,
      promos,
      pages,
      loadingCreate,
      loadingDelete,
      successDelete,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [promoCodes, setPromoCode] = useState([]);

  useEffect(() => {
    const fetchPromos = async () => {
      try {
        const {data} = await axios.get(`api/promocodes/`);
        setPromoCode(data.promoCodes);
      } catch (error) {
        console.error('Error fetching promo codes:', error);
      }
    };

    fetchPromos();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/promos/admin?page=${page} `, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) { }
    };

    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
  }, [page, userInfo, successDelete]);


  const deleteHandler = async (promo) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(`/api/promocodes/${promo._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('Promocode deleted successfully');
        navigate('/admin/dashboard');
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  return (
    <Container className='ps-1 pe-3 mw-100'>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>

          <Row className="mt-3 mb-3">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Promocode
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          <div className='text-dark bg-light p-3 rounded'>
            <Row>
              <Col>
                <h5>Promocodes</h5>
              </Col>
              <Col className="col text-end">
                <div>
                  <Link className='text-center' to="/admin/promocode/create">
                    <Button type="button" size='sm' variant='success'>
                      Create Promocode
                    </Button>
                  </Link>

                </div>
              </Col>
            </Row>

            {/* {loadingCreate && <LoadingBox></LoadingBox>} */}

            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className='table-responsive  mt-3 '>
                <table className="table text-dark">
                  <thead>
                    <tr>
                      <th></th>
                      <th>NAME</th>
                      <th>PERCENTAGE</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promoCodes.map((promo) => (
                      <tr className='align-middle' key={promo._id}>
                        <td></td>
                        <td>{promo?.name}</td>
                        <td>{promo?.percentage}</td>
                        <td>
                          <Button
                            type="button"
                            variant="light"
                            onClick={() => navigate(`/admin/promocode/edit/${promo._id}`)}
                          >
                            <i className="fas fa-edit fa-solid"></i>
                          </Button>
                          &nbsp;
                          <Button
                            type="button"
                            variant="light"
                            onClick={() => deleteHandler(promo)}
                          >
                            <i className="fas fa-trash fa-solid"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  {[...Array(pages).keys()].map((x) => (
                    <Link
                      className={x + 1 === Number(page) ? 'btn btn-secondary text-bold' : 'btn'}
                      key={x + 1}
                      to={`/admin/promos?page=${x + 1}`}
                    >
                      {x + 1}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

    </Container>

  );
}

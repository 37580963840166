import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, } from 'react-router-dom';
import Chart from 'react-google-charts';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import SideBar from '../components/SideBar';

import Breadcrumb from 'react-bootstrap/Breadcrumb';


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default function DashboardScreen() {

  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { state } = useContext(Store);
  const { userInfo } = state;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/orders/summary', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
       
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userInfo]);

  return (

    <Container className='ps-1 pe-3 mw-100'>
    <Row>
        <Col md={2}>
            <SideBar />
        </Col>
        <Col md={10}>
        <Row className="mt-3 mb-3">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className='text-center' to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Dashboard
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (

              <>
           
                <Row>
                  <Col className='mb-3'>
                    <div className="my-3">
                      <h5>Sales</h5>
                      {summary.dailyOrders.length === 0 ? (
                        <MessageBox>No Sale</MessageBox>
                      ) : (
                        <Chart
                          width="100%"
                          height="400px"
                          chartType="AreaChart"
                          loader={<div>Loading Chart...</div>}
                          data={[
                            ['Date', 'Sales'],
                            ...summary.dailyOrders.map((x) => [x._id, x.sales]),
                          ]}
                        ></Chart>
                      )}
                    </div>
                  </Col>
                
                </Row>
              </>
            )}
        </Col>
            </Row>

        </Container>
  );
}

import React from "react";
import { COffcanvas } from "@coreui/react"; // Import COffcanvas
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

const NavigationForMobile = ({ visible, setVisible, isDark,DarkImage }) => {
  // Calculate the background color based on the isDark state
  const backgroundColor = isDark ? "#343a40" : "#f8f9fa"; // Example colors for dark and light modes, you can adjust these as needed

  return (
    <>
      <div className="col-1 d-block d-lg-none">
        <COffcanvas
          placement="start"
          scroll={true}
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ backgroundColor: backgroundColor,width:"80%" }}
        >
          <div className="offcanvas-body" style={{ position: "relative" }}>
            <button
              type="button"
              className="offcanvas-close"
              onClick={() => setVisible(false)}
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              &times;
            </button>
            <div className="logo-container">
              <Link to="/" className="navbar-brand">
                <img
                  src={
                    isDark
                      ? "https://res.cloudinary.com/dzfznnc5l/image/upload/v1688282908/natax6xm7aztxo3rf7gc.png"
                      : DarkImage // Replace DarkImage with your light mode logo source
                  }
                  className="d-inline-block align-text-top logo"
                  alt="Logo"
                  style={{width:"164px",height:"45px"}}
                />
              </Link>
            </div>
            {/* Your off-canvas menu content goes here */}
            <Navigation />
          </div>
        </COffcanvas>
      </div>
    </>
  );
};

export default NavigationForMobile;

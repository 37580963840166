import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../Store';
import { Badge } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_300" + li[1];
  return newimg;
}

function ProductMini(props) {
  const { product } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);

  const {
    cart: { cartItems },
  } = state;

  const brandId = typeof product.brand === 'object' ? product.brand._id : product.brand;
  const categoryId = typeof product.category === 'object' ? product.category._id : product.category;
  const handleClose = () => {
    ctxDispatch({ type: 'SET_MODALSHOW_OFF' });
  };

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  const [categoriesSlug, setCategoriesSlug] = useState([]);
  const [brandsSlug, setBrandsSlug] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/categories');
        const data = await response.json();
        const currentCategory = data.find(category => category._id === categoryId);
        setCategoriesSlug(currentCategory.slug);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await fetch('/api/brands');
        const data = await response.json();
        const currentBrand = data.find(brand => brand._id === brandId);
        setBrandsSlug(currentBrand.slug);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, [brandId, categoryId]);

  return (
    // <ScrollAnimation animateIn="animate__pulse" animatePreScroll={false} initiallyVisible={true} animate >
     
    // </ScrollAnimation>

<Card className="position-relative" bg="light">
<Link to={`/product/${product.slug}`} onClick={handleClose} >

  <div className="row p-1">
    <div className="col-md-6">
      <img src={ResImage(product.image)} className="img-fluid rounded-start" alt={product.name} />
    </div>
    <div className="col-md-6 m-auto">
      <p className="card-text text-dark"><small>{product.name}</small></p>
      <p className='product-price' style={{ textAlign: 'left' }}>
        <span className='text-decoration-line-through text-muted me-1' style={{ fontSize: 'medium' }}>
          ₹{IndianNumber(product.priceMrp)}
        </span>
        <span className='me-1'>
          ₹{IndianNumber(product.price)}
        </span>
        {/* <span className='text-decoration-line-through text-muted me-1' style={{fontSize: 'small'}}>
      ₹ {IndianNumber(product.priceMrp)}
    </span>
    
    <Badge bg="success">₹ {IndianNumber(product.price)}</Badge> */}
      </p>
    </div>

  </div>
</Link>
</Card>


  );
}
export default ProductMini;

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Button } from "react-bootstrap";
import Toggle from "./Toggle";

import Badge from "react-bootstrap/Badge";

import { Link } from "react-router-dom";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
function Navigation() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const [isDark, setIsDark] = useState(false);
  const { show, fullBox, cart, userInfo, sidebarIsOpen } = state;
  const { navIsOpen } = state;
  const [showMobileMenuRight, setShowMobileMenuRight] = useState(true);
  const handleShow = () => {
    if (navIsOpen === true) {
      ctxDispatch({ type: "SET_NAV_OFF" });
    } else {
      ctxDispatch({ type: "SET_NAV_ON" });
    }
    setShowMobileMenuRight(!showMobileMenuRight);
  };

  return (
    <>
      <Helmet></Helmet>
      <div className="APP text-uppercase" style={{ marginTop: "20px", marginLeft: "20px" }}>
        <nav className={`navbar navbar-expand-lg navbar-dark bg-dark py-1 ${isDark ? 'text-dark' : 'text-white'}`}>
          <div className="container-fluid p-0">
            <div
              id="navbarSupportedContent"
              className={
                "collapse navbar-collapse " + (navIsOpen ? "show" : "")
              }
              style={{
                ...(window.innerWidth < 768 && { marginTop: "100px" }),
              }}
            >
              <ul className="navbar-nav add-margin-for-mobile" style={{ margin: "0px", ...(window.innerWidth < 768 && { margin: "20px" }) }}>

                <li className="nav-item dropdown megamenu border-md-0 ">
                  {/* <a id="Rackets" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">Rackets</a> */}
                  <Link to="/badminton-rackets" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    Rackets
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-75">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">
                                  <Link to={{ pathname: '/yonex/badminton-rackets' }}>
                                    <li className='pb-1'>
                                      Yonex
                                    </li>
                                  </Link>

                                  <Link to={{ pathname: '/li-ning/badminton-rackets' }}>
                                    <li className=''>
                                      Li-Ning
                                    </li>
                                  </Link>

                                </ul>
                              </div>
                              <div className="col-lg-4 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Categories</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/attacking-badminton-rackets' }}>
                                    <li className='pb-1'>Attacking</li>
                                  </Link>


                                  <Link to={{ pathname: '/defensive-badminton-rackets' }}>
                                    <li className='pb-1'>Defensive</li>
                                  </Link>


                                  <Link to={{ pathname: '/balanced-badminton-rackets' }}>
                                    <li className=''>Balanced</li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-3 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=racket` }}>
                                                                        <li className='pb-1'>  View All </li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu ">
                  {/* <a id="Rackets" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">Shoes</a> */}
                  <Link to="/badminton-shoes" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    Shoes
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-75">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/yonex/badminton-shoes' }}>
                                    <li className='pb-1'>   Yonex    </li>
                                  </Link>


                                  <Link to={{ pathname: '/li-ning/badmiton-shoes' }}>
                                    <li className='pb-1'>    Li-Ning  </li>
                                  </Link>

                                </ul>
                              </div>
                              <div className="col-lg-4 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-75">Categories</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/professional-badminton-shoes' }}>
                                    <li className='pb-1'>Professional</li>
                                  </Link>


                                  <Link to={{ pathname: '/intermediate-badminton-shoes' }}>
                                    <li className='pb-1'>Intermediate</li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-3 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=shoe` }}>
                                                                        <li className='pb-1'>   View All</li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu ">
                  {/* <a id="Rackets" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">Shuttle</a> */}
                  <Link to="/badminton-shuttlecocks" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    Shuttle
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-75">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/yonex/shuttlecocks' }}>
                                    <li className='pb-1'>    Yonex   </li>
                                  </Link>


                                  <Link to={{ pathname: '/li-ning/shuttlecocks' }}>
                                    <li className='pb-1'>    Li-Ning  </li>
                                  </Link>

                                </ul>
                              </div>
                              <div className="col-lg-4 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-75">Categories</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/nylon-shuttlecocks' }}>
                                    <li className='pb-1'>   Nylon  </li>
                                  </Link>


                                  <Link to={{ pathname: '/feather-shuttlecocks' }}>
                                    <li className='pb-1'>   Feather</li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-3 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=shuttle` }}>
                                                                        <li className='pb-1'>  View All   </li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu ">
                  {/* <a id="Rackets" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">Apparel</a> */}
                  <Link to="/badminton-apparel" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    Apparel
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-75">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/yonex/badminton-apparel' }}>
                                    <li className='pb-1'>    Yonex </li>
                                  </Link>


                                  <Link to={{ pathname: '/li-ning/badminton-apparel' }}>
                                    <li className='pb-1'>  Li-Ning  </li>
                                  </Link>

                                </ul>
                              </div>
                              <div className="col-lg-4 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-75">Categories</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/badminton-apparel-t-shirt' }}>
                                    <li className='pb-1'>    T-Shirt    </li>
                                  </Link>


                                  <Link to={{ pathname: '/badminton-apparel-short' }}>
                                    <li className='pb-1'>  Short  </li>
                                  </Link>


                                  <Link to={{ pathname: '/badminton-apparel-polo' }}>
                                    <li>   Polo </li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-3 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=apparel` }}>
                                                                        <li className='pb-1'>  View All  </li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}

                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu ">
                  {/* <a id="Rackets" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">String</a> */}
                  <Link to="/badminton-string" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    String
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-50">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/yonex/badminton-string' }}>
                                    <li className='pb-1'>   Yonex </li>
                                  </Link>


                                  <Link to={{ pathname: '/li-ning/badminton-string' }}>
                                    <li>     Li-Ning </li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-6 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=string` }}>
                                                                        <li className='pb-1'>  View All</li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu ">
                  {/* <a id="Bags" href="#" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link text-uppercase mx-1">Bags</a> */}
                  <Link to="/badminton-bags" id="Rackets" className="nav-link text-uppercase mx-1 dropdown-toggle">
                    Bags
                  </Link>
                  <div aria-labelledby="dropdownMenuButton2" className="dropdown-menu border-0 p-0 m-0 w-50">
                    <div className="container">
                      <div className="row bg-dark rounded-0 m-0 shadow-sm">
                        <div className="row">
                          <div className="p-3">
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-50">Brands</h6>
                                <ul className="list-unstyled">

                                  <Link to={{ pathname: '/yonex/badminton-bags' }}>
                                    <li className='pb-1'>    Yonex   </li>
                                  </Link>


                                  <Link to={{ pathname: '/li-ning/badminton-bags' }}>
                                    <li>      Li-Ning </li>
                                  </Link>

                                </ul>
                              </div>
                              {/* <div className="col-lg-6 mb-4">
                                                                <h6 className="text-decoration-underline unline-offset font-weight-bold text-uppercase text-light pb-1 w-25">All</h6>
                                                                <ul className="list-unstyled">

                                                                    <Link to={{ pathname: '/search', search: `category=Bags` }}>
                                                                        <li className='pb-1'>  View All </li>
                                                                    </Link>

                                                                </ul>
                                                            </div> */}

                            </div>
                          </div>
                        </div>
                        {/* <div class="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div> */}
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item"><a href="" className="nav-link font-weight-bold text-uppercase">About</a></li> */}
              </ul>
              {/* <div
                className={
                  "col-lg-12 col-md-12 col-12 d-flex justify-content-around hide-mobile-menu d-lg-none"
                }
                style={{
                 marginTop:"20px"
                }}
              >
                
                {userInfo ? (
                  <Link
                    className="nav-link"
                    style={{ fontSize: "large" }}
                    to="/orders"
                  >
                    <button class="btn btn-dark d-flex  bg-none border-0 noevent">
                      <i className="fas fa-user fa-solid"></i>
                    </button>
                  </Link>
                ) : (
                  <Link
                    className="nav-link"
                    style={{ fontSize: "large" }}
                    to="/signin"
                  >
                    <button class="btn btn-dark d-flex   bg-none border-0 noevent">
                      <i className="fas fa-user fa-solid"></i>
                    </button>
                  </Link>
                )}

                {userInfo && userInfo.isAdmin && (
                  <Link
                    className="nav-link"
                    style={{ fontSize: "large" }}
                    to="/admin/dashboard"
                  >
                    <button class="btn btn-dark d-flex   bg-none border-0 noevent">
                      <i className="fas fa-bars"></i>
                    </button>
                  </Link>
                )}
              </div> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Navigation;

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../../Store';
import { getError } from '../../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Button from 'react-bootstrap/Button';
import EditorConvertToHTML from '../../components/Editor';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import SideBar from '../../components/SideBar';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};

export default function PromoCodeEditScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { id: promoId } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
    });

  const [name, setName] = useState('');
  const [percentage, setPercentage] = useState('');
  const [image, setImage] = useState('');
  const [imagePotrait, setImagePotrait] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [type, setType] = useState('');
  const [refSlug, setRefSlug] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/promocodes/promoCodeDetails/${promoId}`);
        setName(data.name);
        setPercentage(data.percentage);
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
      console.log({ link })
    };
    fetchData();
  }, [promoId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {

      await axios.put(
        `/api/promocodes/${promoId}/edit`,
        {
          _id: promoId,
          name,
          percentage,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Promocode Updated Successfully');
      navigate('/admin/promocode');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  return (
    <Container className='ps-1 pe-3 mw-100'>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>
          <Helmet>
            <title>Edit Promo</title>
          </Helmet>
          <Row className="mt-3 mb-3">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/promos">Promocode</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <h6>Edit Promocode</h6>

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <div className='text-dark bg-light p-3 rounded'>
              <Form onSubmit={submitHandler}>
                <Row className="mt-3 mb-3">
                  <Col md={6} sm={12} className='px-5'>

                  <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Promocode Name</Form.Label>
                      <Form.Control
                        value={name}
                        onChange={(e) => setName(e.target.value)}

                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12} className='px-5'>
                  <Form.Group className="mb-3" controlId="percentage">
                      <Form.Label>Promocode Percentage</Form.Label>
                      <Form.Control
                        type='number'
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.value)}
                      />
                    </Form.Group>


                  </Col>
                </Row>



                <Row className="mb-3 px-5 w-50">
                  <Button disabled={loadingUpdate} type="submit" variant='success'>
                    Submit
                  </Button>
                  {loadingUpdate && <LoadingBox></LoadingBox>}
                </Row>
              </Form>
            </div>

          )}
        </Col>
      </Row>

    </Container>
  );
}

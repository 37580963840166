import Spinner from 'react-bootstrap/Spinner';

export default function LoadingBox() {
  return (
    // <Spinner animation="border" role="status">
    //   <span className="visually-hidden">Loading...</span>
    // </Spinner>
    <div className='text-center'>
      <Spinner animation="grow" size="sm" variant="secondary" className='mx-2'/>
      <Spinner animation="grow" size="sm" variant="secondary" className='mx-2'/>
      <Spinner animation="grow" size="sm" variant="secondary" className='mx-2'/>
    </div>
     
  );
}

import React, { useContext, useEffect, useState, useReducer } from "react";
import {
  Modal,
  Button,
  Card,
  Form,
  Row,
  Col,
  ListGroup,
  Badge,
} from "react-bootstrap";
import axios from "axios";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../utils";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.min.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    case "UPLOAD_REQUEST":
      return { ...state, loadingUpload: true, errorUpload: "" };
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        loadingUpload: false,
        errorUpload: "",
      };
    case "UPLOAD_FAIL":
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};

export default function ProductAttributeItem(props) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: "",
    });
  const { variant } = props;
  const { productId } = props;
  const { attr } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [product, setProduct] = useState("");
  const [variation, setVariation] = useState("");

  const [sku, setSku] = useState("");
  const [price, setPrice] = useState("");
  const [priceMrp, setPriceMrp] = useState("");
  const [qty, setQty] = useState("");

  useEffect(() => {
    setSku(attr.sku);
    setPrice(attr.price);
    setPriceMrp(attr.priceMrp);
    setQty(attr.qty);
  }, []);

  function order(a, b) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }

  const isActiveUpdateHandler = async () => {
    let status = attr.isActive;
    if (status === true) {
      status = false;
    } else if (status === false) {
      status = true;
    }
    try {
      dispatch({ type: "UPDATE_REQUEST" });
      await axios.put(
        `/api/products/${productId}/variant`,
        {
          variation: variant.variant._id,
          attribute: attr.variant._id,
          isActive: status,
          type: "attributeStatusActive",
          
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      toast.success("updated successfully");
      attr.isActive = status;
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "UPDATE_FAIL" });
    }
  };

  const submitHandler = async () => {
    try {
      dispatch({ type: "UPDATE_REQUEST" });
      await axios.put(
        `/api/products/${productId}/variant`,
        {
          sku,
          price,
          priceMrp,
          variation: variant.variant._id,
          attribute: attr.variant._id,
          type: "attributeEdit",
          qty,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      toast.success("Updated Successfully");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  return (
    <>
      <tr key={attr._id} className="align-middle">
        <td>{attr.variant.name}</td>
        <td>
          <Form.Control
            className="w-75"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            required
          />
        </td>
        <td>
          <Form.Control
            className="w-75"
            value={priceMrp}
            onChange={(e) => setPriceMrp(e.target.value)}
          />
        </td>
        <td>
          <Form.Control
            className="w-75"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </td>
        <td>
          <Form.Control
            className="w-75"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            required
          />
        </td>
        <td>
          <Button
            onClick={submitHandler}
            className="float-end"
            disabled={loadingUpdate}
            type="buttom"
            size="sm"
            variant="dark"
          >
            Update
          </Button>
        </td>
        <td>
          <Button className="float-start" size="sm" variant="light">
            {attr.isActive === true && (
              <BootstrapSwitchButton
                checked={true}
                onlabel="Active"
                offlabel="In-Active"
                onstyle="success"
                offstyle="secondary"
                size="sm"
                width={120}
                onChange={() => isActiveUpdateHandler()}
              />
            )}
            {attr.isActive === false && (
              <BootstrapSwitchButton
                checked={false}
                onlabel="Active"
                offlabel="In-Active"
                onstyle="success"
                offstyle="secondary"
                size="sm"
                width={120}
                onChange={() => isActiveUpdateHandler()}
              />
            )}
          </Button>
        </td>
      </tr>
    </>
  );
}

// import React, { useContext, useEffect, useState, useReducer } from 'react'
// import { Modal, Button, Card, Form, Row, Col, ListGroup, Badge } from 'react-bootstrap';
// import axios from 'axios';
// import { Store } from "../Store";
// import { toast } from 'react-toastify';
// import { getError } from "../utils";
// import LoadingBox from './LoadingBox';
// import MessageBox from './MessageBox';
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';
// import ProductAttribute from './ProductAttribute';

// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'FETCH_REQUEST':
//             return { ...state, loading: true };
//         case 'FETCH_SUCCESS':
//             return { ...state, loading: false };
//         case 'FETCH_FAIL':
//             return { ...state, loading: false, error: action.payload };
//         case 'UPDATE_REQUEST':
//             return { ...state, loadingUpdate: true };
//         case 'UPDATE_SUCCESS':
//             return { ...state, loadingUpdate: false };
//         case 'UPDATE_FAIL':
//             return { ...state, loadingUpdate: false };
//         case 'UPLOAD_REQUEST':
//             return { ...state, loadingUpload: true, errorUpload: '' };
//         case 'UPLOAD_SUCCESS':
//             return {
//                 ...state,
//                 loadingUpload: false,
//                 errorUpload: '',
//             };
//         case 'UPLOAD_FAIL':
//             return { ...state, loadingUpload: false, errorUpload: action.payload };

//         default:
//             return state;
//     }
// };

// export default function Variant(props) {
//     const { state } = useContext(Store);

//     const { variant } = props;
//     const { productId } = props;

//     const { userInfo } = state;
//     const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
//         useReducer(reducer, {
//             loading: false,
//             error: '',
//         });
//     const [sku, setSku] = useState('');
//     const [price, setPrice] = useState('');
//     const [priceMrp, setPriceMrp] = useState('');
//     const [image, setImage] = useState('');
//     const [images, setImages] = useState([]);
//     const [name, setName] = useState('');
//     useEffect(() => {
//         setName(variant.variant.name);
//         setSku(variant.sku);
//         setPrice(variant.price);
//         setPriceMrp(variant.priceMrp);
//         setImage(variant.image);
//         setImages(variant.images);
//     }, []);

//     const submitHandler = async (e) => {
//         e.preventDefault();
//         try {
//             dispatch({ type: 'UPDATE_REQUEST' });
//             await axios.put(
//                 `/api/products/${productId}/variant`,
//                 {
//                     sku,
//                     image,
//                     images,
//                     price,
//                     priceMrp,
//                     variation: variant.variant._id,
//                     type: 'update'
//                 },
//                 {
//                     headers: { Authorization: `Bearer ${userInfo.token}` },
//                 }
//             );
//             dispatch({
//                 type: 'UPDATE_SUCCESS',
//             });
//             toast.success('Variant Updated Successfully');

//         } catch (err) {
//             toast.error(getError(err));
//             dispatch({ type: 'UPDATE_FAIL' });
//         }
//     };
//     const uploadFileHandler = async (e, forImages) => {
//         const file = e.target.files[0];
//         const bodyFormData = new FormData();
//         bodyFormData.append('file', file);
//         try {
//             dispatch({ type: 'UPLOAD_REQUEST' });
//             const { data } = await axios.post('/api/upload', bodyFormData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     authorization: `Bearer ${userInfo.token}`,
//                 },
//             });
//             dispatch({ type: 'UPLOAD_SUCCESS' });

//             if (forImages) {
//                 setImages([...images, data.secure_url]);
//             } else {
//                 setImage(data.secure_url);
//             }

//             toast.success('Image uploaded successfully.');
//         } catch (err) {
//             toast.error(getError(err));
//             dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
//         }
//     };
//     const deleteFileHandler = async (fileName, f) => {

//         setImages(images.filter((x) => x !== fileName));
//         toast.success('Image removed successfully');
//     };

//     const isActiveUpdateHandler = async (variant) => {

//         let status = variant.isActive;
//         if (status === true) {
//             status = false;
//         }
//         else if (status === false) {
//             status = true;
//         }
//         try {
//             dispatch({ type: 'UPDATE_REQUEST' });
//             await axios.put(
//                 `/api/products/${productId}/variant`,
//                 {
//                     variation: variant.variant._id,
//                     isActive: status,
//                     type: 'statusActive'
//                 },
//                 {
//                     headers: { Authorization: `Bearer ${userInfo.token}` },
//                 }
//             );
//             dispatch({
//                 type: 'UPDATE_SUCCESS',
//             });
//             toast.success('status updated successfully');
//             variant.isActive = status;
//         } catch (err) {
//             toast.error(getError(err));
//             dispatch({ type: 'UPDATE_FAIL' });
//         }
//     };

//     return (
//         <>

//             <Col md={12} >
//                 <Card className="mb-3">
//                     <Card.Header className='bg-secondary text-light'>
//                         Attributes
//                         <Button className="float-end" type="button" size='sm' variant='dark' onClick={handleShow}>
//                             <i className="fas fa-plus-circle me-1"></i>
//                             Add New Attribute
//                         </Button>
//                     </Card.Header>
//                     <Card.Body>
//                         <div className='table-responsive  mt-3 '>
//                             <table className="table text-dark">
//                                 <thead>
//                                     <tr>
//                                         <th>SKU</th>
//                                         <th>Attribute</th>
//                                         <th>M.R.P</th>
//                                         <th>Price</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {attributesList && attributesList.map((item) => (
//                                         <tr key={item._id} className='align-middle'>
//                                             <td>{item.sku}</td>
//                                             <td>{item.variant.name}</td>
//                                             <td>{item.price}</td>
//                                             <td>{item.priceMrp}</td>

//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </Card.Body>
//                 </Card>
//             </Col>

//             <Modal style={{ margin: 'auto' }} aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} size="md" centered >
//                 <Modal.Header closeButton className="text-dark">
//                     <Modal.Title><h6>Add New Attribute</h6>
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body className="text-dark">
//                     <Form onSubmit={submitHandler}>
//                         <Form.Group className="mb-3" controlId="variant">
//                             <Form.Label>Variant</Form.Label>
//                             <Form.Control as="select"
//                                 custom
//                                 onChange={(e) => setVariation(e.target.value)}
//                                 required
//                             >
//                                 {/* .filter(x => x.variantGroup._id === (string)(product.variantSecondary)) */}
//                                 <option >Select Variant</option>
//                                 {variantsList.length > 0 && variantsList.filter(x => x.variantGroup._id === secondaryVariantGroup._id).map((item) => {
//                                     return (
//                                         <option key={item} value={item._id}>
//                                             {item.name}
//                                         </option>
//                                     )
//                                 })
//                                 }
//                             </Form.Control>
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="sku">
//                             <Form.Label>Sku</Form.Label>
//                             <Form.Control
//                                 placeholder="Enter Unique Product SKU"
//                                 value={sku}
//                                 onChange={(e) => setSku(e.target.value)}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="name">
//                             <Form.Label>M.R.P</Form.Label>
//                             <Form.Control
//                                 placeholder="Enter M.R.P If Applicable"
//                                 value={priceMrp}
//                                 onChange={(e) => setPriceMrp(e.target.value)}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="name">
//                             <Form.Label>Price</Form.Label>
//                             <Form.Control
//                                 placeholder="Enter Price If Applicable"
//                                 value={price}
//                                 onChange={(e) => setPrice(e.target.value)}
//                             />
//                         </Form.Group>

//                     </Form>

//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" size="sm" onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant="success" size="sm" onClick={submitHandler}>
//                         Submit
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     )
// }

import { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  sidebarIsOpen: false,
  navIsOpen: false,
  show: false,
  fullBox: false,
  editorState: localStorage.getItem('editorState'),
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,

  cart: {
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : { location: {} },
    paymentMethod: localStorage.getItem('paymentMethod')
      ? localStorage.getItem('paymentMethod')
      : '',
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
  },
};

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MODALSHOW_ON':
      return { ...state, show: true };
    case 'SET_MODALSHOW_OFF':
      return { ...state, show: false };
    case 'SET_SIDEBAR_ON':
      return { ...state, sidebarIsOpen: true };
    case 'SET_SIDEBAR_OFF':
      return { ...state, sidebarIsOpen: false };
    case 'SET_NAV_ON':
      return { ...state, navIsOpen: true };
    case 'SET_NAV_OFF':
      return { ...state, navIsOpen: false };
    case 'SET_FULLBOX_ON':
      return { ...state, fullBox: true };
    case 'SET_FULLBOX_OFF':
      return { ...state, fullBox: false };
    case 'CART_ADD_ITEM':

      const newItem = action.payload;
      delete newItem.variations;
      delete newItem.specs;
      delete newItem.images;
      
      if (newItem.stringVariant || newItem.stringVariant === '') {
        newItem.variant = newItem.stringVariant;
        newItem.attribute = newItem.stringAttribute;
        newItem.image = newItem.stringImage;
        delete newItem.stringAttribute;
        delete newItem.stringImage;
        delete newItem.stringVariant;
      }
    
      const existItem = state.cart.cartItems.find(
        (item) => (item._id === newItem._id && item.variant._id == newItem.variant._id && item.attribute._id == newItem.attribute._id && item.tension == newItem.tension)
      );
      const cartItems = existItem
        ? state.cart.cartItems.map((item) =>
          (item._id === existItem._id && item.variant._id == existItem.variant._id && item.attribute._id == existItem.attribute._id && item.tension == existItem.tension) ? newItem : item
        )
        : [...state.cart.cartItems, newItem];
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, cart: { ...state.cart, cartItems } };
    case 'CART_REMOVE_ITEM': {

      const existItem = state.cart.cartItems.find(
        (item) => (item._id === action.payload._id && item.variant._id == action.payload.variant._id && item.attribute._id == action.payload.attribute._id && item.tension === action.payload.tension)
      );
      // const cartItems = state.cart.cartItems.filter(existItem, 1);
      const cartItems = state.cart.cartItems.filter(
        (item) => item !== existItem
      );
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      delay(150);
      return { ...state, cart: { ...state.cart, cartItems } };
    }
    case 'CART_CLEAR':
      return { ...state, cart: { ...state.cart, cartItems: [] } };
    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,

      };
    case 'SAVE_SHIPPING_ADDRESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: action.payload,
        },
      };
    case 'SAVE_SHIPPING_ADDRESS_MAP_LOCATION':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: {
            ...state.cart.shippingAddress,
            location: action.payload,
          },
        },
      };
    case 'SAVE_PAYMENT_METHOD':
      return {
        ...state,
        cart: { ...state.cart, paymentMethod: action.payload },
      };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children} </Store.Provider>;
}

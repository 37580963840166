import { useContext, useEffect, useState } from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../../components/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


export default function FaqScreen() {
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/contents/contentDetails/faq`);
        setName(data.name);
        setImage(data.image);
        setDescription(data.description);
      } catch (err) {

      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
          <title>FAQ's - Frequently Asked Questions</title>
          <meta name="description" content="We answer all your FAQs and solve queries regarding badminton products to help you buy the right gear." />
      </Helmet>
      <Container>
        <Row className='mt-3 mb-3'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className='text-center' to="/">
                <i className="fas fa-home"></i> Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
             Faq
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <Row className="css-fix mt-3 mb-5 px-3">
          
          {description}
        </Row>
      </Container>

    </div>
  );
}

import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import Product from '../components/Product';
import { Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Breadcrumb } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { Store } from '../Store';

import seoMetaInfo from '../seoMetaInfo';

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                products: action.payload.products,
                page: action.payload.page,
                pages: action.payload.pages,
                countProducts: action.payload.countProducts,
                loading: false,
            };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const prices = [
    {
        name: '₹ 1 to ₹ 999',
        value: '1-999',
    },
    {
        name: '₹ 1,000 to ₹ 2,499',
        value: '1000-2499',
    },
    {
        name: '₹ 2,500 to ₹ 4,999',
        value: '2500-4999',
    },
    {
        name: '₹ 5,000 to ₹ 9,999',
        value: '5000-9999',
    },
    {
        name: '₹ 10,000 & Above',
        value: '10000-999999',
    }
];

export const ratings = [
    {
        name: '4stars & up',
        rating: 4,
    },

    {
        name: '3stars & up',
        rating: 3,
    },

    {
        name: '2stars & up',
        rating: 2,
    },

    {
        name: '1stars & up',
        rating: 1,
    },
];

export default function SubCategroryBrandPage({ brandsProps, catgo, url }) {
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { navIsOpen } = state;

    const navigate = useNavigate();
    const { search } = useLocation();
    const sp = new URLSearchParams(search); // /search?category=Shirts
    const category = catgo || 'all';
    const brand = 'all';
    const query = brandsProps || 'all';
    const price = sp.get('price') || 'all';
    const rating = sp.get('rating') || 'all';
    const order = sp.get('order') || 'newest';
    const page = sp.get('page') || 1;

    const [{ loading, error, products, pages, countProducts }, dispatch] =
        useReducer(reducer, {
            loading: true,
            error: '',
        });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(
                    `/api/products/search?page=${page}&query=${query}&category=${category}&brand=${brand}&price=${price}&rating=${rating}&order=${order}`
                );
                dispatch({ type: 'FETCH_SUCCESS', payload: data });
                ctxDispatch({ type: 'SET_NAV_OFF' });
            } catch (err) {
                dispatch({
                    type: 'FETCH_FAIL',
                    payload: getError(error),
                });

            }
        };
        fetchData();
    }, [category, brand, error, order, page, price, query, rating, dispatch, brandsProps]);

    const [categories, setCategories] = useState([]);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await axios.get(`/api/products/categories`);
                setCategories(data);
            } catch (err) {
                toast.error(getError(err));
            }
        };
        fetchCategories();
    }, [dispatch]);

    const [brands, setBrands] = useState([]);
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const { data } = await axios.get(`/api/products/brands`);
                setBrands(data);
            } catch (err) {
                toast.error(getError(err));
            }
        };
        fetchBrands();
    }, [dispatch]);



    const [pageName, setPageName] = useState('');

    useEffect(() => {
        const pathname = window.location.pathname;

        const lastPart = pathname.split('/').filter(part => part !== '').pop();

        setPageName(lastPart || '');
    }, [pageName]);

    // const [pageName, setPageName] = useState(`${catgo}${brandsProps}`)
    const { metaTitle, metaDescription, heading, contents } = seoMetaInfo[pageName] || {};


    useEffect(() => {
        setPageName(`${catgo}${brandsProps}`);
    }, [catgo, brandsProps]);


    const [breadcrumb, setBreadcrumb] = useState(null);

    useEffect(() => {
        const url = window.location.pathname;

        const pattern = /\/([^/]+)\/([^/]+)/;

        const match = url.match(pattern);

        if (match) {
            const dynamicPart = match[2].charAt(0).toUpperCase() + match[2].slice(1);
            setBreadcrumb(dynamicPart);
        } else {
            setBreadcrumb(null);
        }
    }, []);

    const getFilterUrl = (filter, skipPathname) => {

        const filterPage = filter.page || page;
        const filterCategory = filter.category || category;
        const filterBrand = filter.brand || brand;
        const filterQuery = filter.query || query;
        const filterRating = filter.rating || rating;
        const filterPrice = filter.price || price;
        const sortOrder = filter.order || order;

        return `${skipPathname ? '' : '/search?'
            }category=${filterCategory}&brand=${filterBrand}&query=${filterQuery}&price=${filterPrice}&order=${sortOrder}&page=${filterPage}`;
    };

    return (
        <>
            <div>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                </Helmet>

                <Container >
                    <Row ClassName="mt-3 mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                <i className="fas fa-home"></i> Home
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href={`/${url}`}>
                                {(catgo).charAt(0).toLocaleUpperCase() + catgo.slice(1).toLocaleLowerCase()}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {breadcrumb}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>

                    {/* <Row>
            <SearchBox />
          </Row> */}
                    <Row>
                        <Col md={2} >
                            <Collapsible className='d-lg-none' trigger={<><i className="fas fa-filter"></i> Filter</>}>
                                <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Category</h5>
                                    {/* <ul>
                                        <li>
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                
                                                to={getFilterUrl({ category: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {categories.map((c) => (
                                            <li key={"1"}>
                                                <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                    className={c === category ? 'text-bold' : ''}
                                                    to={getFilterUrl({ category: c })}
                                                >
                                                    {"1"}
                                                </Link>
                                            </li>
                                        ))}
                                        <li key="1">
                                                <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                    
                                                    to="/badminton-rackets"
                                                >
                                                   Appre
                                                </Link>
                                            </li>
                                    </ul> */}
                                    <ul>
                                        <li>
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === category ? 'text-bold' : ''}
                                                to={getFilterUrl({ category: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {/* {categories.map((c) => (
                                            <li key={c}>
                                                <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                    className={c === category ? 'text-bold' : ''}
                                                    to={getFilterUrl({ category: c })}
                                                >
                                                    {c}
                                                </Link>
                                            </li>
                                        ))} */}
                                        <li key="1">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-apparel"
                                            >
                                                Apparel
                                            </Link>
                                        </li>
                                        <li key="2">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-rackets"
                                            >
                                                Racket
                                            </Link>
                                        </li>
                                        <li key="3">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-shuttlecocks"
                                            >
                                                Shuttle
                                            </Link>
                                        </li>
                                        <li key="4">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-shoes"
                                            >
                                                Shoe
                                            </Link>
                                        </li>
                                        <li key="5">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-string"
                                            >
                                                String
                                            </Link>
                                        </li>
                                        <li key="6">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-bags"
                                            >
                                                Bags
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Brand</h5>
                                    <ul>
                                        <li>
                                            <Link
                                                style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === brand ? 'text-bold' : ''}
                                                to={getFilterUrl({ brand: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {brands.map((b) => (
                                            <li key={b}>
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                    className={b === brand ? 'text-bold' : ''}
                                                    to={getFilterUrl({ brand: b })}
                                                >
                                                    {b}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Price</h5>
                                    <ul>
                                        <li>
                                            <Link
                                                style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === price ? 'text-bold' : ''}
                                                to={getFilterUrl({ price: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {prices.map((p) => (
                                            <li key={p.value}>
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                    to={getFilterUrl({ price: p.value })}
                                                    className={p.value === price ? 'text-bold' : ''}
                                                >
                                                    {p.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Collapsible>

                            <div className='d-none d-lg-block'>
                                <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Category</h5>
                                    <ul>
                                        <li>
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === category ? 'text-bold' : ''}
                                                to={getFilterUrl({ category: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {/* {categories.map((c) => (
                                            <li key={c}>
                                                <Link style={{ textDecoration: 'none', color: 'gray' }}
                                                    className={c === category ? 'text-bold' : ''}
                                                    to={getFilterUrl({ category: c })}
                                                >
                                                    {c}
                                                </Link>
                                            </li>
                                        ))} */}
                                        <li key="1">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-apparel"
                                            >
                                                Apparel
                                            </Link>
                                        </li>
                                        <li key="2">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-rackets"
                                            >
                                                Racket
                                            </Link>
                                        </li>
                                        <li key="3">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-shuttlecocks"
                                            >
                                                Shuttle
                                            </Link>
                                        </li>
                                        <li key="4">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-shoes"
                                            >
                                                Shoe
                                            </Link>
                                        </li>
                                        <li key="5">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-string"
                                            >
                                                String
                                            </Link>
                                        </li>
                                        <li key="6">
                                            <Link style={{ textDecoration: 'none', color: 'gray' }}

                                                to="/badminton-bags"
                                            >
                                                Bags
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Brand</h5>
                                    <ul>
                                        <li>
                                            <Link
                                                style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === brand ? 'text-bold' : ''}
                                                to={getFilterUrl({ brand: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {brands.map((b) => (
                                            <li key={b}>
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                    className={b === brand ? 'text-bold' : ''}
                                                    to={getFilterUrl({ brand: b })}
                                                >
                                                    {b}
                                                </Link>
                                            </li>
                                        ))}
                                        <li key="1">
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                   
                                                    to="/li-ning/badminton-rackets"
                                                >
                                                    Li-Ning
                                                </Link>
                                            </li>
                                            <li key="2">
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                   
                                                    to="/yonex/badminton-rackets"
                                                >
                                                   Yonex
                                                </Link>
                                            </li>
                                    </ul>
                                </div> */}
                                <div className='mt-3 searchcat'>
                                    <h5 className='sidecat'>Price</h5>
                                    <ul>
                                        <li>
                                            <Link
                                                style={{ textDecoration: 'none', color: 'gray' }}
                                                className={'all' === price ? 'text-bold' : ''}
                                                to={getFilterUrl({ price: 'all' })}
                                            >
                                                Any
                                            </Link>
                                        </li>
                                        {prices.map((p) => (
                                            <li key={p.value}>
                                                <Link
                                                    style={{ textDecoration: 'none', color: 'gray' }}
                                                    to={getFilterUrl({ price: p.value })}
                                                    className={p.value === price ? 'text-bold' : ''}
                                                >
                                                    {p.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </Col>



                        <Col md={10}>
                            {loading ? (
                                <LoadingBox></LoadingBox>
                            ) : error ? (
                                <MessageBox variant="danger">{error}</MessageBox>
                            ) : (
                                <>
                                    {/* <Card className='mb-3 mt-3 p-1 text-dark'>
                                        <Row className="justify-content-between">
                                            <Col md={6} sm={12} xs={12} className="d-none d-lg-block">
                                                <div>
                                                    {countProducts === 0 ? 'No' : countProducts} Results
                                                    {query !== 'all' && ' : ' + query}
                                                    {category !== 'all' && ' : ' + category}
                                                    {brand !== 'all' && ' : ' + brand}
                                                    {price !== 'all' && ' : Price ' + price}
                                                    {rating !== 'all' && ' : Rating ' + rating + ' & up'}
                                                    {query !== 'all' ||
                                                        category !== 'all' ||
                                                        rating !== 'all' ||
                                                        brand !== 'all' ||
                                                        price !== 'all' ? (
                                                        <Button
                                                            variant="light"
                                                            onClick={() => navigate('/search')}
                                                        >
                                                            <i className="fas fa-times-circle"></i>
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6} sm={12} xs={12} className="text-md-end">

                                                <span className="d-inline-block">
                                                    <select className='form-select'
                                                        value={order}
                                                        onChange={(e) => {
                                                            navigate(getFilterUrl({ order: e.target.value }));
                                                        }}
                                                    >
                                                        <option value="newest">Newest Arrivals</option>
                                                        <option value="lowest">Price: Low to High</option>
                                                        <option value="highest">Price: High to Low</option>
                                                    </select>
                                                </span>


                                            </Col>
                                        </Row>
                                    </Card> */}


                                    {products.length === 0 && (
                                        <MessageBox>No Product Found</MessageBox>
                                    )}

                                    <Row>
                                        {products.length > 0 && products.map((product) => (
                                            <Col sm={6} xs={6} md={3} lg={3} className="mb-3" key={product._id}>
                                                <Product product={product}></Product>
                                            </Col>
                                        ))}
                                    </Row>


                                </>
                            )}
                        </Col>
                    </Row>
                    <h1 style={{ fontSize: "0" }}>{heading}</h1>
                </Container>

            </div>
        </>
    );
}

import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';
import { Container } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SideBar from '../../components/SideBar';

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                contents: action.payload.contents,
                page: action.payload.page,
                pages: action.payload.pages,
                loading: false,
            };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'CREATE_REQUEST':
            return { ...state, loadingCreate: true };
        case 'CREATE_SUCCESS':
            return {
                ...state,
                loadingCreate: false,
            };
        case 'CREATE_FAIL':
            return { ...state, loadingCreate: false };

        case 'DELETE_REQUEST':
            return { ...state, loadingDelete: true, successDelete: false };
        case 'DELETE_SUCCESS':
            return {
                ...state,
                loadingDelete: false,
                successDelete: true,
            };
        case 'DELETE_FAIL':
            return { ...state, loadingDelete: false, successDelete: false };

        case 'DELETE_RESET':
            return { ...state, loadingDelete: false, successDelete: false };
        default:
            return state;
    }
};

export default function ContentListScreen() {
    const [
        {
            loading,
            error,
            contents,
            pages,
            loadingCreate,
            loadingDelete,
            successDelete,
        },
        dispatch,
    ] = useReducer(reducer, {
        loading: true,
        error: '',
    });

    const navigate = useNavigate();
    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const page = sp.get('page') || 1;

    const { state } = useContext(Store);
    const { userInfo } = state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/api/contents/admin?page=${page} `, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });

                dispatch({ type: 'FETCH_SUCCESS', payload: data });
            } catch (err) { }
        };

        if (successDelete) {
            dispatch({ type: 'DELETE_RESET' });
        } else {
            fetchData();
        }
    }, [page, userInfo, successDelete]);

    const createHandler = async () => {
        if (window.confirm('Are you sure to create?')) {
            try {
                dispatch({ type: 'CREATE_REQUEST' });
                const { data } = await axios.post(
                    '/api/contents',
                    {},
                    {
                        headers: { Authorization: `Bearer ${userInfo.token}` },
                    }
                );
                toast.success('Content created successfully');
                dispatch({ type: 'CREATE_SUCCESS' });
                navigate(`/admin/content/${data.content._id}`);
            } catch (err) {
                toast.error(getError(error));
                dispatch({
                    type: 'CREATE_FAIL',
                });
            }
        }
    };

    const isActiveUpdateHandler = async (content) => {

        let status = content.isActive;
        if (status === true) {
            status = false;
        }
        else if (status === false) {
            status = true;
        }
        try {
            dispatch({ type: 'UPDATE_REQUEST' });
            await axios.put(
                `/api/contents/isActive`,
                {
                    _id: content._id,
                    isActive: status
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            dispatch({
                type: 'UPDATE_SUCCESS',
            });
            toast.success('Content updated successfully');

            const fetchData = async () => {
                try {
                    const { data } = await axios.get(`/api/contents/admin?page=${page} `, {
                        headers: { Authorization: `Bearer ${userInfo.token}` },
                    });

                    dispatch({ type: 'FETCH_SUCCESS', payload: data });
                } catch (err) { }
            };
            fetchData();

            // navigate('/admin/contents');
        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPDATE_FAIL' });
        }
    };

    const deleteHandler = async (content) => {
        if (window.confirm('Are you sure to delete?')) {
            try {
                await axios.delete(`/api/contents/${content._id}`, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
                toast.success('Content deleted successfully');
                dispatch({ type: 'DELETE_SUCCESS' });
            } catch (err) {
                toast.error(getError(error));
                dispatch({
                    type: 'DELETE_FAIL',
                });
            }
        }
    };

    return (
        <Container className='ps-1 pe-3 mw-100'>
            <Row>
                <Col md={2}>
                    <SideBar />
                </Col>
                <Col md={10}>
                    <Row className="mt-3 mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link className='text-center' to="/">
                                    <i className="fas fa-home"></i> Home
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Contents
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>

                    <div className='text-dark bg-light p-3 rounded'>
                        <Row>
                            <Col>
                                <h5>Contents</h5>
                            </Col>
                            <Col className="col text-end">
                                <div>
                                    <Link className='text-center' to="/admin/content/create">
                                        <Button type="button" size='sm' variant='success'>
                                            Create New Content
                                        </Button>
                                    </Link>

                                </div>
                            </Col>
                        </Row>


                        {loading ? (
                            <LoadingBox></LoadingBox>
                        ) : error ? (
                            <MessageBox variant="danger">{error}</MessageBox>
                        ) : (

                            <div className='table-responsive  mt-3 '>
                                <table className="table text-dark">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>NAME</th>
                                            <th>Status</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contents.map((content) => (
                                            <tr className='align-middle' key={content._id}>
                                                <td></td>
                                                <td>{content.name}</td>
                                                <td>
                                                    {content.isActive === true && <BootstrapSwitchButton checked={true} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(content)} />}
                                                    {content.isActive === false && <BootstrapSwitchButton checked={false} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(content)} />}
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        variant="light"
                                                        onClick={() => navigate(`/admin/content/edit/${content._id}`)}
                                                    >
                                                        <i className="fas fa-edit fa-solid"></i>
                                                    </Button>
                                                    &nbsp;
                                                
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div>
                                    {[...Array(pages).keys()].map((x) => (
                                        <Link
                                            className={x + 1 === Number(page) ? 'btn btn-secondary text-bold' : 'btn'}
                                            key={x + 1}
                                            to={`/admin/contents?page=${x + 1}`}
                                        >
                                            {x + 1}
                                        </Link>
                                    ))}
                                </div>
                            </div>


                        )}
                    </div>
                </Col>
            </Row>

        </Container>

    );
}

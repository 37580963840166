import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../../Store';
import { getError } from '../../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Button from 'react-bootstrap/Button';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import SideBar from '../../components/SideBar';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductVariant from '../../components/ProductVariant';
import ProductSpecs from '../../components/ProductSpecs';
import InfoModal from '../../components/InfoModal';
import AttributeAdd from '../../components/AttributeAdd';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };
    default:
      return state;
  }
};

export default function ProductDetailScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { id: productId } = params;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
    });
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [price, setPrice] = useState('');
  const [priceMrp, setPriceMrp] = useState('');
  const [sku, setSku] = useState('');
  const [hsn, setHsn] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [product, setProduct] = useState('');
  const [images, setImages] = useState([]);
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [variantGroupsList, setVariantGroupsList] = useState('');
  const [variantsList, setVariantsList] = useState('');
  const [primaryVariantGroup, setPrimaryVariantGroup] = useState('');
  const [secondaryVariantGroup, setSecondaryVariantGroup] = useState('');
  const [attributesList, setAttributesList] = useState('');

 

  const fetchData = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.get(`/api/products/${productId}`);
      setProduct(data);
      setName(data.name);
      setSlug(data.slug);
      setPrice(data.price);
      setPriceMrp(data.priceMrp);
      setSku(data.sku);
      setHsn(data.hsn);
      setDescription(data.description);
      setCategory(data.category.name);
      setQuantity(data.quantity);
      setBrand(data.brand.name);
      setImage(data.image);
      setImages(data.images);
      setPrimaryVariantGroup(data.variantPrimary);
      setSecondaryVariantGroup(data.variantSecondary);

      dispatch({ type: 'FETCH_SUCCESS' });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
   
    try {
      const { data } = await axios.get(`/api/variantGroups/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setVariantGroupsList(data);
    } catch (err) { }

    try {
      const { data } = await axios.get(`/api/variants/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setVariantsList(data);
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) { }
    try {
      const { data } = await axios.get(`/api/products/attributes/${productId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setAttributesList(data);
    } catch (err) { }


  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `/api/products/${productId}`,
        {
          _id: productId,
          name,
          sku,
          slug,
          price,
          priceMrp,
          image,
          images,
          description,
          hsn,
          quantity,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Product updated successfully');

    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };

  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });
      if (forImages) {
        setImages([...images, data.secure_url]);
      } else {
        setImage(data.secure_url);
      }
      toast.success('Image uploaded successfully.');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };
  const deleteFileHandler = async (fileName, f) => {
    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully');
  };
  const updateHandlerPrimary = async (e) => {
    try {
      const variantGroupId = e;
      await axios.put(
        `/api/products/${product._id}/variantGroup/`,
        {
          variantGroupId,
          option: 'Primary'
          , type: 'add'
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Variant Group Created Successfully');
      fetchData();
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const deleteHandlerPrimary = async () => {
    try {
      const variantGroupId = primaryVariantGroup;
      await axios.put(
        `/api/products/${product._id}/variantGroup/`,
        {
          variantGroupId,
          option: 'Primary'
          , type: 'delete'
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      toast.success('Variant Group Deleted Successfully');
      fetchData();

    } catch (err) {
      toast.error(getError(err));
    }
  };

  const updateHandlerSecondary = async (e) => {
    try {
      const variantGroupId = e;
      await axios.put(
        `/api/products/${product._id}/variantGroup/`,
        {
          variantGroupId,
          option: 'Secondary',
          type: 'add'
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Variant Group Created Successfully');
      fetchData();
    } catch (err) {
      toast.error(getError(err));
    }
  };
  const deleteHandlerSecondary = async () => {
    try {
      const variantGroupId = secondaryVariantGroup;
      await axios.put(
        `/api/products/${product._id}/variantGroup/`,
        {
          variantGroupId,
          option: 'Secondary',
          type: 'delete'
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      toast.success('Variant Group Deleted Successfully');
      fetchData();
    } catch (err) {
      toast.error(getError(err));

    }
  };



  return (
    <Container className='ps-1 pe-3 mw-100'>

      <Helmet>
        ( <title>{`${product.name}`}</title> )

      </Helmet>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>
          <Row className="mt-3 mb-1">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/product/list">Products</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {product.name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <div className='text-dark rounded'>
              <Row>
                <Tabs
                  defaultActiveKey="ProductInfo"
                  id="product-details"
                  className="mb-3 text-dark"
                >
                  <Tab eventKey="ProductInfo" title="Product Info" variant="dark">
                    <Row className="mt-1 mb-3">

                      <Col md={5} sm={12}>
                        <Card className=" mb-3 p-0">
                          <Card.Header>
                            Product Details
                            <Button onClick={submitHandler} className="float-end" disabled={loadingUpdate} type="buttom" size="sm" variant='dark'>
                              Update
                            </Button>
                          </Card.Header>
                          <Card.Body className='border-0 p-0'>
                            <ListGroup className='border-0 p-0'>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Name</Col>
                                  <Col md={9}>
                                    <Form.Control
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                      required
                                    /></Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Category</Col>
                                  <Col md={9}>
                                    <Form.Control
                                      disabled
                                      value={category}
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Brand</Col>
                                  <Col md={9}> <Form.Control
                                    disabled
                                    value={brand}
                                  />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>M.r.p</Col>
                                  <Col md={9}><Form.Control
                                    value={priceMrp}
                                    onChange={(e) => setPriceMrp(e.target.value)}
                                  />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Price</Col>
                                  <Col md={9}><Form.Control
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    required
                                  />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Quantity</Col>
                                  <Col md={9}><Form.Control
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                  />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>slug</Col>
                                  <Col md={9}>
                                    <Form.Control
                                      value={slug}
                                      onChange={(e) => setSlug(e.target.value)}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>sku</Col>
                                  <Col md={9}>
                                    <Form.Control
                                      value={sku}
                                      onChange={(e) => setSku(e.target.value)}
                                      
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Hsn</Col>
                                  <Col md={9}>
                                    <Form.Control
                                      value={hsn}
                                      onChange={(e) => setHsn(e.target.value)}
                                      
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            </ListGroup>
                          </Card.Body>
                        </Card>
                        <Card className="">
                          <Card.Header>
                            Image
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="image">
                                  <img
                                    src={image}
                                    className="img-fluid rounded"
                                  ></img>{' '}
                                  {/* <Form.Control
                              disabled='true'
                              value={image}
                              onChange={(e) => setImage(e.target.value)}
                              required
                            /> */}

                                </Form.Group>
                              </Col>
                              <Col md={8}>
                                <Form.Group className="mb-3" controlId="imageFile">
                                  <Form.Label>Upload Image</Form.Label>
                                  <Form.Control type="file" onChange={uploadFileHandler} />
                                  {loadingUpload && <LoadingBox></LoadingBox>}
                                </Form.Group>
                              </Col>
                            </Row>

                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={7} sm={12}>
                        <Card className="">
                          <Card.Header>
                            Additional Images
                          </Card.Header>
                          <Card.Body>
                            <Form.Group className="mb-3" controlId="additionalImage">
                              {images.length === 0 && <MessageBox>No image</MessageBox>}
                              <ListGroup variant="flush" className='row'>
                                <Row>
                                  {images.length > 0 && images.map((x) => (
                                    <Col md={4} sm={12} className='mb-3'>
                                      <div class="card" key={x}>
                                        <img src={x} class="card-img" alt="..." />
                                        <div class="card-img-overlay p-1 text-end">
                                          <Button className='btn-sm' variant="secondary" onClick={() => deleteFileHandler(x)}>
                                            <i className="fa fa-times-circle"></i>
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </ListGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="additionalImageFile">
                              <Form.Label>Upload Aditional Image</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={(e) => uploadFileHandler(e, true)}
                              />
                            </Form.Group>


                          </Card.Body>
                        </Card>

                        <Card className="mt-3 mb-3 p-0">
                          <Card.Header>
                            Variant Details
                          </Card.Header>
                          <Card.Body className='border-0 p-0'>
                            <ListGroup className='border-0 p-0'>
                              <ListGroup.Item className='bg-white text-dark'>
                                <Row>
                                  <Col md={3} className='text-bold'>Primary</Col>
                                  <Col md={9}>
                                    {!primaryVariantGroup &&
                                      <td>  <Form.Group className="mb-3" controlId="brand">

                                        <Form.Control as="select"
                                          custom
                                          onChange={(e) => updateHandlerPrimary(e.target.value)}
                                          required
                                        >
                                          <option >Select Variant Group</option>
                                          {variantGroupsList.length > 0 && variantGroupsList.map((item) => {
                                            return (
                                              <option key={item} value={item._id}>
                                                {item.name}
                                              </option>
                                            )
                                          })
                                          }
                                        </Form.Control>
                                      </Form.Group></td>
                                    }
                                    {primaryVariantGroup && (
                                      <>
                                        <td>
                                          {primaryVariantGroup.name}
                                        </td>
                                        <td>
                                          <Button
                                            type="button"
                                            variant="light"
                                            onClick={() => deleteHandlerPrimary()}
                                          >
                                            <i className="fas fa-trash fa-solid text-end"></i>
                                          </Button>
                                        </td>
                                      </>

                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              {primaryVariantGroup && (
                                <ListGroup.Item className='bg-white text-dark'>
                                  <Row>
                                    <Col md={3} className='text-bold'>Attribute</Col>
                                    <Col md={9}>
                                      {!secondaryVariantGroup &&
                                        <td>  <Form.Group className="mb-3" controlId="brand">

                                          <Form.Control as="select"
                                            custom
                                            onChange={(e) => updateHandlerSecondary(e.target.value)}
                                            required
                                          >
                                            <option >Select</option>
                                            {variantGroupsList.length > 0 && variantGroupsList.filter(x => x._id !== product.variantPrimary._id).map((item) => {
                                              return (
                                                <option key={item} value={item._id}>
                                                  {item.name}
                                                </option>
                                              )
                                            })
                                            }
                                          </Form.Control>
                                        </Form.Group></td>
                                      }
                                      {secondaryVariantGroup && (
                                        <>
                                          <td>
                                            {secondaryVariantGroup.name}
                                          </td>
                                          <td>
                                            <Button
                                              type="button"
                                              variant="light"
                                              onClick={() => deleteHandlerSecondary()}
                                            >
                                              <i className="fas fa-trash fa-solid text-end"></i>
                                            </Button>
                                          </td>
                                        </>

                                      )}
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              )}


                            </ListGroup>
                          </Card.Body>
                        </Card>

                      </Col>
                      <Col md={12} >
                        <Card className="mt-3">
                          <Card.Header>
                            Description
                          </Card.Header>
                          <Card.Body>
                            {product.description}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab eventKey="Variants" title="Variants">
                    {product.variantPrimary && <ProductVariant product={product} />}
                  </Tab>
                  <Tab eventKey="Specifications" title="Specifications">
                    <ProductSpecs product={product} /> 

                  </Tab>

            
                </Tabs>
              </Row>




            </div>

          )}
        </Col>
      </Row>

    </Container >
  );
}

const seoMetaInfo = {
    homePage: {
        metaTitle: 'Sportspalace.in: Your Ultimate Sports Gear Destination',
        metaDescription: 'Explore high-quality sports gear at Sportspalace.in. Find unbeatable prices on top-notch equipment for your sporting journey. Elevate your game today'
    },
    racket: {
        metaTitle: 'Buy Badminton Rackets Online at Best Price - Sportspalace',
        metaDescription: 'Buy badminton rackets online in India at Sportspalace. Explore badminton racket brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
        heading: 'Discover Quality Rackets for Every Sport at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest badminton rackets online in India. Explore our extensive collection featuring top-notch brands such as Yonex, Li-Ning, and more, all at unbeatable prices with free shipping. Whether you prefer an attacking playstyle, defensive strategy, or a balanced approach, our diverse range of rackets caters to every player's unique preferences. Choose the perfect badminton racket that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    shoe: {
        metaTitle: 'Buy Badminton Shoes Online at Best Price - Sportspalace',
        metaDescription: 'Buy badminton shoes online in India at Sportspalace. Explore badminton shoes brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
        heading: 'Quality Sports Shoes at Sportspalace.in for Every Game',
        contents: `Welcome to SportsPalace, your premier destination for purchasing the finest badminton shoes online in India. Explore our extensive collection featuring top-notch brands such as Yonex, Li-Ning and more, all at unbeatable prices with free shipping. Whether you prefer an intermediate or professional style, our diverse range of shoes caters to every player's unique preferences. Choose the perfect badminton shoes that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    shuttle: {
        metaTitle: 'Buy Badminton Shuttlecocks Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton Shuttlecocks online in India at Sportspalace. Explore badminton shuttlecocks like Yonex, Li-Ning and more at the the lowest Prices with free shipping.',
        heading: 'Latest Shuttlecocks and Accessories at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest badminton shuttlecocks online in India. Explore our extensive collection featuring top-notch brands such as Yonex,Li-Ning and more, all at unbeatable prices with free shipping. Whether you prefer a Nylon or Feather our diverse range of shuttlecocks caters to every player's unique preferences. Choose the perfect badminton shuttlecocks that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    string: {
        metaTitle: 'Buy Badminton String Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton String online in India at sportspalace. Explore badminton String brands like Yonex, Li-ning and more at the lowest price with free shipping.',
        heading: 'Discover the Latest Sports Strings at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest Badminton String online in India. Explore our extensive collection featuring top-notch brands such as Yonex, Li-Ning and more, all at unbeatable prices with free shipping. Our diverse range of strings caters to every player's unique preferences. Choose the perfect badminton string that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    apparel: {
        metaTitle: 'Buy Badminton Apparel Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton Apparel online in India at Sportspalace. Explore Badminton Apparel brands like Yonex, Li-ning, and more at the lowest price with free shipping.',
        heading: 'Explore Latest Sports Apparel Collection at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest Badminton Apparel online in India. Explore our extensive collection featuring top-notch brands such as Yonex, Li-ning and more, all at unbeatable prices with free shipping. Whether you prefer a T-shirt, shorts, or Polo t-shirts our diverse range of apparel caters to every player's unique preferences. Choose the perfect badminton apparel that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    bags: {
        metaTitle: 'Buy Badminton Bags Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton Bags online in India at sportspalace. Explore badminton bags brands like Yonex, Li-Ning and more at the lowest price with free shipping.',
        heading: 'Explore the Latest Sports Bags Collection at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest Badminton Bags online in India. Explore our extensive collection featuring top-notch brands such as Yonex, Li-Ning and more, all at unbeatable prices with free shipping. Our diverse range of bags caters to every player's unique preferences. Choose the perfect badminton bags that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    racketYonex: {
        metaTitle: 'Buy Yonex Badminton Rackets Online at Best Price - Sportspalace',
        metaDescription: 'Buy Yonex badminton rackets online in India at Sportspalace. Explore badminton racket like attacking, defensive and balanced at the lowest prices with free shipping.',
        heading: 'Yonex Rackets for Winning Performance at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest badminton rackets online in India. Explore our extensive collection featuring top-notch brands such as Yonex and more, all at unbeatable prices with free shipping. Whether you prefer an attacking playstyle, defensive strategy, or a balanced approach, our diverse range of rackets caters to every player's unique preferences. Choose the perfect badminton racket that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    'racketLi-Ning': {
        metaTitle: 'Buy li-ning Badminton Rackets Online at Best Price - Sportspalace',
        metaDescription: 'Buy Li-ning badminton rackets online in India at Sportspalace. Explore badminton racket like attacking, defensive and balanced at the lowest prices with free shipping.',
        heading: 'Li-Ning Rackets for Winning Performance at Sportspalace.in',
        contents: `Welcome to Sports Palace, your premier destination for purchasing the finest badminton rackets online in India. Explore our extensive collection featuring top-notch brands such as Li-Ning and more, all at unbeatable prices with free shipping. Whether you prefer an attacking playstyle, defensive strategy, or a balanced approach, our diverse range of rackets caters to every player's unique preferences. Choose the perfect badminton racket that suits your playing style and elevate your game to new heights. Shop with confidence at Sports Palace for a seamless online shopping experience and unparalleled quality in badminton equipment.`
    },
    shoeYonex: {
        metaTitle: 'Buy Yonex Badminton Shoes Online at Best Price - Sportspalace',
        metaDescription: 'Buy Yonex badminton shoes online in India at Sportspalace. Explore badminton shoes like professional or intermediate and more at the lowest prices with free shipping.',
        heading: 'Explore Yonex Sports Shoes for Excellence at Sportspalace.in'
    },
    'shoeLi-Ning': {
        metaTitle: 'Buy Li-Ning Badminton Shoes Online at Best Price - Sportspalace',
        metaDescription: 'Buy Li-Ning badminton shoes online in India at Sportspalace. Explore badminton shoes like professional or intermediate and more at the lowest prices with free shipping.',
        heading: 'Li-Ning Sports Shoes for Excellence at Sportspalace.in'
    },
    shuttleYonex: {
        metaTitle: 'Buy Yonex Badminton Shuttlecocks Online at the Best Price - Sportspalace',
        metaDescription: 'Buy Yonex Badminton Shuttlecocks online in India at Sportspalace. Explore badminton shuttlecocks like Nylon or Feather and more at the lowest prices with free shipping.',
        heading: 'Latest Shuttlecocks and Accessories at Sportspalace.in'
    },
    'shuttleLi-Ning': {
        metaTitle: 'Buy Li-Ning Badminton Shuttlecocks Online at  Best Price - Sportspalace.',
        metaDescription: 'Buy Li-Ning Badminton Shuttlecocks online in India at Sportspalace. Explore badminton shuttlecocks like Nylon or Feather and more at the lowest prices with free shipping.',
        heading: 'Discover Li-Ning Shuttlecocks for Badminton Excellence'
    },
    apparelYonex: {
        metaTitle: 'Buy Yonex Badminton Apparel Online at Best Price - Sportspalace',
        metaDescription: 'Buy Yonex Badminton Apparel online in India at Sportspalace. Explore badminton apparel like T-shirts, Shorts, Polo t-shirts, and more at the lowest prices with free shipping.',
        heading: 'Latest Shuttlecocks and Accessories at Sportspalace.in'
    },
    'apparelLi-Ning': {
        metaTitle: 'Buy Li-Ning Badminton Apparel Online at Best Price -  Sportspalace',
        metaDescription: 'Buy Li-ning Badminton Apparel online in India at Sportspalace. Explore badminton apparel like T-shirts, Shorts, Polo t-shirts, and more at the lowest prices with free shipping.',
        heading: 'Discover Li-Ning Shuttlecocks for Badminton Excellence'
    },
    stringYonex: {
        metaTitle: 'Buy Yonex Badminton String Online at Best Price - Sportspalace',
        metaDescription: 'Buy Yonex Badminton string online in India at Sportspalace. Explore the largest collection of badminton strings at best and lowest prices with free shipping',
        heading: 'Yonex Strings for Superior Sports Gear at Sportspalace.in'
    },
    'stringLi-Ning': {
        metaTitle: 'Buy Li-Ning Badminton String Online at Best Price - Sportspalace ',
        metaDescription: 'Buy Li-Ning Badminton string online in India at Sportspalace. Explore the largest collection of badminton String at best and lowest prices with free shipping',
        heading: 'Li-Ning Strings for Superior Sports Gear at Sportspalace.in'
    },
    'bagsLi-Ning': {
        metaTitle: 'Buy Li-Ning Badminton Bags Online at Best Price - Sportspalace',
        metaDescription: 'Buy Li-Ning Badminton bag online in India at Sportspalace. Explore the largest collection of badminton kit bags at best and lowest prices with free shipping',
        heading: 'Explore Li-Ning Sports Bags for Style and Functionality'
    },
    bagsYonex: {
        metaTitle: 'Buy Yonex Badminton Bags Online at Best Price - Sportspalace',
        metaDescription: 'Buy Yonex Badminton bag online in India at Sportspalace. Explore the largest collection of badminton kit bags at best and lowest prices with free shipping',
        heading: 'Explore Li-Ning Sports Bags for Style and Functionality'
    },
    'balanced-badminton-rackets': {
        metaTitle: 'Buy Balanced Badminton Rackets online at Best Prices- Sportspalace',
        metaDescription: 'Buy Balanced badminton rackets online in India at Sportspalace. Explore badminton racket brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'attacking-badminton-rackets': {
        metaTitle: 'Buy Attacking Badminton Rackets Online at  Best Prices- Sportspalace',
        metaDescription: 'Buy Attacking badminton rackets online in India at Sportspalace. Explore badminton racket brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'defensive-badminton-rackets': {
        metaTitle: 'Buy Defensive Badminton Rackets Online at  Best Prices- Sportspalace',
        metaDescription: 'Buy Defensive badminton rackets online in India at Sportspalace. Explore badminton racket brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'professional-badminton-shoes': {
        metaTitle: 'Buy Professional Badminton Shoes Online at Best Price - Sportspalace',
        metaDescription: 'Buy Professional badminton shoes online in India at Sports Palace. Explore badminton shoes brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'intermediate-badminton-shoes': {
        metaTitle: 'Buy Intermediate Badminton Shoes Online at Best Price - Sportspalace',
        metaDescription: 'Buy Intermediate badminton shoes online in India at Sportspalace. Explore badminton shoes brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'nylon-shuttlecocks': {
        metaTitle: 'Buy Nylon Badminton Shuttlecocks Online for the Best Price - Sportspalace',
        metaDescription: 'Buy Nylon Badminton Shuttlecocks online in India at Sports Palace. Explore badminton shuttlecocks brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'feather-shuttlecocks': {
        metaTitle: 'Buy Feather Badminton Shuttlecocks Online at Best Price - Sportspalace',
        metaDescription: 'Buy Feather Badminton Shuttlecocks online in India at Sports Palace. Explore badminton shuttlecocks brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'badminton-apparel-t-shirt': {
        metaTitle: 'Buy Badminton Apparel T-Shirts Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton apparel t-shirt online in India at SportsPalace. Explore badminton apparel brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'badminton-apparel-short': {
        metaTitle: 'Buy Badminton Apparel Shorts Online at Best Price - Sportspalace',
        metaDescription: 'Buy Badminton apparel Shorts online in India at SportsPalace. Explore badminton apparel brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    },
    'badminton-apparel-polo': {
        metaTitle: 'Buy Badminton Apparel Polo T-Shirts Online at Best Price - Sportspalace ',
        metaDescription: 'Buy Badminton apparel Polo t-shirts online in India at SportsPalace. Explore badminton apparel brands like Yonex, Li-Ning, and more at the lowest prices with free shipping.',
    }

};

export default seoMetaInfo;

import React, { useState } from "react";
import "css-pro-layout/dist/css/css-pro-layout.min.css";
import SidebarPro from "./SidebarPro";

export default function SideBar() {
  const [toggled, setToggled] = useState(false);

  return (
    <div className={`layout has-sidebar `}>
      <aside
        id="sidebar"
        className={`sidebar break-point-md ${toggled ? "poAb toggled " : ""}`}
      >
        <SidebarPro />
      </aside>
      <div className="overlay" onClick={() => setToggled(false)}></div>
      <div className="layout">
        <div className="content">
          <div className="sidebar-toggler break-point-md">
            <a
              className="btn btn-secondary"
              onClick={() => setToggled(!toggled)}
            >
              Menu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

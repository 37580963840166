import React, { useContext, useEffect, useReducer } from 'react';
import { Link, } from 'react-router-dom';
import Chart from 'react-google-charts';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//import Collapse from 'react-bootstrap/Collapse';
import { Collapse, Container } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

export default function DashboardUserScreen() {
  const navigate = useNavigate();
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/orders/mine', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userInfo]);

  return (
    <Container className='bg-dark'>
      <div>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-3 my-lg-0 my-md-1">
                <div id="sidebarr" className=" d-none d-lg-block">
                  <div className="h4 text-white">Account</div>
                  <ul>
                    <li>
                      <Link to="/orders">
                        <a className="text-decoration-none d-flex align-items-start">
                          <div className="fas fa-box-open pt-2 me-3"></div>
                          <div className="d-flex flex-column">
                            <div className="link">My Orders</div>
                            <div className="link-desc">View & Manage orders</div>
                          </div>
                        </a>
                      </Link>

                    </li>

                    <li>
                      <Link to="/profile">
                        <a className="button text-decoration-none d-flex align-items-start">
                          <div className="far fa-user pt-2 me-3"></div>
                          <div className="d-flex flex-column">
                            <div className="link">My Profile</div>
                            <div className="link-desc">Profile and Address Details</div>
                          </div>
                        </a>
                      </Link>

                    </li>
                    <li>
                      <Link>
                        <a onClick={signoutHandler} className="text-decoration-none d-flex align-items-start">
                          <div className="fas fa-sign-out-alt pt-2 me-3"></div>
                          <div className="d-flex flex-column">
                            <div className="link">Log-Out</div>
                          </div>
                        </a>
                      </Link>

                    </li>
                  </ul>
                </div>

                <div className="d-lg-none row px-3 py-1 border-1 d-flux">

                  <div className='col col-4'>
                    <Link to="/orders">
                      <button type="button" className="btn btn-sm btn-outline-secondary text-light">

                        My Orders</button>
                    </Link>
                  </div>

                  <div className='col col-4'>
                    <Link to="/profile">
                      <button type="button" className="btn btn-sm btn-outline-secondary text-light">
                        My Profile</button>
                    </Link>
                  </div>

                  <div className='col col-4' onClick={signoutHandler}>
                    <Link>
                      <button type="button" className="btn btn-sm btn-outline-secondary text-light">
                        Log Out</button>
                    </Link>

                  </div>
                </div>
              </div>
              <div className="col-lg-9 my-lg-0 my-1">
                <div id="main-content" >
                  <div className="d-flex flex-column">
                    <div className="fs-6 text-uppercase">Hello {userInfo.name}</div>
                  </div>
               
                  {summary.length > 0 && summary.map((order) => (
                    <div className="order my-3  text-dark bg-light card " key={order._id}>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="d-flex flex-column justify-content-between order-summary">
                            <div className="d-flex align-items-center">
                              <div className="fs-8 text-uppercase">Order #{order.orderId}</div>
                            
                            </div>
                            <div className="fs-8 text-uppercase">Items :  {order.orderItems.reduce((a, c) => a + c.quantity, 0)}</div>
                            <div className="fs-8 text-uppercase">Amount : ₹ {IndianNumber(order.totalPrice.toFixed(2))}</div>
                            <div className="fs-8 text-uppercase">Date : {order.createdAt.substring(0, 10)}</div>
                            <div className="fs-8 text-uppercase">Payment : {order.isPaid ? 'Paid' : 'Not Paid'}</div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="d-sm-flex align-items-sm-start justify-content-sm-between">
                           
                            <div className="fs-8 text-uppercase">Status :  {order.status}</div>
                            <div className="btn btn-success text-uppercase btn-xs"
                              onClick={() => {
                                navigate(`/order/${order.orderId}`);
                              }}
                            >Order Details</div>
                          </div>
                          <div className="progressbar-track">
                            <ul className="progressbar">
                              <li id="step-1" className="green">
                                <span className="fas fa-gift"></span>
                              </li>

                              {(order.status === 'Accepted' || order.status === 'Shipped' || order.status === 'Delivered') ? (
                                <li id="step-2" className="green">
                                  <span className="fas fa-check"></span>
                                </li>
                              ) : (
                                <>
                                  {(order.status === 'Rejected') ? (
                                   <li id="step-5" className="red">
                                    <span className="fas fa-times"></span>
                                  </li>
                                  ) : (
                                  <li id="step-2" className="text-muted ">
                                    <span className="fas fa-check"></span>
                                  </li>
                                  )}
                                </>

                              )}

                              {(order.status === 'Shipped' || order.status === 'Delivered') ? (
                                <li id="step-3" className="green">
                                  <span className="fas fa-check"></span>
                                </li>
                              ) : (
                                <li id="step-3" className="text-muted ">
                                  <span className="fas fa-check"></span>
                                </li>
                              )}

                              {(order.status === 'Delivered') ? (
                                <li id="step-4" className="green">
                                  <span className="fas fa-check"></span>
                                </li>
                              ) : (
                                <li id="step-4" className="text-muted ">
                                  <span className="fas fa-check"></span>
                                </li>
                              )}
                            </ul>
                            <div id="tracker"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {summary.length == 0 && (
                    <h6 class="card-title text-uppercase text-muted mt-5"> No Orders Yet</h6>
                  )}
                </div>
              </div>
            </div>









          </>
        )}
      </div>
    </Container>

  );
}

import React, { useContext, useEffect, useState, useReducer } from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Store } from "../Store";
import { toast } from 'react-toastify';
import { getError } from "../utils";
import axios from 'axios';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import 'bootstrap/dist/css/bootstrap.min.css';

function IndianNumber(number) {
    const IndianFormatter = new Intl.NumberFormat("en-IN");
    const IndianFormattedNumber = IndianFormatter.format(number);
    return IndianFormattedNumber;
}

function ResImage(img, size) {
    var li = img.split("upload");
    var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
    return newimg;
}

const ProductListItem = (props) => {
    const { product } = props;
    const { state } = useContext(Store);
    const { userInfo } = state;
    const [isFeatured, setIsFeatured] = useState('');

    useEffect(() => {
        setIsFeatured(product.isFeatured);
    }, []);



    const isActiveUpdateHandler = async () => {
        
        let status = product.isActive;
        if (status === true) {
            status = false;
        }
        else if (status === false) {
            status = true;
        }

        try {
            await axios.put(
                `/api/products/${product._id}/isActive`,
                {
                    status: status
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Updated   Successfully');
            product.isActive = status;

        } catch (err) {
            toast.error(getError(err));

        }

    }

    const isFeaturedUpdateHandler = async (product, status) => {
        setIsFeatured(status);
        try {
            await axios.put(
                `/api/products/${product._id}/isFeatured`,
                {
                    status: status
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Updated   Successfully');

        } catch (err) {
            toast.error(getError(err));

        }

    }

    return (
        <tr key={product._id} className='align-middle'>
            <td>
                <img
                    src={ResImage(product.image, 300)}
                    className="img-fluid rounded img-thumbnail"
                    style={{ minWidth: '80px' }}
                ></img>
            </td>
            <td className='align-middle'>
                <Link className='text-center' to={`/admin/product/${product._id}`} >
                    <Button type="button" size='sm' variant='light'>
                        {product.name}
                    </Button>
                </Link></td>
            <td> ₹ {IndianNumber(product.price)}</td>
            <td>
                <Button className="float-start m-0 p-0" variant='light' >
                    {isFeatured === true && <i className="fa fa-star" onClick={() => isFeaturedUpdateHandler(product, false)} />}
                    {(isFeatured === false || isFeatured === null) && <i className="far fa-star" onClick={() => isFeaturedUpdateHandler(product, true)} />}
                </Button>
            </td>
            <td>{product.category.name}</td>
            <td>{product.brand.name}</td>
            <td className='text-center'>{product.quantity || 0}</td>
            <td>
                <Button className="float-start" size='sm' variant='light' >
                    {product.isActive === true && <BootstrapSwitchButton checked={true} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler()} />}
                    {product.isActive === false && <BootstrapSwitchButton checked={false} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler()} />}
                </Button>
            </td>
            <td>
                {/* <div className='btn-group'>
            <Button
                className='me-1'
                type="button"
                variant="dark"
                onClick={() => navigate(`/admin/product/edit/${product._id}`)}
            >
                <i className="fas fa-edit fa-solid"></i>
            </Button>

            <Button
                type="button"
                variant="danger"
                onClick={() => deleteHandler(product)}
            >
                <i className="fas fa-trash fa-solid"></i>
            </Button>
        </div> */}
            </td>

        </tr>
    )
}

export default ProductListItem

// import {
//   useProSidebar,
//   ProSidebar,
//   Menu,
//   MenuItem,
//   SubMenu,
//   SidebarHeader,
//   SidebarFooter,
//   SidebarContent
// } from 'react-pro-sidebar';

// const Sidebar = ({
//   image,
//   collapsed,
//   toggled,
//   handleToggleSidebar,
//   handleCollapsedChange
// }) => {
//   return (
//     <ProSidebar
//     //   image={image ? sidebarBg : false}
//       collapsed={collapsed}
//       toggled={toggled}
//       onToggle={handleToggleSidebar}
//       breakPoint="md"
//     >
//       {/* Header */}
//       <SidebarHeader>
//         <Menu iconShape="circle">
//           {collapsed ? (
//             <MenuItem
//               icon={<FaAngleDoubleRight />}
//               onClick={handleCollapsedChange}
//             ></MenuItem>
//           ) : (
//             <MenuItem
//               suffix={<FaAngleDoubleLeft />}
//               onClick={handleCollapsedChange}
//             >
//               <div
//                 style={{
//                   padding: '9px',
//                   textTransform: 'uppercase',
//                   fontWeight: 'bold',
//                   fontSize: 15,
//                   letterSpacing: '1px'
//                 }}
//               >
//                 Pro Sidebar
//               </div>
//             </MenuItem>
//           )}
//         </Menu>
//       </SidebarHeader>
//       {/* Content */}
//       <SidebarContent>
//         <Menu iconShape="circle">
//           <MenuItem
//             icon={<FaTachometerAlt />}
//             suffix={<span className="badge red">NEW</span>}
//           >
//             Dashboard
//             <NavLink to="/" />
//           </MenuItem>
//           {/* <MenuItem icon={<FaGem />}>Components </MenuItem> */}
//           <MenuItem icon={<FaGem />}>
//             Components <Link to="/components" />
//           </MenuItem>
//           <SubMenu
//             suffix={<span className="badge yellow">3</span>}
//             title={'With Suffix'}
//             icon={<FaRegLaughWink />}
//           >
//             <MenuItem>Submenu 1</MenuItem>
//             <MenuItem>Submenu 2</MenuItem>
//             <MenuItem>Submenu 3</MenuItem>
//           </SubMenu>
//           <SubMenu
//             prefix={<span className="badge gray">3</span>}
//             title={'With Prefix'}
//             icon={<FaHeart />}
//           >
//             <MenuItem>Submenu 1</MenuItem>
//             <MenuItem>Submenu 2</MenuItem>
//             <MenuItem>Submenu 3</MenuItem>
//           </SubMenu>
//           <SubMenu title={'Multi Level'}>
//             <MenuItem>Submenu 1 </MenuItem>
//             <MenuItem>Submenu 2 </MenuItem>
//             <SubMenu title={'Submenu 3'}>
//               <MenuItem>Submenu 3.1 </MenuItem>
//               <MenuItem>Submenu 3.2 </MenuItem>
//             </SubMenu>
//           </SubMenu>
//         </Menu>
//       </SidebarContent>
//       {/* Footer */}
//       <SidebarFooter style={{ textAlign: 'center' }}>
//         <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>
//           <Link
//             className="sidebar-btn"
//             style={{ cursor: 'pointer' }}
//             to="/profile"
//           >
//             <FaUser />
//             <span>My Account</span>
//           </Link>
//         </div>
//       </SidebarFooter>
//     </ProSidebar>
//   );
// };

// export default Sidebar;



import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { Link, NavLink } from 'react-router-dom';
import {
  FaUser,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTachometerAlt,
  FaGem,
  FaList,
  FaRegLaughWink,
  FaHeart,
  FaFolderPlus,
  FaLayerGroup,
  FaHome,
  FaBars,
  FaSignOutAlt
} from 'react-icons/fa';
import { Store } from '../Store';
import { useContext } from 'react';




export default function SidebarPro() {

  const { state, dispatch: ctxDispatch } = useContext(Store);


  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
  };
  
  return (

    // <Sidebar backgroundColor="#3a3a3a" className='mw-100 w-100 border-0 h-100'
    // >
      <Menu iconShape="square" className="bg-dark"
        // rootStyles={{
        //style={{ minWidth: '50px' }}
        //   ['& > .' + menuClasses.button]: {
        //     backgroundColor: '#eaabff',
        //     color: '#9f0099',
        //     '&:hover': {
        //       backgroundColor: '#27292b',
        //       color: '#fff',
        //     },
        //   },
        //   ['.' + menuClasses.subMenuContent]: {
        //     backgroundColor: '#fbedff',
        //     '&:hover': {
        //       backgroundColor: '#27292b',
        //       color: '#fff',
        //     },
        //   },
        // }}
        menuItemStyles={{

          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            // if (level === 0)
            //   return {
            //     color: active ? '#f5d9ff' : '#fff',
            //     backgroundColor: active ? '#4d4d4d' : '#3a3a3a',
            //     '&:hover': {
            //       backgroundColor: '#27292b',
            //       color: '#fff'
            //     },
            //   };
            // if (level === 1)
            //   return {
            //     color: active ? '#f5d9ff' : '#fff',
            //     backgroundColor: active ? '#4d4d4d' : '#3a3a3a',
            //     '&:hover': {
            //       backgroundColor: '#27292b',
            //       color: '#fff'
            //     },
            //   };

                        // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: active ? '#f5d9ff' : '#fff',
                backgroundColor: active ? '#4d4d4d' : '#27292b',
                '&:hover': {
                  backgroundColor: '#4d4d4d',
                  color: '#fff'
                },
              };
            if (level === 1)
              return {
                color: active ? '#f5d9ff' : '#fff',
                backgroundColor: active ? '#4d4d4d' : '#27292b',
                '&:hover': {
                  backgroundColor: '#4d4d4d',
                  color: '#fff'
                },
              };
          },
        }}
      >
        <MenuItem component={<Link to="/admin/dashboard" />} >Dashboard</MenuItem>
        <SubMenu label={'Orders'}  >
          <MenuItem component={<Link to="/admin/orders" />}>All Orders</MenuItem>
          {/* <MenuItem component={<Link to="/admin/orders/new" />}>New </MenuItem>
            <MenuItem component={<Link to="/admin/orders/dispatched" />}>Dispatched </MenuItem>
            <MenuItem component={<Link to="/admin/orders/delivered" />}>Delivered </MenuItem>
            <MenuItem component={<Link to="/admin/orders/cod" />}>C.O.D </MenuItem>
            <MenuItem component={<Link to="/admin/orders/brandCreate" />} >Add New</MenuItem> */}
        </SubMenu>
        <SubMenu label={'Products'}  >
          <MenuItem component={<Link to="/admin/product/list" />}>All Products</MenuItem>
          <MenuItem component={<Link to="/admin/product/featured" />}>Featured</MenuItem>
          <MenuItem component={<Link to="/admin/variantGroups" />}>Variant Groups </MenuItem>
          <MenuItem component={<Link to="/admin/variants" />}>Variants</MenuItem>
          <MenuItem component={<Link to="/admin/specs" />}>Specs</MenuItem>
          <MenuItem component={<Link to="/admin/product/create" />} >Create New</MenuItem>
        </SubMenu>
        <SubMenu label={'Users'}  >
          <MenuItem component={<Link to="/admin/users" />}>All Users</MenuItem>
          {/* <MenuItem component={<Link to="/admin/users/new" />}>New Users</MenuItem>
            <MenuItem component={<Link to="/admin/users/admin" />}>Admin Users </MenuItem> */}
        </SubMenu>
        <SubMenu label={'Categories'} >
          <MenuItem component={<Link to="/admin/categories" />}>Categories List</MenuItem>
          <MenuItem component={<Link to="/admin/categoryCreate" />} >Add New</MenuItem>
        </SubMenu>

        <SubMenu label={'Brands'}  >
          <MenuItem component={<Link to="/admin/brands" />}>Brands List</MenuItem>
          <MenuItem component={<Link to="/admin/brandCreate" />} >Add New</MenuItem>
        </SubMenu>

        <SubMenu label={'Contents'}  >
          <MenuItem component={<Link to="/admin/contents" />}>Contents List</MenuItem>
          <MenuItem component={<Link to="/admin/content/create" />} >Add New</MenuItem>
        </SubMenu>

        <SubMenu label={'Promos'}  >
          <MenuItem component={<Link to="/admin/promos" />}>Promos List</MenuItem>
          <MenuItem component={<Link to="/admin/promo/create" />} >Add New</MenuItem>
        </SubMenu>
        <SubMenu label={'Promocode'}  >
          <MenuItem component={<Link to="/admin/promocode" />}>Promocode</MenuItem>
          <MenuItem component={<Link to="/admin/promocode/create" />} >Add New</MenuItem>
        </SubMenu>
        <MenuItem onClick={signoutHandler} icon={<FaSignOutAlt />}>Logout</MenuItem>

      </Menu>
    // </Sidebar>

  );
}

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../../Store';
import { getError } from '../../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Button from 'react-bootstrap/Button';

import { Breadcrumb, Row, Col } from 'react-bootstrap';
import SideBar from '../../components/SideBar';

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return { ...state, loading: false };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'UPDATE_REQUEST':
            return { ...state, loadingUpdate: true };
        case 'UPDATE_SUCCESS':
            return { ...state, loadingUpdate: false };
        case 'UPDATE_FAIL':
            return { ...state, loadingUpdate: false };
        case 'UPLOAD_REQUEST':
            return { ...state, loadingUpload: true, errorUpload: '' };
        case 'UPLOAD_SUCCESS':
            return {
                ...state,
                loadingUpload: false,
                errorUpload: '',
            };
        case 'UPLOAD_FAIL':
            return { ...state, loadingUpload: false, errorUpload: action.payload };

        default:
            return state;
    }
};

export default function VariantCreateScreen() {
    const navigate = useNavigate();
    const params = useParams();
    const { state } = useContext(Store);
    const { userInfo } = state;
    const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
        useReducer(reducer, {
            loading: false,
            error: '',
        });
    const [variantGroupsList, setvariantGroupsList] = useState('');
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [image, setImage] = useState('');
    const [variantGroup, setvariantGroup] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/api/variantGroups/`, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
                setvariantGroupsList(data);
            } catch (err) { }

        };
        fetchData();
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch({ type: 'UPDATE_REQUEST' });
            await axios.post(
                `/api/variants/create`,
                {
                    name,
                    slug,
                    image,
                    variantGroup,
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            dispatch({
                type: 'UPDATE_SUCCESS',
            });
            toast.success('Variant Group Created Successfully');
            setSlug('');
            setName('');
            navigate('/admin/variants/create');
        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPDATE_FAIL' });
        }
    };
    const uploadFileHandler = async (e, forImages) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        try {
            dispatch({ type: 'UPLOAD_REQUEST' });
            const { data } = await axios.post('/api/upload', bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: 'UPLOAD_SUCCESS' });


            setImage(data.secure_url);

            toast.success('Image uploaded successfully.');
        } catch (err) {
            toast.error(getError(err));
            dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
        }
    };

    return (
        <Container className='ps-1 pe-3 mw-100'>
            <Row>
                <Col md={2}>
                    <SideBar />
                </Col>
                <Col md={10}>
                    <Helmet>
                        <title>Create New Variant</title>
                    </Helmet>
                    <Row className="mt-3 mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link className='text-center' to="/">
                                    <i className="fas fa-home"></i> Home
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link className='text-center' to="/admin/variants">Variants</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Create New Variant
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <h5>Create New Variant</h5>

                    {loading ? (
                        <LoadingBox></LoadingBox>
                    ) : error ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                        <div className='text-dark bg-light p-3 rounded'>

                            <Form onSubmit={submitHandler}>
                                <Row className="mt-3 mb-3">
                                    <Col md={6} sm={12} className='px-5'>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="slug">
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control
                                                value={slug}
                                                onChange={(e) => setSlug(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="brand">
                                            <Form.Label>Variant Group</Form.Label>
                                            <Form.Control as="select"
                                                custom
                                                onChange={(e) => setvariantGroup(e.target.value)}
                                                required
                                            >
                                                <option >Select Variant Group</option>
                                                {variantGroupsList.length > 0 && variantGroupsList.map((item) => {
                                                    return (
                                                        <option key={item} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>



                                </Row>
                                <Row className="mt-3 mb-3 px-5 w-50">
                                    <Button disabled={loadingUpdate} type="submit" variant='success'>
                                        Submit
                                    </Button>
                                    {loadingUpdate && <LoadingBox></LoadingBox>}
                                </Row>
                            </Form>
                        </div>

                    )}
                </Col>
            </Row>

        </Container>
    );
}

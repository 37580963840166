import { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Store } from "../Store";
export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { show, fullBox, cart, userInfo,sidebarIsOpen } = state;


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    ctxDispatch({ type: 'SET_SIDEBAR_OFF' });
    ctxDispatch({ type: 'SET_NAV_OFF' });
  }, [pathname]);

  return null;
}
import axios from "axios";
import React from "react";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Rating from "../components/Rating";
import { Helmet } from "react-helmet-async";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError } from "../utils";
import { Store } from "../Store";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import { Breadcrumb } from "react-bootstrap";
import ProductMini from "../components/ProductMini";
import ImageZoom from "../components/ImageZoom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ContentZoom from "react-content-zoom";
import ImageMagnifier from "../components/ImageMagnifier";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import MobileCartDrawerScreen from "./MobileCartDeawerScreen";

const reducer = (state, action) => {
  switch (action.type) {
    case "REFRESH_PRODUCT":
      return { ...state, product: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreateReview: true };
    case "CREATE_SUCCESS":
      return { ...state, loadingCreateReview: false };
    case "CREATE_FAIL":
      return { ...state, loadingCreateReview: false };
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "SET_MODALSHOW_OFF":
      return { ...state, show: false };
    default:
      return state;
  }
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img, size) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
  return newimg;
}

function calculateAmountExcludingGST(amountIncludingGST, gstPercentage=0) {
  var amountExcludingGST = (amountIncludingGST * 100) / (100 + gstPercentage);
  return amountExcludingGST.toFixed(2);
}

function calculateGSTPercentage(amountIncludingGST, amountExcludingGST) {
  var gstPercentage = (amountExcludingGST / 100) * amountIncludingGST;
  return gstPercentage.toFixed(2);
}

function Drawer({ isOpen, onClose }) {
  return (
    <>
        {/* <div className="overlay-drawer" onClick={onClose}></div> */}
      <div className={`drawer ${isOpen ? 'open' : 'close'}`}>
        <div className="drawer-content">
          <div className="d-flex align-items-center justify-content-between pt-3">
            <p className="m-0" style={{fontSize: "18px"}}>SHOPPING CART</p>
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>
          </div>
          <hr/>
          <MobileCartDrawerScreen/>
        </div>
      </div>
    </>
  );
}


function ProductScreen() {
  const renderedProducts = new Set(); // Use a Set to keep track of rendered product IDs
  let reviewsRef = useRef();
  const [catname, setCatname] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [variantGroupsList, setVariantGroupsList] = useState("");
  const [images, setImages] = useState([]);
  const [image, setImage] = useState("");
  const [product, setProduct] = useState("");
  const [variations, setVariations] = useState("");
  const [variant, setVariant] = useState("");
  const [attribute, setAttribute] = useState("");
  const [attributeList, setAttributeList] = useState("");
  const [productSpecsList, setProductSpecsList] = useState([]);
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState("");
  const [priceMrp, setPriceMrp] = useState("");
  const [priceProduct, setPriceProduct] = useState("");
  const [priceMrpProduct, setPriceMrpProduct] = useState("");
  const [priceVariant, setPriceVariant] = useState("");
  const [priceMrpVariant, setPriceMrpVariant] = useState("");
  const [isStung, setIsStung] = useState(false);
  const [tax, setTax] = useState("");
  const [hsn, setHsn] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const tensionli = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
  const [tension, setTension] = useState("");
  const [string, setString] = useState("");
  const [stringVariantli, setStringVariantli] = useState([]);
  const [stringVariant, setStringVariant] = useState("");
  const [stringVariantPrimaryName, setStringVariantPrimaryName] = useState("");
  const [stringImage, setStringImage] = useState("");
  const [stringMainImage, setStringMainImage] = useState("");
  const [stringAttribute, setStringAttribute] = useState("");
  const [showAddToCart, setShowAddToCart] = useState(true);
  const [{ show, loading, error, loadingCreateReview }, dispatch] = useReducer(
    reducer,
    {
      show: "false",
      // product: [],
      loading: true,
      error: "",
    }
  );
  const [relatedProducts, setrelatedProducts] = useState([]);
  const [stringProducts, setStringProducts] = useState([]);

  const [imageGallery, setImageGallery] = useState(null);

  const [showVarientPrice, setShowVarientPrice] = useState("");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  function order(a, b) {
    // return a.variant.name < b.variant.name ? -1 : (a.variant.name > b.variant.name ? 1 : 0);
    return a.variant.name > b.variant.name;
  }

  const variationHandler = async (e) => {
    await setVariant(e);
    if (e.image) {
      await setImage(e.image);
    } else {
      await setImage(product.image);
    }

    if (e.images) {
      await setImages(e.images);
    } else {
      await setImages(product.images);
    }

    if (e.sku) {
      setSku(e.sku);
    }

    if (e.attributes.length > 0) {
      setAttributeList(e.attributes);
      await attributeHandler(
        e.attributes.filter((x) => x.isActive === true)[0]
      );
    }
  };

  const attributeHandler = async (e) => {
    // const unitPrice = calculateAmountExcludingGST(e.price , product?.category?.tax);
    // setUnitPrice(unitPrice);
    if (e.qty === 0 ? setShowAddToCart(false) : setShowAddToCart(true));
    setShowVarientPrice(e.price);
    await setAttribute(e);
    if (e.sku) {
      await setSku(e.sku);
    }

    setProduct((prevProduct) => ({
      ...prevProduct,
      unitPrice: calculateAmountExcludingGST(e.price, product?.category?.tax),
      tax: calculateGSTPercentage(unitPrice, prevProduct.category.tax),
    }));
  };

  const stringHandler = async (e) => {
    let x = stringProducts.filter((item) => {
      return item._id === e;
    });
    let y = x[0];

    await setString(y);

    setStringImage(y.image);
    if (x[0].variations.length > 0) {
      setStringVariantPrimaryName(y.variantPrimary.name);
      let li = y.variations.filter((x) => x.isActive === true);
      await delay(500);
      // setStringVariantli(li);
      setStringVariant(li[0]);
      if (li[0].image) {
        await setStringImage(li[0].image);
      } else {
        await setStringImage(stringMainImage);
      }
    } else {
      setStringVariantli("");
      await setStringVariant("");
    }
  };

  const stringVariantHandler = async (e) => {
    let x = await stringVariantli.filter((item) => {
      return item.variant._id === e;
    });
    if (x) {
      await setStringVariant(x[0]);
      if (x[0].image) {
        await setStringImage(x[0].image);
      } else {
        await setStringImage(stringMainImage);
      }
    }
  };

  function fetchStringProducts() {
    const fetch = async () => {
      try {
        const result = await axios.get(`/api/products/strings`);

        await setStringProducts(result.data.products);
        // setString(result.data.products[0]);
        // stringHandler(result.data.products[0]);
        if (result.data.products[0].variations.length > 0) {
          setStringVariantPrimaryName(
            result.data.products[0].variantPrimary.name
          );
          let li = result.data.products[0].variations.filter(
            (x) => x.isActive === true
          );
          await setStringVariantli(li);
          await setStringMainImage(result.data.products[0].image);
          // setStringVariant(li[0]);
          if (li[0].image) {
            await setStringImage(li[0].image);
          } else {
            await setStringImage(result.data.products[0].image);
          }
        }
        // setTension(tensionli[0]);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetch();
  }

  const [brandId, setBrandId] = useState("");
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "SET_MODALSHOW_OFF" });
      dispatch({ type: "FETCH_REQUEST" });

      try {
        const result = await axios.get(`/api/products/slug/${slug}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        setProduct(result.data);
        const brandIdTemp =
          typeof product.brand === "object" ? product.brand._id : product.brand;
        const categoryIdTemp =
          typeof product.category === "object"
            ? product.category._id
            : product.category;
        setBrandId(brandIdTemp);
        setCategoryId(categoryIdTemp);
        setPrice(result.data.price);
        setQuantity(result.quantity);
        setPriceMrp(result.data.priceMrp);
        setVariations(result.data.variations);
        setImage(result.data.image);
        setImages(result.data.images);
        setTaxRate(result.data.category.tax);
        setUnitPrice(result.data.unitPrice);
        setTax(result.data.tax);
        setHsn(result.data.hsn);
        if (result.data.variations.length > 0) {
          variationHandler(
            result.data.variations.filter((x) => x.isActive === true)[0]
          );
        }
        setCatname(result.data.category.name);
        if (result.data.specs) {
          setProductSpecsList(result.data.specs);
        }
        if (result.data.category.name === "Racket") {
          fetchStringProducts();
        }
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }

      try {
        const { data } = await axios.get(`/api/variantGroups/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setVariantGroupsList(data);
      } catch (err) {}
    };
    fetchData();

    const fetchRelatedProducts = async () => {
      try {
        const result = await axios.get(`/api/products/relatedProducts/${slug}`);
        setrelatedProducts(result.data.products);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchRelatedProducts();
  }, [slug]);

  const [categoriesSlug, setCategoriesSlug] = useState([]);
  const [brandsSlug, setBrandsSlug] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("/api/categories");
        const data = await response.json();
        const currentCategory = data.find(
          (category) => category._id === categoryId
        );
        setCategoriesSlug(currentCategory.slug);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await fetch("/api/brands");
        const data = await response.json();
        const currentBrand = data.find((brand) => brand._id === brandId);
        setBrandsSlug(currentBrand.slug);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  const addToCartHandler = async () => {
    if (isStung) {
      if (tension === "" || string === "") {
        toast.error("Please select tension and string variant.");
        return;
      }
    }

    if (isStung && stringVariantli.length > 0) {
      if (stringVariant === "") {
        toast.error("Please select variant.");
        return;
      }
    }

    const existItem = cart.cartItems.find(
      (x) =>
        x._id === product._id &&
        x.variant._id === variant._id &&
        x.attribute._id === attribute._id
    );

    // console.log(existItem)

    // if(existItem?.attribute?.qty < existItem?.quantity - 1){
    //   alert('This item is out of stock');
    //   return;
    // }
    // const quantity = existItem ? existItem.quantity + 1 : 1;
    const quantity = existItem ? existItem.quantity : 1;
    toast.success("Item Added to Cart");
    product.taxRate = product.category.tax;
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, image, variant, attribute, quantity },
    });

    if (isStung === true) {
      const existItem = cart.cartItems.find(
        (x) => (
          x._id === string._id &&
            x.variant._id === stringVariant._id &&
            x.attribute._id === stringAttribute._id,
          x.tension === tension
        )
      );
      const quantity = existItem ? existItem.quantity + 1 : 1;
      string.taxRate = string.category.tax;
      ctxDispatch({
        type: "CART_ADD_ITEM",
        payload: {
          ...string,
          stringImage,
          stringVariant,
          stringAttribute,
          quantity,
          tension,
        },
      });
    }

    setIsDrawerOpen(!isDrawerOpen);
  };
  const newStyle = {
    backgroundColor: "#FFC107 !importangt",
  };

  useEffect(() => {
    const imgG = images?.map((url) => ({
      original: url,
      thumbnail: url,
    }));

    const primaryImg = {
      original: image,
      thumbnail: image,
    };

    const updatedImgG = [primaryImg, ...imgG];

    setImageGallery(updatedImgG);
  }, [images, image]);

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <Container>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description} />
      </Helmet>
      <Row className="mt-3 mb-3 container">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <i className="fas fa-home"></i> Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className="text-center" to={{ pathname: "/search" }}>
              {product.category.name}{" "}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row>
        <Col lg={8} md={12} sm={12}>
          <div className="scroll-container d-lg-none">
            {/* {[image, ...images].map((x) => (
              <img src={ResImage(x, 900)} />
            ))} */}
            <ReactImageGallery
              showBullets={false}
              showFullscreenButton={false}
              showPlayButton={false}
              originalHeight={true}
              items={imageGallery}
            />
          </div>

          <ListGroup
            variant="flush"
            className="d-none d-lg-block list-group-nobg"
          >
            <ListGroup.Item>
              <Row
                // sm={1}
                // md={images.length > 0 ? "2" : "1"}
                className={images.length > 0 ? "g-2 my-5" : "g-2 px-5 my-5"}
              >
                {/* {[image, ...images].map((x) => (
                  <Col key={x}>
                    <ImageMagnifier src={ResImage(x, 900)} />
                  </Col>
                ))} */}
                <ReactImageGallery
                  showBullets={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  originalHeight={true}
                  items={imageGallery}
                />
                <Tabs
                  defaultActiveKey="Description"
                  id="product-details"
                  className="mb-3"
                >
                  <Tab eventKey="Description" title="Description">
                    <ListGroup>
                      <ListGroup.Item>{product.description}</ListGroup.Item>
                    </ListGroup>
                  </Tab>
                </Tabs>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col lg={4} md={12} sm={12}>
          <Row className="p-2">
            {/* <Card.Body> */}
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col md={3}>Name</Col>
                  <Col md={9}>{product.name}</Col>
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col md={3}>Unit Price:</Col>
                  <Col md={9}>
                    <span className="me-1">₹ {IndianNumber(unitPrice)}</span>
                  </Col>
                </Row>
              </ListGroup.Item> */}
              {/* <ListGroup.Item>
                <Row>
                  <Col md={3}>Tax:</Col>
                  <Col md={9}>
                    <span className="me-1">₹ {IndianNumber(tax)}</span>
                  </Col>
                </Row>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <Row>
                  <Col md={3}>Price:</Col>
                  <Col md={9}>
                    <span
                      className="text-decoration-line-through  me-1"
                      style={{ fontSize: "medium" }}
                    >
                      ₹ {IndianNumber(priceMrp)}
                    </span>
                    <span className="me-1 mx-1">
                      <Badge bg="success" style={{ fontSize: "medium" }}>
                        {/* ₹ {IndianNumber(price)} */}₹{" "}
                        {IndianNumber(showVarientPrice)}
                      </Badge>
                    </span>
                    {/* <span className='text-decoration-line-through  me-1' style={{ fontSize: 'small' }}>
                      ₹ {IndianNumber(priceMrp)}
                    </span> */}
                    {/* <Badge bg="success" >₹ {IndianNumber(price)}</Badge> */}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col md={3}>Status:</Col>
                  <Col md={9}>
                    {product.countInStock > 0 || true ? (
                      <>
                        {product?.quantity > 0 && showAddToCart ? (
                          <Badge bg="success">In Stock</Badge>
                        ) : (
                          <Badge bg="warning">Out Of Stock</Badge>
                        )}
                      </>
                    ) : (
                      <Badge bg="danger">Unavailable</Badge>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>

              {product.countInStock > 0 && (
                <ListGroup.Item>
                  <div className="d-grid"></div>
                </ListGroup.Item>
              )}
            </ListGroup>
            {/* </Card.Body> */}
          </Row>
          {variations.length > 0 && (
            <Row className="mt-3 p-2">
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col md={3}>
                      <h6>{product.variantPrimary.name}</h6>
                    </Col>
                    <Col md={9} className="cart-lable">
                      <h6 className="text-bold ">{variant.variant.name}</h6>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="mt-3">
                    <Row>
                      {variations &&
                        variations
                          .filter((x) => x.isActive === true)
                          .map((item, index) => {
                            const isVariantActive = item.attributes.some(
                              (attr) => attr.qty > 0
                            );

                            return (
                              <Col lg={4} md={4} sm={4} xs={4} key={item._id}>
                                <Card
                                  className={
                                    "cart-lable attribute-color mb-3 " +
                                    (item._id === variant._id
                                      ? "border-5 border-bottom border-success"
                                      : "")
                                  }
                                  onClick={() => variationHandler(item)}
                                  // disabled={!isVariantActive}
                                >
                                  {item.variant.name && (
                                    <>
                                      <img
                                        src={ResImage(item.image, 600)}
                                        style={{ borderRadius: "10px" }}
                                      />
                                      <p className="fsproductvariant text-dark">
                                        {item.variant.name}
                                      </p>
                                    </>
                                  )}
                                  {item.attributes?.map((attr) => {
                                    return (
                                      <React.Fragment key={attr.id}>
                                        {/* Render other attributes if needed */}
                                      </React.Fragment>
                                    );
                                  })}
                                </Card>
                              </Col>
                            );
                          })}
                    </Row>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Row>
          )}

          {product.variantSecondary.name === "General" ? null : (
            <>
              {attributeList.length > 0 && (
                <Row className="mt-3 p-2">
                  <ListGroup>
                    <ListGroup.Item>
                      <h6>{product.variantSecondary.name}</h6>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="mt-3">
                        <Row>
                          {attributeList &&
                            attributeList
                              .filter((x) => x.isActive === true)
                              .sort(order)
                              .map((item) => (
                                <Col lg={3} md={3} sm={4} xs={4} key={item._id}>
                                  <Card
                                    className={`attribute-size mb-3 ${
                                      item._id === attribute._id
                                        ? "bg-success text-white"
                                        : "bg-light text-dark"
                                    }`}
                                    onClick={() => attributeHandler(item)}
                                  >
                                    {item.variant.name}
                                  </Card>
                                </Col>
                              ))}
                        </Row>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
              )}
            </>
          )}

          {product.category.name === "Racket" && (
            <>
              <Row className="mb-3 mt-3 p-2">
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={3} className="my-auto">
                        STRINGING
                      </Col>
                      <Col md={9}>
                        <div class="row">
                          <div
                            class="col col-6 my-1"
                            onClick={() => setIsStung(false)}
                          >
                            <Card
                              className={
                                isStung === false
                                  ? "py-1 border border-secondary border-2 rounded-3 bg-dark  m-auto"
                                  : "py-1 rounded-3 bg-dark m-auto"
                              }
                            >
                              <h6 className="p-1 m-auto">UN-STRUNG</h6>
                            </Card>
                          </div>
                          <div
                            class="col col-6 my-1"
                            onClick={() => setIsStung(true)}
                          >
                            <Card
                              className={
                                isStung === true
                                  ? "py-1 border border-secondary border-2 rounded-3 bg-dark  m-auto"
                                  : "py-1 rounded-3 bg-dark m-auto"
                              }
                            >
                              <h6 className="p-1 m-auto">STRUNG</h6>
                            </Card>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Row>
              {isStung === true && (
                <Row className="p-2">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col sm={3} className="m-auto">
                          STRING
                        </Col>
                        <Col sm={9}>
                          <Form.Select
                            as="select"
                            custom
                            onChange={(e) => stringHandler(e.target.value)}
                            required
                            className="bg-dark text-light"
                          >
                            <option
                              key="select-string"
                              disabled
                              selected
                              value=""
                            >
                              SELECT STRING
                            </option>
                            {stringProducts.length > 0 &&
                              stringProducts.map((product) => (
                                <option key={product} value={product._id}>
                                  {product.name} + ₹{" "}
                                  {IndianNumber(product.price)}
                                </option>
                              ))}{" "}
                          </Form.Select>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {stringVariantli.length > 0 && (
                      <ListGroup.Item>
                        <Row>
                          <Col sm={3} className="m-auto text-uppercase">
                            {stringVariantPrimaryName}
                          </Col>
                          <Col sm={9}>
                            <Form.Select
                              as="select"
                              custom
                              onChange={(e) =>
                                stringVariantHandler(e.target.value)
                              }
                              required
                              className="bg-dark text-light text-uppercase"
                            >
                              <option
                                key="select-string-varient"
                                disabled
                                selected
                                value=""
                              >
                                SELECT {stringVariantPrimaryName}
                              </option>
                              {stringVariantli.length > 0 &&
                                stringVariantli.sort(order).map((product) => (
                                  <option
                                    key={product}
                                    value={product.variant._id}
                                    className="text-uppercase"
                                  >
                                    {product.variant.name}
                                  </option>
                                ))}{" "}
                            </Form.Select>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )}

                    <ListGroup.Item>
                      <Row>
                        <Col sm={3} className="m-auto">
                          TENSION
                        </Col>
                        <Col sm={9}>
                          <Form.Select
                            as="select"
                            custom
                            onChange={(e) => setTension(e.target.value)}
                            required
                            className="bg-dark text-light"
                          >
                            <option
                              key="select-string"
                              disabled
                              selected
                              value=""
                            >
                              SELECT TENSION
                            </option>
                            {tensionli.length > 0 &&
                              tensionli.map((item) => (
                                <option key={item._id}>{item}</option>
                              ))}{" "}
                          </Form.Select>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
              )}
            </>
          )}

          <Row className="mt-3 p-2">
            {product?.quantity > 0 && showAddToCart ? (
              <>
                <Button
                  onClick={addToCartHandler}
                  variant="success"
                  className="w-100"
                >
                  Add to Cart
                </Button>
                <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
                {/* {
                  isDrawerOpen && (
                    <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
                  )
                } */}
              </>
            ) : (
              <>
                <Button
                  disabled
                  variant="warning"
                  className="w-100"
                  style={{ cursor: "not-allowed" }}
                >
                  Out Of Stock
                </Button>
              </>
            )}
          </Row>

          <Row className="mt-3 p-2">
            <h6>Specification</h6>
            <ListGroup>
              {sku && (
                <ListGroup.Item>
                  <Row className="mb-2">
                    <Col md={4}>SKU</Col>
                    <Col md={8}>{sku}</Col>
                  </Row>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <Row className="mb-2">
                  <Col md={4}>Brand</Col>
                  <Col md={8}>{product.brand.name}</Col>
                </Row>
              </ListGroup.Item>
              {productSpecsList &&
                productSpecsList
                  .filter((x) => x.isActive === true && x.value !== null)
                  .map((item) => (
                    <ListGroup.Item>
                      <Row className="mb-2">
                        <Col md={4}>{item.spec.name}</Col>
                        <Col md={8}>{item.value}</Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
            </ListGroup>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3 mb-5 hide-desktop">
        <Col lg={8} md={8} sm={12}>
          <Tabs
            defaultActiveKey="Description"
            id="product-details"
            className="mb-3"
          >
            <Tab eventKey="Description" title="Description">
              <ListGroup>
                <ListGroup.Item>{product.description}</ListGroup.Item>
              </ListGroup>
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        {relatedProducts.length > 0 && <h5>Related Products</h5>}

        {relatedProducts.length > 0 &&
          relatedProducts.map((product) => (
            <Col sm={6} xs={6} md={4} lg={4} className="mb-3" key={product._id}>
              <Link to={`/product/${product.slug}`}>
                <ProductMini product={product}></ProductMini>
              </Link>
            </Col>
          ))}
      </Row>
    </Container>
  );
}
export default ProductScreen;

import React from 'react';
import { Link } from 'react-router-dom';
import shuttlecockImage from '../images/shuttlecock.png';

function NotFound() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
            <h2 id="not-found"style={{ textAlign: 'center' }}>Page Not Found !</h2>
            <img src={shuttlecockImage} alt="React Image" style={{ width: '100px', height: '100px' }} />
            <p id="home-button-new"> Go To The <Link to="/">Homepage</Link>.</p>
        </div>
    );
}

export default NotFound;

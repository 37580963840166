import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };

    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}

function DateConversion(date) {
 
  try {
    return (format(new Date(date), 'yyyy/MM/dd kk:mm:ss'));
  }
  catch (err) {
    return ('');
  }

}

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img, size) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
  return newimg;
}

export default function InvoiceScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();
  const [trackingId, setTrackingId] = useState('');
  const [paymentReferenceId, setPaymentReferenceId] = useState('');
  const [deliveryPartner, setDeliveryPartner] = useState('Blue Dart');
  const [comment, setComment] = useState('');
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 10)
  );
  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();


  function onError(err) {
    toast.error(getError(err));
  }



  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/invoice/${orderId}`, {
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (
      !order._id ||
      successPay ||
      successDeliver
      // (order._id && order._id !== orderId)
    ) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: 'PAY_RESET' });
      }
      if (successDeliver) {
        dispatch({ type: 'DELIVER_RESET' });
      }
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    paypalDispatch,
    successPay,
    successDeliver,
  ]);


  async function getPdf() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.get(
        `/api/orders/orderPdf/${order.orderId}`,
        {},
        {
          headers: {  'Accept': 'application/pdf' },
        },
        {
          responseType: 'blob',
        }
      );
      const link = document.createElement('a');
      link.href = "data:application/octet-stream;base64," + data;
      link.download = `SportsPalace-${order.orderId}.pdf`;
      link.click();

      dispatch({ type: 'DELIVER_SUCCESS' });
      //toast.success('Pdf will be downloaded soon...!!!');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }


  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <Container className='bg-dark'>
      <div>
        <Helmet>
          <title>Order : {order.shippingAddress.fullName}</title>
        </Helmet>


        <Row className='text-light bg-dark mb-3'>
          <Col md={8}>
            <div className='text-uppercase mb-3 mt-3 ms-0 text-start text-light'>
              <span className='ps-1 d-block fs-8'>
                Order ID : {order.orderId}
              </span>
              <span className='ps-1 d-block fs-8'>
                Date : {DateConversion(order.createdAt)}
              </span>
              <span className='ps-1 d-block fs-8'>
                Status : {order.status}
              </span>
            </div>
          </Col>
          <Col md={4} className='m-auto'>
            <Button className='btn-sm' type="button" variant='success' onClick={getPdf}>
              <span className='fs-8 text-uppercase'>
                
               Download Invoice
              </span>
              <span className='ms-3'>
              <i className="fa fa-download"></i>
              </span>
            </Button>
          </Col>
        </Row>

        <Row className='text-light bg-dark'>
          <Col md={8}>
            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase fs-8">
              <Card.Header className='bg-secondary'>
                <h6>Details</h6>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Name</Col>
                      <Col md={10}>{order.shippingAddress.fullName}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Mobile</Col>
                      <Col md={10}>{order.shippingAddress.mobile}</Col>
                    </Row>
                  </ListGroup.Item>
                  {order.shippingAddress.email != null &&
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={2}>Email</Col>
                        <Col md={10}>{order.shippingAddress.email}</Col>
                      </Row>
                    </ListGroup.Item>
                  }

                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Address</Col>
                      <Col md={10}>
                        {order.shippingAddress.address},&nbsp;
                        {order.shippingAddress.city},&nbsp;{order.shippingAddress.province},&nbsp; {order.shippingAddress.postalCode}
                        ,&nbsp;{order.shippingAddress.country}
                        &nbsp;
                        {order.shippingAddress.location &&
                          order.shippingAddress.location.lat && (
                            <a
                              target="_new"
                              href={`https://maps.google.com?q=${order.shippingAddress.location.lat},${order.shippingAddress.location.lng}`}
                            >
                              Show On Map
                            </a>
                          )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              
              </Card.Body>
            </Card>

            {order.deliveryInfo && (
              <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase fs-8">
                <Card.Header className='bg-secondary'>
                  <h6>Shipping</h6>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Company</Col>
                        <Col md={9}>{order.deliveryInfo.company}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Tracking Id</Col>
                        <Col md={9}>{order.deliveryInfo.trackingId}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Pickup Time</Col>
                        <Col md={9}>{DateConversion(order.deliveryInfo.pickupdAt)}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Delivery Time</Col>
                        <Col md={9}>
                          {order.isDelivered ? (
                            <>
                              {DateConversion(order.deliveryInfo.DeliveredAt)}
                            </>
                          ) : (
                            <>Not Delivered</>

                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
             
                </Card.Body>
              </Card>
            )}

            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase">
              <Card.Header className='bg-secondary'>
                <h6>Payment</h6>
              </Card.Header>
              <Card.Body className='row'>

                <Card.Text>
                  <strong>Method:</strong> {order.paymentMethod}
                </Card.Text>

                {order.isPaid ? (
                  <>

                    <Card.Text>
                      <strong>Date:</strong>  {DateConversion(order.paymentResult.trans_date)}
                    </Card.Text>
                    <h6> <strong>Status:</strong> Paid</h6>
                  </>

                ) : (<>
                  <h6> <strong>Status:</strong> Not Paid</h6>

                </>

                )}
              </Card.Body>
            </Card>

            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase">
              <Card.Header className='bg-secondary'>
                <h6>Items</h6>
              </Card.Header>
              <Card.Body>

                <ListGroup variant="flush">
                  {order.orderItems.map((item) => (
                    <ListGroup.Item className='bg-dark text-light'>
                     

                      <div key={item._id} className="mt-3 pb-3 border-bottom border-secondary border-1">

                        <Row className="align-items-center cart-lable text-dark " >
                          <Col md={6} sm={12}>
                            <div className='row'>
                              <div className='col-3 col-md-4 col-sm-3 m-auto align-items-center sm-mb-3 cart-lable'>
                                <img
                                  src={ResImage(item.image, 300)}
                                  alt={item.name}
                                  className="img-fluid rounded img-thumbnail sm-mb-3"
                                ></img>{' '}
                              </div>
                              <div className='col-9 col-md-8 col-sm-9 m-auto align-items-center sm-mb-3 cart-lable text-dark'>
                                <Link to={`/${item?.category?.name?.toLowerCase()}/${item?.brand?.name?.toLowerCase()}/${item.slug}`}>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.product.name}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.variantPrimary && item.variantPrimary.name}
                                    {item.variant && <> {item.variant.variantGroup.name} : {item.variant.name}</>}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.variantSecondary && item.variantSecondary.name}
                                    {item.attribute && <>{item.attribute.variantGroup.name} : {item.attribute.name}</>}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">

                                    {item.tension && <>Tension : {item.tension}</>}
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className='row mt-3 text-light'>
                              <div className='col col-6'>
                                Quantity : {item.quantity}
                                <span className='d-block'>
                                  Unit Price : {IndianNumber(item.unitPrice)}
                                </span>
                                <span className='d-block'>
                                  Tax : {IndianNumber(item.tax)} {' '} ({item.taxRate}%)
                                </span>
                              </div>
                              <div className='col col-4 m-auto fs-5'>
                                ₹ {IndianNumber(item.price * item.quantity)}
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </div>

                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-3 text-light bg-dark border border-1 border-secondary">
              <Card.Header className='bg-secondary text-uppercase'>
                <h6>Order Summary</h6>
              </Card.Header>
              <Card.Body className='bg-dark text-uppercase'>

                <ListGroup variant="flush">
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Items</Col>
                      <Col> {order.orderItems.reduce((a, c) => a + c.quantity, 0)} </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Sub Total</Col>
                      <Col> {IndianNumber(order.itemsPrice.toFixed(2))} </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Tax</Col>
                      <Col>₹ {IndianNumber(order.taxPrice.toFixed(2))}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Shipping</Col>
                      <Col>₹ {IndianNumber(order.shippingPrice.toFixed(2))}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>
                        <strong> Order Total</strong>
                      </Col>
                      <Col>
                        <strong>₹ {IndianNumber(order.totalPrice.toFixed(2))}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>


                </ListGroup>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </div>


    </Container >

  );
}

import React from "react";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import Toggle from "./Toggle";

const NavigationForDesktop = ({
  handleShow,
  cart,
  userInfo,
  isDark,
  setIsDark,
  handleNavShow,
  visible,
  setVisible,
  toggleOffCanvas,
}) => {
  return (
    <>
      <div className="col-8 d-none d-lg-block">
        <Navigation />
      </div>

      <div
        className={
          isDark
            ? "col-lg-2 col-md-6 col-5 d-flex justify-content-end d-none-below-992"
            : "col-lg-2 col-md-6 col-5 d-flex justify-content-end d-none-below-992 light-icon"
        }
      >
        <Link className="nav-link">
          <button
            class="btn btn-dark d-flex  bg-none border-0"
            onClick={handleShow}
          >
            <i className="fas fa-search"></i>
          </button>
        </Link>
        <Link to="/cart" className="nav-link fsft">
          <button class="btn btn-dark d-flex bg-none border-0 noevent">
            <i className="fas fa-shopping-cart"></i>
            {""}
            {cart.cartItems.length > 0 && (
              <Badge bg="dark">
                {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              </Badge>
            )}
          </button>
        </Link>

        {userInfo ? (
          <Link className="nav-link" style={{ fontSize: "large" }} to="/orders">
            <button class="btn btn-dark d-flex  bg-none border-0 noevent">
              <i className="fas fa-user fa-solid"></i>
            </button>
          </Link>
        ) : (
          <Link className="nav-link" style={{ fontSize: "large" }} to="/signin">
            <button class="btn btn-dark d-flex   bg-none border-0 noevent">
              <i className="fas fa-user fa-solid"></i>
            </button>
          </Link>
        )}

        {userInfo && userInfo.isAdmin && (
          <Link
            className="nav-link"
            style={{ fontSize: "large" }}
            to="/admin/dashboard"
          >
            <button class="btn btn-dark d-flex   bg-none border-0 noevent">
              <i className="fas fa-bars"></i>
            </button>
          </Link>
        )}

        <Toggle isChecked={isDark} handleChange={() => setIsDark(!isDark)} />
      </div>
      <div className="col-2 col-sm-6 d-block d-lg-none d-flex justify-content-center">
        <Link to="/cart" className="nav-link fsft">
          <button class="btn btn-dark d-flex bg-none border-0 noevent">
            <i className="fas fa-shopping-cart"></i>
            {""}
            {cart.cartItems.length > 0 && (
              <Badge bg="dark">
                {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              </Badge>
            )}
          </button>
        </Link>
        <Toggle isChecked={isDark} handleChange={() => setIsDark(!isDark)} />

        <Link className="nav-link" style={{ marginLeft: "10px" }}>
          <button
            class="btn btn-dark d-flex  bg-none border-0"
            onClick={handleShow}
          >
            <i className="fas fa-search"></i>
          </button>
        </Link>
        <Link
          className="nav-link"
          onClick={toggleOffCanvas}
          style={{ marginLeft: "10px", marginRight: "110px" }}
        >
          <button class="btn btn-dark d-flex  bg-none border-0 noevent">
            <i style={{ fontSize: "larger" }} className="fas fa-bars fa-1x"></i>
          </button>
        </Link>
      </div>
    </>
  );
};

export default NavigationForDesktop;

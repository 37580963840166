import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import { Store } from '../Store';


import 'react-text-trim/dist/index.css';
import ScrollAnimation from 'react-animate-on-scroll';

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img) {
  var li = img.split("upload");   
  var newimg = li[0]+"/upload/c_scale,w_480"+li[1];
  return newimg;
}
function Product(props) {
  const { product } = props;

  const brandId = typeof product.brand === 'object' ? product.brand._id : product.brand;
  const categoryId = typeof product.category === 'object' ? product.category._id : product.category;

  const { state, dispatch: ctxDispatch } = useContext(Store);

  const {
    cart: { cartItems },
  } = state;

  const [variants, setVariants] = useState('');
  const [images, setImages] = useState('');

  const [categoriesSlug, setCategoriesSlug] = useState([]);
  const [brandsSlug, setBrandsSlug] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/categories');
        const data = await response.json();
        const currentCategory = data.find(category => category._id === categoryId);
        setCategoriesSlug(currentCategory.slug);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await fetch('/api/brands');
        const data = await response.json();
        const currentBrand = data.find(brand => brand._id === brandId);
        setBrandsSlug(currentBrand.slug);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, [brandId, categoryId]);

  const handleClose = () => {
    ctxDispatch({ type: 'SET_MODALSHOW_OFF' });
  };

  useEffect(() => {
        // var count = 0; console.log(product)
    // if (product.variations.length > 0) {
    //   product.variations.forEach(element => {
    //     if (element.image) {
    //       count++;
    //       setImages([...images, element.image]);
    //     }
    //   });
    // }
  }, []);

  return (
    // <ScrollAnimation animateIn="animate__pulse"  animatePreScroll={false} initiallyVisible={true} animate >
   
    // </ScrollAnimation>

<Link className="img-container" to={`/product/${product.slug}`} onClick={handleClose} >
  <Card className="product-card position-relative align-middle bg-light text-dark">
    <div >
      <img src={ResImage(product.image)} className="card-img-top " alt={product.name} />
    </div>
    <div className='row'>
      <p className='product-price'>
        <span className='text-decoration-line-through text-muted me-1' style={{ fontSize: 'medium' }}>
          ₹{IndianNumber(product.priceMrp)}
        </span>
        <span className='me-1'>
          ₹{IndianNumber(product.price)}
        </span>
        {/* <Badge bg="success">₹ {IndianNumber(product.price)}</Badge> */}
      </p>
    </div>
    <div className='text-center align-middle cart-lable' style={{ minHeight: '65px', margin: 'revert' }}>
      <p className='fsproduct px-1'>
        {product.name}
      </p>
    </div>
    {/* <p className='product-price'>
    <span className='text-decoration-line-through text-muted me-1' style={{ fontSize: 'small' }}>
      ₹ {IndianNumber(product.priceMrp)}
    </span>

    <Badge bg="success">₹ {IndianNumber(product.price)}</Badge>
  </p>
  <p class="product-price text-center text-light align-middle" style={{ minHeight: '5px', margin: 'revert', fontSize: 'small' }}> {product.name}</p> */}

    {/* 


  <Card className='d-inline-block'>
    {images && images.map((item) => (
      <img src={item} className='rounded-3 m-1 small-image-product d-inline-block' />))}
  </Card>

  */}



    {/* <h5 className='product-price-chip'>
  ₹ {product.price}
  </h5> */}

    {/* <div style={{overflow: 'hidden', height: '3.6rem'}}>
    <div className='text-dark align-middle' style={{lineHeight: '1.6rem', fontSize: '.9rem', textAlign:'center', marginBottom:'0.2rem'}}>
    {product.name}

      </div>
    </div>
  */}

    {/* <p className="product-price">
    ₹  {product.price}
  </p> */}


    {/* <h5 className="product-price">
      ₹  {product.price}
      </h5> */}
    {/* <p className="price"> ₹ {product.price}</p> */}
    {/* <Button variant="dark" disabled className=' border-radius-null overflow-hidden'>
  {product.name}
    </Button> */}


    {/* <Card.Body>
  <Link to={`/product/${product.slug}`}>
    <Card.Title>{product.name}</Card.Title>
  </Link>

  <Card.Text>₹ {product.price}</Card.Text>
  {product.countInStock === 0 ? (
    <Button variant="light" disabled>
      Out of stock
    </Button>
  ) : (
    <Button onClick={() => addToCartHandler(product)}>Add to cart</Button>
  )}
  </Card.Body> */}
  </Card>
</Link>

  );
}
export default Product;

import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Card, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Store } from "../Store";
import { toast } from 'react-toastify';
import { getError } from "../utils";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export default function CatgorySpecs(props) {
    const { state } = useContext(Store);
    const { userInfo } = state;
    const { category } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [specsList, setSpecsList] = useState([]);
    const [catspecsList, setCatspecsList] = useState([]);
    const [spec, setSpec] = useState('');
    function order(a, b) {
        return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
    }

    const fetchCategoryData = async () => {
        try {
            const { data } = await axios.get(`/api/categories/catDetails/${category}`);
            setCatspecsList(data.specs);
        } catch (err) {

        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/api/specs/`, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
                setSpecsList(data);
            } catch (err) { }
        };
        fetchData();
        fetchCategoryData();
    }, []);

    const submitHandler = async () => {
        try {
            await axios.put(
                `/api/categories/${category}/specs`,
                {
                    item: spec,
                    type: 'add'
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Created Successfully');
            fetchCategoryData();
        } catch (err) {
            toast.error(getError(err));

        }
    };

    const isActiveUpdateHandler = async (item) => {
        let status = item.isActive;
        if (status === true) {
            status = false;
        }
        else if (status === false) {
            status = true;
        }
        try {
            await axios.put(
                `/api/categories/${category}/specs`,
                {
                    item: item,
                    type: 'isActive',
                    status: status
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Updated Successfully');
            fetchCategoryData();
        } catch (err) {
            toast.error(getError(err));

        }
    };

    return (
        <>
            <Card className="mt-3">
                <Card.Header className="mt-auto">
                    <span className="mt-auto">
                        Specs
                    </span>
                    <span>
                        <Button className="float-end" type="button" size='sm' variant='dark' onClick={handleShow}>
                            <i className="fas fa-plus-circle me-1"></i>
                            Add New
                        </Button>
                    </span>

                </Card.Header>
                <Card.Body>
                    <div className='table-responsive  mt-3 '>
                        <table className="table text-dark">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {catspecsList && catspecsList.map((item) => (
                                    <tr key={item._id} className='align-middle'>
                                        <td>{item.spec.name}</td>
                                        <td>
                                            <Button className="float-start" size='sm' variant='light' >
                                                {item.isActive === true && <BootstrapSwitchButton checked={true} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(item)} />}
                                                {item.isActive === false && <BootstrapSwitchButton checked={false} onlabel="Active" offlabel="In-Active" onstyle="success" offstyle="secondary" size="sm" width={120} onChange={() => isActiveUpdateHandler(item)} />}
                                            </Button>
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>

            </Card>

            <Modal style={{ margin: 'auto' }} aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} size="md" centered >
                <Modal.Header closeButton className="text-dark">
                    <Modal.Title><h6>Add New </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark">
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="spec">
                            <Form.Label>Specs</Form.Label>
                            <Form.Control as="select"
                                custom
                                onChange={(e) => setSpec(e.target.value)}
                                required
                            >
                                <option >Select </option>
                                {specsList.length > 0 && specsList.sort(order).map((item) => {
                                    return (
                                        <option key={item} value={item._id}>
                                            {item.name}
                                        </option>
                                    )
                                })
                                }
                            </Form.Control>
                        </Form.Group>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" size="sm" onClick={submitHandler}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

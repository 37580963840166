//import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Store } from '../Store';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Product from '../components/Product';
import ProductMini from './ProductMini';
import Editor from './Editor';
//export default 
function SearchBox() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    navigate(query ? `/search/?query=${query}` : '/search');
  };

  return (
    <Form className="d-flex me-auto w-100" onSubmit={submitHandler}>
      <InputGroup>
        <FormControl
          type="text"
          name="q"
          id="q"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="search products..."
          aria-label="Search Products"
          aria-describedby="button-search"
        ></FormControl>
        <Button variant="outline-primary" type="submit" id="button-search">
          <i className="fas fa-search"></i>
        </Button>
      </InputGroup>
    </Form>
  );
}




// const [show, setShow] = useState('false');
// const handleClose = () => setShow('false');
// const handleShow = () => setShow('true');




const AutoSearch = () => {

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { show, fullBox, cart, userInfo } = state;
  const handleClose = () => {
    ctxDispatch({ type: 'SET_MODALSHOW_OFF' });
  };
  const [searchTerm, updateSearchTerm] = useState('');
  const [filteredResults, updateFilteredResults] = useState([]);
  const [searchResults, updateSearchResults] = useState([]);
  const [displayResults, updateDisplayResults] = useState(false);
  const [focusIndex, updateFocusIndex] = useState(-1);
  const linkRefs = [];
  const keys = {
    ENTER: 13,
    UP: 38,
    DOWN: 40
  };

  useEffect(() => {

  }, []);


  const getSearchResults = async (e) => {

    try {
      const { data } = await axios.get(
        `/api/products/searchfilter?query=` + e
      );

      updateSearchResults(data.products);
      updateFilteredResults(data.products);
      // console.log(searchResults);
    } catch (err) {

    }


    // ⚠️ This is where you should pull data in from your server
    // const searchResultsResponse = await getSearchResults();


  };

  // getSearchResults();
  const updateSearch = e => {
    updateSearchTerm(e.target.value);
    getSearchResults(e.target.value);
    //updateFilteredResults(searchResults.filter(result => products.name.match(new RegExp(e.target.value, 'gi'))))
  };

  const hideAutoSuggest = e => {
    e.persist();

    if (e.relatedTarget && e.relatedTarget.className === 'autosuggest-link') {
      return;
    }

    updateDisplayResults(true);
    updateFocusIndex(-1);
  };

  const showAutoSuggest = () => {
    updateDisplayResults(false);
  };

  const handleNavigation = e => {
    switch (e.keyCode) {
      case keys.ENTER:
        if (focusIndex !== -1) {
          window.open(linkRefs[focusIndex].href);
        }

        hideAutoSuggest(e);
        break;
      case keys.UP:

        if (focusIndex > -1) {
          updateFocusIndex(focusIndex - 1);
        }
        break;
      case keys.DOWN:

        if (focusIndex < filteredResults.length - 1) {
          updateFocusIndex(focusIndex + 1);
        }
        break;
    }
  };

  const SearchResults = () => {
    const Message = ({ text }) => (
      <div className="search-results-message">
        <h2>{text}</h2>
        <hr />
      </div>
    );

    if (!displayResults) {
      return null;
    }

    if (!searchResults.length) {
      return <Message text="Loading search results" />
    }

    if (!searchTerm) {
      return <Message text="Try to search for something..." />
    }

    if (!filteredResults.length) {
      return <Message text="We couldn't find anything for your search term." />
    }

    return (
      <ul className="search-results">
        {filteredResults.map((article, index) => (
          <li key={index}>
            {/* ⚠️ You may want to outsource this part to make the component less heavy */}
            <Card model={article} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <section className="search">

      <Modal.Header bg="dark" style={{ color: 'white', marginTop: '54px' }}>
        {/* closeButton <Modal.Title>Search {searchTerm.length ? `results for: ${searchTerm}` : null}</Modal.Title> */}
        <input type="text"
        autoFocus
          placeholder="Search..."
          onChange={updateSearch}
          // onKeyUp={updateSearch}
          onKeyDown={handleNavigation}
          onBlur={hideAutoSuggest}
          onFocus={showAutoSuggest}
          style={{ width: '30%',  border: 'none', minHeight: '50px', borderRadius: '10px', padding: '10px',    fontSize: 'large', background: 'black',
          color: 'whitesmoke', boxShadow: '0 2px 26px rgba(0,0,0,0.16), 0 2px 2px rgba(0,0,0,0.23)' }} />
          {/* <Editor /> */}
        <Button variant="dark" onClick={handleClose}>
        <i className="fas fa-window-close"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ul className="search-suggestions">
          {/* {(!displayResults && searchTerm) && <li key="-1" className={focusIndex === -1 ? 'active' : null}>{`Search for ${searchTerm}`}</li>} */}

          {/* {searchResults.products.map((element, index) => {
        return (
          <div key={index}>
            <h2>{element}</h2>
          </div>
        );
      })}
           */}
          {/* {Array.isArray(obj)
        ? obj.map(element => {
            return <h2>{element}</h2>;
          })
        : null} */}
          {/* {searchResults.length === 0 && (
            <div>no items</div>
          )}

          <Row>
            {Array.isArray(searchResults) ? searchResults.map(product => {
              <Col sm={6} xs={6} md={4} lg={3} className="mb-3" key={product._id}>
                <Product product={product}></Product>
              </Col>
            }) : ''}
          </Row> */}
          <Row>
            {searchTerm && searchResults.map((product) => (
              <Col sm={6} xs={6} md={4} lg={3} className="mb-3" key={product._id}>
                <ProductMini product={product}></ProductMini>
              </Col>
            ))}
          </Row>
          {/* {searchResults.products.map(() => (
            <li key={product}>

              <Link to={`/product/${product.slug}`}>
                <img src={product.image} className="card-img-top" alt={product.name} />
                <h5 className="product-title">
                  {product.name}
                  <p className="product-price">
                    ₹  {product.price}
                  </p>
                </h5>
              </Link>


            </li>
          ))} */}
          {/* <Link to={`/product/${product.slug}`}>
              <img src={product.image} className="card-img-top" alt={product.name} />
              <h5 className="product-title">
                {product.name}
                <p className="product-price">
                  ₹  {product.price}
                </p>
              </h5>
            </Link> */}
          {/* {!displayResults && filteredResults.map((article, index) => (
            <li key={index} className={focusIndex === index ? 'active' : null}>
              <a href={article.link} target="_blank" className="autosuggest-link"
                ref={link => { linkRefs[index] = link }}>
                {article.title}
              </a>
           
            </li>
          ))} */}
        </ul>

      </Modal.Body>
      {searchTerm && searchResults.length >= 9 && (
        <Modal.Footer style={{border: 'none'}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
                                 Save Changes
                               </Button> */}
        </Modal.Footer>
      )}


      {/* <h1>Search {searchTerm.length ? `results for: ${searchTerm}` : null}</h1>
      <input type="text"
        placeholder="Search for tutorials..."
        onKeyUp={updateSearch}
        onKeyDown={handleNavigation}
        onBlur={hideAutoSuggest}
        onFocus={showAutoSuggest} /> */}

      {/* <SearchResults /> */}
    </section>
  );
}

export default AutoSearch;

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import EditorConvertToHTML from '../components/Editor';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import SideBar from '../components/SideBar';
const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};

export default function BrandEditScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { id: brandId } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
    });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/brands/brandDetails/${brandId}`);
        setName(data.name);
        setSlug(data.slug);
        setImage(data.image);
        setDescription(data.description);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [brandId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `/api/brands/${brandId}/edit`,
        {
          _id: brandId,
          name,
          slug,
          image,
          description,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Brand Updated Successfully');
      navigate('/admin/brands');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });


      setImage(data.secure_url);

      toast.success('Image uploaded successfully.');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };

  return (
    <Container className='ps-1 pe-3 mw-100'>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>
          <Helmet>
            <title>Create New Brand</title>
          </Helmet>
          <Row className="mt-3 mb-3">
            <Breadcrumb>

              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/brands">Brands</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <h6>Edit Brand</h6>

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <div className='text-dark bg-light p-3 rounded'>
              <Form onSubmit={submitHandler}>
                <Row className="mt-3 mb-3">
                  <Col md={6} sm={12} className='px-5'>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="slug">
                      <Form.Label>Slug</Form.Label>
                      <Form.Control
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        as="textarea" rows={3}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12} className='px-5'>
                    <Form.Group className="mb-3" controlId="image">
                      <Form.Label>Image File</Form.Label>
                      <img

                        src={image}
                        className="img-fluid rounded img-thumbnail d-block m-3"
                      ></img>{' '}
                      <Form.Control
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                        required
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="imageFile">
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control type="file" onChange={uploadFileHandler} />
                      {loadingUpload && <LoadingBox></LoadingBox>}
                    </Form.Group>
                  </Col>
                </Row>



                <Row className="mb-3 px-5 w-50">
                  <Button disabled={loadingUpdate} type="submit" variant='success'>
                    Submit
                  </Button>
                  {loadingUpdate && <LoadingBox></LoadingBox>}
                </Row>
              </Form>
            </div>

          )}
        </Col>
      </Row>

    </Container>
  );
}

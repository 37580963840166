import Axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';

export default function LoginScreen() {
    const navigate = useNavigate();
    const { search } = useLocation();
    const redirectInUrl = new URLSearchParams(search).get('redirect');
    const redirect = redirectInUrl ? redirectInUrl : '/';

    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [otpState, setOtpState] = useState(false);

    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { userInfo } = state;
    const submitGetOtpHandler = async (e) => {
        e.preventDefault();
        try {
            const { data } = await Axios.post('/api/users/getOtp', {
                mobile,
            });
            setOtpState(true);
        } catch (err) {
            toast.error(getError(err));
        }
    };
    const submitVerifyOtpHandler = async (e) => {
        e.preventDefault();
        try {
            const { data } = await Axios.post('/api/users/verifyOtp', {
                mobile, otp
            });
            ctxDispatch({ type: 'USER_SIGNIN', payload: data });
            localStorage.setItem('userInfo', JSON.stringify(data));
            navigate(redirect || '/');
        } catch (err) {
            toast.error(getError(err));
        }
    };
    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [navigate, redirect, userInfo]);

    return (
        <div>
            <div className="login">
                <div className="login__content">
                    <div className="login__img  d-none d-lg-block">
                        <img src="login-bg.png" alt="user login" />
                    </div>
                    <div className="login__forms my-auto">
                        {otpState === false && (
                            <Form onSubmit={submitGetOtpHandler} className="login__register" id="login-in">
                                <h1 className="login__title">Sign In</h1>
                                <div className="login__box">
                                    <i className='bx bx-user login__icon'></i>
                                    <Form.Control
                                        type="number"
                                        placeholder='Mobile Number'
                                        required
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="login__input w-100"
                                    />
                                    {/* <input type="number" placeholder="Mobile Number" className="login__input" />  d-none d-lg-block */}
                                </div>

                                <div className="mb-3">
                                    <Button className='login__button w-100 btn-sm' variant='success' type="submit">Get OTP</Button>
                                </div>


                            </Form>
                        )}
                        {otpState === true && (
                            <Form onSubmit={submitVerifyOtpHandler} className="bg-secondary login__register" id="login-in">
                                <h1 className="login__title">Verify OTP</h1>
                                <div className="login__box">
                                    <i className='bx bx-user login__icon'></i>
                                    <Form.Control
                                        type="number"
                                        placeholder='Mobile Number'
                                        disabled
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="login__input w-100"
                                        value={mobile}
                                    />
                                    
                                </div>
                                <div className="login__box">
                                    <i className='bx bx-user login__icon'></i>
                                    <Form.Control
                                        type="number"
                                        placeholder='Enter OTP'
                                        required
                                        onChange={(e) => setOtp(e.target.value)}
                                        className="login__input w-100"
                                    />
                                    
                                </div>

                                <div className="mb-3">
                                    <Button className='login__button w-100 btn-sm' variant='success' type="submit">Submit OTP</Button>
                                </div>


                            </Form>
                        )}
                    </div>
                </div>
            </div>



        </div>
    );
}

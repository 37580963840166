import { useState,useContext } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../components/MessageBox';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { toast } from 'react-toastify';

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img, size) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
  return newimg;
}

export default function MobileCartDrawerScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    // const { data } = await axios.get(`/api/products/${item._id}`);
    if (item.attribute.qty < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    navigate('/signin?redirect=/checkout');
  };

  return (
    <div>
      <Container>
        <Row className='mt-3 mb-3' style={{display: "flex", flexDirection: "column"}}>
          <Col lg={12} md={12} className='mb-3'>
            <Card className='bg-dark text-light'>
              <Card.Body >
                {cartItems.length === 0 ? (<div className='mt-3 mb-3'>
                  <h4 className='text-secondary'>
                    Cart is empty.
                    <div className='mt-3 '>
                      <Link className='text-light' to="/">Proceed to Shopping</Link>
                    </div>
                  </h4>

                </div>
                ) : (
                  <ListGroup>
                    {cartItems.map((item) => (
                      <div key={item._id} className="mt-3 pb-3 border-bottom border-secondary border-1">
                  
                        <Row className="align-items-center cart-lable" >
                          <Col md={12} sm={12}>
                            <div className='row'>
                              <div className='col-3 col-md-4 col-sm-3 m-auto align-items-center sm-mb-3 cart-lable'>
                                <img
                                  src={ResImage(item.image, 300)}
                                  alt={item.name}
                                  className="img-fluid rounded img-thumbnail sm-mb-3"
                                ></img>{' '}
                              </div>
                              <div className='col-9 col-md-8 col-sm-9 m-auto align-items-center sm-mb-3 cart-lable'>
                                <Link to={`/${item?.category?.name?.toLowerCase()}/${item?.brand?.name?.toLowerCase()}/${item.slug}`}>{item.name}</Link>
                                <p className="m-auto h6 cart-lable">
                                  {item.variantPrimary && item.variantPrimary.name}
                                  {item.variant && <> : {item.variant.variant.name}</>}
                                </p>
                                <p className="m-auto fs-6 cart-lable">
                                  {item.variantSecondary && item.variantSecondary.name}
                                  {item.attribute && <> : {item.attribute.variant.name}</>}
                                </p>
                                <p className="m-auto h6 cart-lable text-light">
                                   
                                    {item.tension && <>Tension : {item.tension}</>}
                                  </p>
                              </div>
                            </div>

                        

                          </Col>
                          <Col md={12} sm={12}>
                            <div className='row mt-3'>
                              <div className='col col-4 m-auto'>
                                <Button
                                  style={{ opacity: '100', color: '#fff !important' }}
                                  variant="success"
                                  className='mx-1 rounded-2 text-center btn-sm disabled bg-success text-white'>
                                  ₹ {IndianNumber((item.attribute ? item.attribute.price : item?.price))}
                                </Button>
                               
                              </div>
                              <div className='col col-6'>
                                <div className='btn-group w-100' style={{ border: 'solid 1px gray' }}>

                                  <Button
                                    onClick={() =>
                                      updateCartHandler(item, item.quantity - 1)
                                    }
                                    variant="dark"
                                    disabled={item.quantity === 1}
                                    className='mx-1 rounded-3 '
                                  >
                                    <i className="fas fa-minus-circle"></i>
                                  </Button>{' '}

                                  <Button
                                    variant="dark"
                                    className='mx-1 rounded-3'>
                                    <span>{item.quantity}</span>
                                  </Button>


                                  <Button
                                    className='mx-1 rounded-3'
                                    variant="dark"
                                    onClick={() =>
                                      updateCartHandler(item, item.quantity + 1)
                                    }
                                    disabled={item.quantity === 10}
                                  >
                                    <i className="fas fa-plus-circle"></i>
                                  </Button>
                                </div>
                              </div>
                              <div className='col col-2'>
                                <Button
                                  className='mx-1 rounded-3 btn-outline-secondary'
                                  onClick={() => removeItemHandler(item)}
                                  variant="dark"
                                >
                                  <i className="fas fa-trash text-light"></i>
                                </Button>
                              </div>
                            </div>
                        

                          </Col>
                        </Row>

                      </div>
                    ))}
                  </ListGroup>
                )}
              </Card.Body>
            </Card>

          </Col>
          <Col lg={12} md={12}>
            {cartItems.length === 0 ? (
              <></>
            ) : (
              <Card className='bg-dark'>
                <Card.Body>
                  <ListGroup variant="dark">
                    <ListGroup.Item>
                      <h6>
                        Total Items : {' '}{cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <h6>
                        Sub-Total : {' '}₹{' '}
                        {/* {IndianNumber(cartItems.reduce((a, c) => a + c.attribute.price * c.quantity, 0))} */}
                        {IndianNumber(cartItems.reduce((a, c) => a + (c.attribute?.price || c.price) * c.quantity, 0))}


                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-grid">
                        <Button
                          type="button"
                          variant="success"
                            onClick={checkoutHandler}
                          disabled={cartItems.length === 0}
                        >
                          Proceed to Checkout
                        </Button>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            )}

          </Col>
        </Row>
      </Container>

    </div>
  );
}

import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };

    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}

function DateConversion(date) {
 
  try {
    return (format(new Date(date), 'yyyy/MM/dd kk:mm:ss'));
  }
  catch (err) {
    return ('');
  }

}

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img, size) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
  return newimg;
}

export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();
  const [trackingId, setTrackingId] = useState('');
  const [paymentReferenceId, setPaymentReferenceId] = useState('');
  const [deliveryPartner, setDeliveryPartner] = useState('Blue Dart');
  const [comment, setComment] = useState('');
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 10)
  );
  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  function handleAcceptShow() {
    setId('accept')
    setShow(true);
  }
  function handleRejectShow() {
    setId('reject')
    setShow(true);
  }
  function handleShippedShow() {
    setId('shipped')
    setShow(true);
  }
  function handleDeliveredShow() {
    setId('delivered')
    setShow(true);
  }

  function handlePaymentShow() {
    setId('payment')
    setShow(true);
  }

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        dispatch({ type: 'PAY_REQUEST' });
        const { data } = await axios.put(
          `/api/orders/${order._id}/pay`,
          details,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        toast.success('Order is paid');
      } catch (err) {
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
        toast.error(getError(err));
      }
    });
  }
  function onError(err) {
    toast.error(getError(err));
  }



  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/login');
    }
    if (
      !order._id ||
      successPay ||
      successDeliver
      // (order._id && order._id !== orderId)
    ) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: 'PAY_RESET' });
      }
      if (successDeliver) {
        dispatch({ type: 'DELIVER_RESET' });
      }
    } else {
      const loadPaypalScript = async () => {
        const { data: clientId } = await axios.get('/api/keys/paypal', {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': clientId,
            currency: 'USD',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      loadPaypalScript();
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    paypalDispatch,
    successPay,
    successDeliver,
  ]);


  async function getPdf() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.get(
        `/api/orders/orderPdf/${order.orderId}`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.token}`, 'Accept': 'application/pdf' },
        },
        {
          responseType: 'blob',
        }
      );
      const link = document.createElement('a');
      link.href = "data:application/octet-stream;base64," + data;
      link.download = `SportsPalace-${order.orderId}.pdf`;
      link.click();

      dispatch({ type: 'DELIVER_SUCCESS' });
      //toast.success('Pdf will be downloaded soon...!!!');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }


  async function handlePaymentUpdate() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/paymentCodUpdate`,
        { paymentReferenceId, startDate },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('Order Payment Updated');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  async function acceptOrderHandler() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/accept`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('Order is Accepted');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  async function rejectOrderHandler() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/reject`,
        { comment },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('Order is Accepted');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }
  async function shippedOrderHandler() {

    try {
      //dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/shipped`,
        { trackingId, startDate, deliveryPartner },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      //dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      setShow(false);
      toast.success('Shippment Details Updated');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  async function deliveredOrderHandler() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/deliver`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('Delivery Details Updated');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  const submitHandler = async () => {
    try {
      shippedOrderHandler();

    } catch (err) {
      toast.error(getError(err));

    }
  };

  const handlePaymentSubmit = (event) => {
    event.preventDefault(); // (1) in notes below
    let formData = {
      merchant_id: '2545664',
      order_id: orderId,
      currency: 'INR',
      amount: order.totalPrice,
      redirect_url: 'http://localhost:3002/ccavResponseHandler',
      cancel_url: 'http://localhost:3002/ccavResponseHandler',
      language: 'EN',
      billing_name: order.shippingAddress.fullName,
      billing_address: order.shippingAddress.address,
      billing_city: order.shippingAddress.city,
      billing_state: 'Telangana',
      billing_zip: order.shippingAddress.postalCode,
      billing_country: 'India',
      billing_tel: order.shippingAddress.mobile,
      billing_email: order.shippingAddress.email,
      integration_type: 'iframe_normal',
    };
    const serializedBody = JSON.stringify(formData); // (2) in notes below
    const fetchOptions = { // (3)
      method: 'POST', // (4)
      body: serializedBody // (5)
    };

    axios.post(
      `/ccavRequestHandler`, fetchOptions,
    );
    
  };


  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <Container className='bg-dark'>
      <div>
        <Helmet>
          <title>Order : {order.shippingAddress.fullName}</title>
        </Helmet>
        <Row className='text-light bg-dark mb-3'>
          <Col md={8}>
            <div className='text-uppercase mb-3 mt-3 ms-0 text-start text-light'>
              <span className='ps-1 d-block fs-8'>
                Order ID : {order.orderId}
              </span>
              <span className='ps-1 d-block fs-8'>
                Date : {DateConversion(order.createdAt)}
              </span>
              <span className='ps-1 d-block fs-8'>
                Status : {order.status}
              </span>
            </div>
          </Col>
          <Col md={4} className='m-auto'>
            <Button className='btn-sm' type="button" variant='success' onClick={getPdf}>
              <span className='fs-8 text-uppercase'>

                Download
              </span>
              <span className='ms-3'>
                <i className="fa fa-download"></i>
              </span>
            </Button>
          </Col>
        </Row>

        <Row className='text-light bg-dark'>
          <Col md={8}>
            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase fs-8">
              <Card.Header className='bg-secondary'>
                <h6>Details</h6>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Name</Col>
                      <Col md={10}>{order.shippingAddress.fullName}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Mobile</Col>
                      <Col md={10}>{order.shippingAddress.mobile}</Col>
                    </Row>
                  </ListGroup.Item>
                  {order.shippingAddress.email != null &&
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={2}>Email</Col>
                        <Col md={10}>{order.shippingAddress.email}</Col>
                      </Row>
                    </ListGroup.Item>
                  }

                  <ListGroup.Item className='bg-dark'>
                    <Row className='mb-2'>
                      <Col md={2}>Address</Col>
                      <Col md={10}>
                        {order.shippingAddress.address},&nbsp;
                        {order.shippingAddress.city},&nbsp;{order.shippingAddress.province},&nbsp; {order.shippingAddress.postalCode}
                        ,&nbsp;{order.shippingAddress.country}
                        &nbsp;
                        {order.shippingAddress.location &&
                          order.shippingAddress.location.lat && (
                            <a
                              target="_new"
                              href={`https://maps.google.com?q=${order.shippingAddress.location.lat},${order.shippingAddress.location.lng}`}
                            >
                              Show On Map
                            </a>
                          )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              
              </Card.Body>
            </Card>

            {order.deliveryInfo && (
              <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase fs-8">
                <Card.Header className='bg-secondary'>
                  <h6>Shipping</h6>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Company</Col>
                        <Col md={9}>{order.deliveryInfo.company}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Tracking Id</Col>
                        <Col md={9}>{order.deliveryInfo.trackingId}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Pickup Time</Col>
                        <Col md={9}>{DateConversion(order.deliveryInfo.pickupdAt)}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-dark'>
                      <Row className='mb-2'>
                        <Col md={3}>Delivery Time</Col>
                        <Col md={9}>
                          {order.isDelivered ? (
                            <>
                              {DateConversion(order.deliveryInfo.DeliveredAt)}
                            </>
                          ) : (
                            <>Not Delivered</>

                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
              
                </Card.Body>
              </Card>
            )}

            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase">
              <Card.Header className='bg-secondary'>
                <h6>Payment</h6>
              </Card.Header>
              <Card.Body className='row'>

                <Card.Text>
                  <strong>Method:</strong> {order.paymentMethod}
                </Card.Text>

                {order.isPaid ? (
                  <>
                    {order.paymentResult.id &&
                      <Card.Text>
                        <strong>Reference No:</strong> {order.paymentResult.id}
                      </Card.Text>
                    }
                    <Card.Text>
                      <strong>Date:</strong>  {DateConversion(order.paymentResult.trans_date)}
                    </Card.Text>
                    <h6> <strong>Status:</strong> Paid</h6>
                  </>

                
                ) : (<>
                  <h6> <strong>Status:</strong> Not Paid</h6>
                  {userInfo.isAdmin && (
                    <ListGroup.Item className='bg-dark'>

                      <div className="d-grid w-50">
                        <Button type="button" variant='success' onClick={handlePaymentShow}>
                          <span className='fs-8 text-uppercase'>
                            Update Payment
                          </span>
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}

           
                </>

                )}
              </Card.Body>
            </Card>

            <Card className="mb-3 text-light bg-dark border border-1 border-secondary text-uppercase">
              <Card.Header className='bg-secondary'>
                <h6>Items</h6>
              </Card.Header>
              <Card.Body>

                <ListGroup variant="flush">
                  {order.orderItems.map((item) => (
                    <ListGroup.Item className='bg-dark text-light'>
               

                      <div key={item._id} className="mt-3 pb-3 border-bottom border-secondary border-1">

                        <Row className="align-items-center cart-lable text-dark " >
                          <Col md={6} sm={12}>
                            <div className='row'>
                              <div className='col-3 col-md-4 col-sm-3 m-auto align-items-center sm-mb-3 cart-lable'>
                                <img
                                  src={ResImage(item.image, 300)}
                                  alt={item.name}
                                  className="img-fluid rounded img-thumbnail sm-mb-3"
                                ></img>{' '}
                              </div>
                              <div className='col-9 col-md-8 col-sm-9 m-auto align-items-center sm-mb-3 cart-lable text-dark'>
                                <Link to={`/${item?.category?.name?.toLowerCase()}/${item?.brand?.name?.toLowerCase()}/${item.slug}`}>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.product.name}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.variantPrimary && item.variantPrimary.name}
                                    {item.variant && <> {item.variant.variantGroup.name} : {item.variant.name}</>}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">
                                    {item.variantSecondary && item.variantSecondary.name}
                                    {item.attribute && <>{item.attribute.variantGroup.name} : {item.attribute.name}</>}
                                  </p>
                                  <p className="m-auto h6 cart-lable text-light">

                                    {item.tension && <>Tension : {item.tension}</>}
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className='row mt-3 text-light'>
                              <div className='col col-6'>
                                Quantity : {item.quantity}
                                <span className='d-block'>
                                  Unit Price : {IndianNumber(item.unitPrice)}
                                </span>
                                <span className='d-block'>
                                  Tax : {IndianNumber(item.tax)} {' '} ({item.taxRate}%)
                                </span>
                              </div>
                              <div className='col col-4 m-auto fs-5'>
                                ₹ {IndianNumber(item.price * item.quantity)}
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </div>

                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-3 text-light bg-dark border border-1 border-secondary">
              <Card.Header className='bg-secondary text-uppercase'>
                <h6>Order Summary</h6>
              </Card.Header>
              <Card.Body className='bg-dark text-uppercase'>

                <ListGroup variant="flush">
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Items</Col>
                      <Col> {order.orderItems.reduce((a, c) => a + c.quantity, 0)} </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Sub Total</Col>
                      <Col> {IndianNumber(order.itemsPrice.toFixed(2))} </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Tax</Col>
                      <Col>₹ {IndianNumber(order.taxPrice.toFixed(2))}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>Shipping</Col>
                      <Col>₹ {IndianNumber(order.shippingPrice.toFixed(2))}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className='bg-dark'>
                    <Row>
                      <Col>
                        <strong> Order Total</strong>
                      </Col>
                      <Col>
                        <strong>₹ {IndianNumber(order.totalPrice.toFixed(2))}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
      

                </ListGroup>
              </Card.Body>
            </Card>

            <Card className="mb-3 text-light bg-dark border border-1 border-secondary">
              <Card.Header className='bg-secondary text-uppercase'>
                <h6>Order STATUS</h6>
              </Card.Header>
              <Card.Body className='bg-dark text-uppercase'>

                <ListGroup variant="flush">
                  <ListGroup.Item className='bg-dark'>

                    <div className="d-grid">
                      <Button type="button" variant='light' disabled className='text-uppercase'>
                        Order Placed
                        <span className='ps-1 d-block fs-8'>
                          {DateConversion(order.createdAt)}
                        </span>
                      </Button>
                    </div>
                  </ListGroup.Item>

                  {order.isAccepted && (
                    <ListGroup.Item className='bg-dark'>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      <div className="d-grid">
                        <Button type="button" variant='light' disabled className='text-uppercase'>
                          Order is Accepted
                          <span className='ps-1 d-block fs-8'>
                            {DateConversion(order.acceptedAt)}
                          </span>
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}
                  {order.isRejected && (
                    <ListGroup.Item className='bg-dark'>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      <div className="d-grid">
                        <Button type="button" variant='light' disabled className='text-uppercase'>
                          Order is Rejected
                          <span className='ps-1 d-block fs-8'>
                            {order.comment}
                          </span>
                          <span className='ps-1 d-block fs-8'>
                            {DateConversion(order.acceptedAt)}
                          </span>
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}
                  {order.isShipped && (
                    <ListGroup.Item className='bg-dark'>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      <div className="d-grid">
                        <Button type="button" variant='light' disabled className='text-uppercase'>
                          Order is Shipped
                          <span className='ps-1 d-block fs-8'>
                            {DateConversion(order.shippedAt)}
                          </span>
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}
                  {order.isDelivered && (
                    <ListGroup.Item className='bg-dark'>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      <div className="d-grid">
                        <Button type="button" variant='light' disabled className='text-uppercase'>
                          Order is Delivered
                          <span className='ps-1 d-block fs-8'>
                            {DateConversion(order.deliveredAt)}
                          </span>
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}
                  {userInfo.isAdmin && !order.isAccepted && !order.isRejected && (
                    <ListGroup.Item className='bg-dark'>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      <div className="d-inline-block w-100">
                        <Button type="button" variant='success' onClick={handleAcceptShow} className='text-uppercase fs-8 me-3 mb-3'>
                          Accept Order
                        </Button>
                        <Button type="button" variant='danger' onClick={handleRejectShow} className='text-uppercase fs-8 mb-3'>
                          Reject Order
                        </Button>
                      </div>
                    </ListGroup.Item>
                  )}

                  {userInfo.isAdmin && order.isAccepted && !order.isShipped && (
                    <>
                      <ListGroup.Item className='bg-dark'>
                        {loadingDeliver && <LoadingBox></LoadingBox>}
                        <div className="d-grid">
                          <Button type="button" variant='success' onClick={handleShippedShow}>
                            <span className='fs-8 text-uppercase'>
                              Update Order as Shipped
                            </span>
                          </Button>
                        </div>
                      </ListGroup.Item>
                    </>

                  )}

                  {userInfo.isAdmin && order.isAccepted && order.isShipped && !order.isDelivered && (
                    <>
                      <ListGroup.Item className='bg-dark'>
                        {loadingDeliver && <LoadingBox></LoadingBox>}
                        <div className="d-grid">
                          <Button type="button" variant='success' onClick={handleDeliveredShow} className='text-uppercase'>
                            Update Order as Delivered
                          </Button>
                        </div>
                      </ListGroup.Item>
                    </>

                  )}

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal style={{ margin: 'auto' }} aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} size="md" centered >
        {id === 'shipped' && (
          <Form onSubmit={shippedOrderHandler}>
            <Modal.Header closeButton className="text-dark">
              <Modal.Title><h6>Shippment Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">

              <Form.Group className="mb-3" controlId="deliverpartner">
                <Form.Label>Delivery Partner</Form.Label>
                <Form.Control as="select"
                  custom
                  onChange={(e) => setDeliveryPartner(e.target.value)}
                  required
                >
                  <option value="Blue Dart">
                    Blue Dart
                  </option>
                  <option value="Xpressbees">
                    Xpressbees
                  </option>
                  <option value="Store Pickup">
                    Store Pickup
                  </option>
                </Form.Control>
                <Form.Label className='mt-3'>Tracking Id </Form.Label>
                <Form.Control
                  placeholder='Enter Tracking Id'
                  onChange={(e) => setTrackingId(e.target.value)}
                  required
                />

                <DatePicker
                  className='form-control mt-3 px-3 me-5'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  injectTimes={[
                    setHours(setMinutes(new Date(), 1), 0),
                    setHours(setMinutes(new Date(), 5), 12),
                    setHours(setMinutes(new Date(), 59), 23),
                  ]}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" size="sm" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}

        {id === 'accept' && (
          <Form onSubmit={acceptOrderHandler}>
            <Modal.Header closeButton className="text-dark">
              <Modal.Title><h6>Accept Order</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">

              <Form.Group className="mb-3">
                <Form.Label>Confirm Accept Order...?</Form.Label>

              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" size="sm" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
        {id === 'reject' && (
          <Form onSubmit={rejectOrderHandler}>
            <Modal.Header closeButton className="text-dark">
              <Modal.Title><h6>Confirm Reject Order...?</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">
              <Form.Group className="mb-3" >
                <Form.Label>Reason for rejecting order</Form.Label>
                <Form.Control onChange={(e) => setComment(e.target.value)} required placeholder='Type Reason' />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" size="sm" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
        {id === 'delivered' && (
          <Form onSubmit={deliveredOrderHandler}>
            <Modal.Header closeButton className="text-dark">
              <Modal.Title><h6>Confirm order is Delivered...?</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">
              <Form.Group className="mb-1">
                <Form.Label>Delivered Time</Form.Label>

              </Form.Group>
              <Form.Group className="mb-1">

                <DatePicker
                  className='form-control px-3 me-5'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  injectTimes={[
                    setHours(setMinutes(new Date(), 1), 0),
                    setHours(setMinutes(new Date(), 5), 12),
                    setHours(setMinutes(new Date(), 59), 23),
                  ]}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" size="sm" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}

        {id === 'payment' && (
          <Form onSubmit={handlePaymentUpdate}>
            <Modal.Header closeButton className="text-dark">
              <Modal.Title><h6>Update Payment Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">

              <Form.Group className="mb-3" controlId="deliverpartner">

                <Form.Label className='mt-3'>Payment Reference Number</Form.Label>
                <Form.Control
                  placeholder='Enter Payment Reference Number'
                  onChange={(e) => setPaymentReferenceId(e.target.value)}
                  required
                />

                <DatePicker
                  className='form-control mt-3 px-3 me-5'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  injectTimes={[
                    setHours(setMinutes(new Date(), 1), 0),
                    setHours(setMinutes(new Date(), 5), 12),
                    setHours(setMinutes(new Date(), 59), 23),
                  ]}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" size="sm" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}

      </Modal>


    </Container >

  );
}

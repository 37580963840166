import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Container className="py-5 rounded">
        <Row className="justify-content-center">
          <Col
            md={4}
            className="text-center p-4"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h1 style={{fontSize:"60px"}}>Thank You! </h1>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="green"
              >
                <path
                  d="M9 16.17l-3.5-3.5 1.17-1.17L9 13.83l6.34-6.34 1.17 1.17-7.5 7.5z"
                  strokeWidth="15"
                />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
            <p style={{fontSize:"20px"}}>Your message has been successfully sent.</p>
            <Button variant="btn btn-success">
              <Link to={"/"} className="text-white">Back to Home</Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThankYouPage;

import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from "react-toastify";
import { getError } from "../utils";
import { Store } from "../Store";
import CheckoutSteps from "../components/CheckoutSteps";
import LoadingBox from "../components/LoadingBox";
import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Modal } from "react-bootstrap";
import PaymnetCCAvenue from "../components/PaymentCCAvenue";
const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loading: true };
    case "CREATE_SUCCESS":
      return { ...state, loading: false };
    case "CREATE_FAIL":
      return { ...state, loading: false };
    default:
      return state;
  }
};

function IndianNumber(number) {
  const IndianFormatter = new Intl.NumberFormat("en-IN");
  const IndianFormattedNumber = IndianFormatter.format(number);
  return IndianFormattedNumber;
}

function ResImage(img, size) {
  var li = img.split("upload");
  var newimg = li[0] + "/upload/c_scale,w_" + size + li[1];
  return newimg;
}

export default function PlaceOrderScreen() {
  const navigate = useNavigate();

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const [fullName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("India");
  const [paymentMethod, setPaymentMethod] = useState("Online");
  const [user, setUser] = useState("");
  const [isStoreManager, setIsStoreManager] = useState("false");
  const [html, setHTML] = useState({ __html: "" });

  const [promoCode, setPromoCode] = useState("");
  const [appliedPromocode, setAppliedPromocode] = useState(null);
  const [promoPercentage, setPromoPercentage] = useState(null);
  const [promocodeInputDisabled, setPromocodeInputDisabled] = useState(false);
  const [customPrice, setCustomPrice] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;
  cart.totalPrice = round2(
    // cart.cartItems.reduce((a, c) => a + c.quantity * c.attribute.price, 0)
    cart.cartItems.reduce((a, c) => a + (c.quantity * (c.attribute?.price || c.price)), 0)
  );

  cart.taxPrice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.tax, 0)
  );
  cart.itemsPrice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.unitPrice, 0)
  );

  cart.shippingPrice = cart.itemsPrice > 100 ? round2(0) : round2(0);

  const placeOrderHandler = async () => {
    try {
      if (
        fullName === "" ||
        email === "" ||
        mobile === "" ||
        address === "" ||
        postalCode === "" ||
        province === "" ||
        city === ""
      ) {
        toast.error("Fill All Details");
      } else {
        dispatch({ type: "CREATE_REQUEST" });

        const { data } = await axios.post(
          "/api/orders",
          {
            fullName: fullName,
            email: email,
            mobile: mobile,
            address: address,
            postalCode: postalCode,
            province: province,
            city: city,
            country: country,
            shippingAddress: {
              address: address,
              city: city,
              postalCode: postalCode,
              mobile: mobile,
              province: province,
              country: country,
              fullName: fullName,
              email: email,
            },
            orderItems: cart.cartItems,
            paymentMethod: paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
          },
          {
            headers: {
              authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        ctxDispatch({ type: "CART_CLEAR" });
        dispatch({ type: "CREATE_SUCCESS" });
        localStorage.removeItem("cartItems");
        navigate(`/order/${data.order.orderId}`);
      }
    } catch (err) {
      dispatch({ type: "CREATE_FAIL" });
      toast.error(getError(err));
    }
  };

  const placePaymentOrderHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });

      const { data } = await axios.post(
        "/api/orders/initiate",
        {
          fullName: fullName,
          email: email,
          mobile: mobile,
          address: address,
          postalCode: postalCode,
          city: city,
          country: country,
          shippingAddress: {
            address: address,
            city: city,
            postalCode: postalCode,
            mobile: mobile,
            province: province,
            country: country,
            fullName: fullName,
            email: email,
          },
          orderItems: cart.cartItems,
          paymentMethod: paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      window.location.href =
        "http://payment.sportspalace.in/getorderdetails/" + data.orderId;
    } catch (err) {
      dispatch({ type: "CREATE_FAIL" });
      toast.error(getError(err));
    }
  };
  const handleUpdateTotalPrice = (customPrice) => {
    return () => {
      // Convert the customPrice to a number
      const updatedTotalPrice = cart.totalPrice + parseFloat(customPrice); // Add the custom price to the current total price
      // Optionally, return the updated total price
      return updatedTotalPrice;
    };
  };
  const placeStoreOrderHandler = async () => {
    try {
      if (fullName === "" || mobile === "") {
        toast.error("Fill Customer Details");
      } else {
        dispatch({ type: "CREATE_REQUEST" });
        const updatedTotalPrice = handleUpdateTotalPrice(customPrice)();
        // const updatedTotalPrice = cart.totalPrice + parseFloat(customPrice);
        const { data } = await axios.post(
          "/api/orders/storeOrder",
          {
            fullName: fullName,
            mobile: mobile,
            shippingAddress: {
              address: "Agway Sports, Plot No 147, Kondapur",
              city: "Hyderabad",
              postalCode: "500084",
              mobile: mobile,
              province: "Telangana",
              country: "India",
              fullName: fullName,
            },
            orderItems: cart.cartItems,
            paymentMethod: paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            taxPrice: cart.taxPrice,
            totalPrice: updatedTotalPrice,
          },
          {
            headers: {
              authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        ctxDispatch({ type: "CART_CLEAR" });
        dispatch({ type: "CREATE_SUCCESS" });
        localStorage.removeItem("cartItems");
        navigate(`/order/${data.order.orderId}`);
      }
    } catch (err) {
      dispatch({ type: "CREATE_FAIL" });
      toast.error(getError(err));
    }
  };

  function fetchCustomerDetails() {
    const fetch = async () => {
      if (mobile.length === 10) {
        try {
          const { data } = await axios.get(
            `/api/users/customerDetails/${mobile}`,
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            },
            {
              mobile: mobile,
            }
          );
          if (data.isUser === true) {
            setName(data.user.name);
            setEmail(data.user.email);
            setMobile(data.user.mobile);
            setAddress(data.user.address);
            setProvince(data.user.province);
            setPostalCode(data.user.postalCode);
            setCity(data.user.city);
          } else {
            setName("");
            setEmail("");

            setAddress("");
            setProvince("");
            setPostalCode("");
            setCity("");
          }
        } catch (err) {
          setName("");
          setEmail("");

          setAddress("");
          setProvince("");
          setPostalCode("");
          setCity("");
          toast.error(getError(err));
        }
      } else {
        toast.error("Enter Valid Mobile Number");
      }
    };
    fetch();
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin?redirect=/checkout");
    } else {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_REQUEST" });
          const { data } = await axios.get(`/api/users/myne/${userInfo._id}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          });

          if (data.isStoreManager === true) {
            setIsStoreManager(true);
            setPaymentMethod("COD");
          } else {
            setUser(data);
            setName(data.name);
            setEmail(data.email);
            setMobile(data.mobile);
            setAddress(data.address);
            setProvince(data.province);
            setPostalCode(data.postalCode);
            setCity(data.city);
            setIsStoreManager(false);
          }
        } catch (err) {}
      };
      fetchData();
    }
  }, [cart, navigate]);

  const checkValidPromocode = async () => {
    try {
      const { data } = await axios.get("/api/promocodes");

      const foundPromocode = data.promoCodes.find(
        (promo) => promo.name === promoCode
      );

      if (foundPromocode) {
        // Promocode is valid, update the state
        setAppliedPromocode(foundPromocode.name);
        setPromoPercentage(foundPromocode.percentage);
        setPromocodeInputDisabled(true);
        toast.success("Promocode Applied Successfully");
      } else {
        // Promocode not valid
        toast.error("Promocode Not Valid");
        setPromocodeInputDisabled(false);
        setPromoCode("");
        setAppliedPromocode(null);
        setPromoPercentage(null);
      }
    } catch (error) {
      // Handle API error
      toast.error("Error checking Promocode validity");
      console.error(error);
    }
  };

  const removePromocode = () => {
    setPromocodeInputDisabled(false);
    setPromoCode("");
    setAppliedPromocode(null);
    setPromoPercentage(null);
  };

  // State to store the custom price entered by the user

  // Function to handle changes in the input field
  const handleCustomPriceChange = (e) => {
    setCustomPrice(e.target.value);
  };

  return (
    <div className="container">
      {/* <CheckoutSteps step1 step2 step3 step4></CheckoutSteps> */}
      <Helmet>
        <title>Check-Out</title>
      </Helmet>
      <Row className="mt-3 mb-1">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className="text-center" to="/">
              <i className="fas fa-home"></i> Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className="text-center" to="/cart">
              Cart
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Checkout</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        {/* <h6 className="my-1">Checkout</h6> */}

        <Col md={8}>
          {isStoreManager === true && (
            <Card className="mb-3 p-0 bg-dark text-light border border-secondary border-1">
              <Card.Header className=" bg-secondary text-white">
                <Card.Title>Customer Information</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="p-3">
                  <Col md={6}>
                    <Form autoComplete="off">
                      <Form.Group className="mb-3" controlId="mobile">
                        {/* <Form.Label>Customer Mobile Number</Form.Label> */}
                        <Form.Control
                          className="bg-dark text-light border border-secondary"
                          required
                          maxLength={10}
                          minLength={10}
                          autoComplete="off"
                          placeholder="Enter Customer Mobile Number"
                          type="Number"
                          onChange={(e) => {
                            setMobile(e.target.value);
                            setName("");
                          }}
                        />
                      </Form.Group>

                      <div className="mb-3">
                        <Button
                          variant="success"
                          type="button"
                          onClick={fetchCustomerDetails}
                        >
                          Search Customer
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col md={6}>
                    <ListGroup className="border-0 p-0">
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Name
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              value={fullName}
                              className="bg-dark text-light border border-secondary"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Mobile
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {isStoreManager === false && (
            <Card className=" mb-3 p-0 bg-dark text-light border border-secondary border-1">
              <Card.Header className=" bg-secondary text-white">
                <Card.Title>Customer Information</Card.Title>
              </Card.Header>
              <Card.Body className="border-0 p-0">
                <ListGroup className="border-0 p-0">
                  <Row className="p-3">
                    <Col md={6}>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Name
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              value={fullName}
                              className="bg-dark text-light border border-secondary"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Email
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Mobile
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </Col>
                    <Col md={6}>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Address
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              as="textarea"
                              rows={2}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            City
                          </Col>
                          <Col md={9}>
                            {" "}
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            State
                          </Col>
                          <Col md={9}>
                            {" "}
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={province}
                              onChange={(e) => setProvince(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-dark text-light">
                        <Row>
                          <Col md={3} className="text-bold">
                            Pin Code
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              className="bg-dark text-light border border-secondary"
                              value={postalCode}
                              onChange={(e) => setPostalCode(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </Col>
                  </Row>
                </ListGroup>
              </Card.Body>
            </Card>
          )}

          <Card className=" mb-3 p-0 bg-dark text-light border border-secondary border-1">
            <Card.Header className="bg-secondary text-white">
              <Card.Title>Items</Card.Title>
            </Card.Header>
            <Card.Body>
              {cart.cartItems.length === 0 ? (
                <div className="mt-3 mb-3 text-light">
                  <h4 className="text-secondary">
                    Cart is empty.
                    <div className="mt-3 ">
                      <Link className="text-light" to="/">
                        Proceed to Shopping
                      </Link>
                    </div>
                  </h4>
                </div>
              ) : (
                <ListGroup>
                  {cart.cartItems.map((item) => (
                    <div
                      key={item._id}
                      className="mt-3 pb-3 border-bottom border-secondary border-1"
                    >
                      <Row className="align-items-center cart-lable text-dark">
                        <Col md={6} sm={12}>
                          <div className="row">
                            <div className="col-3 col-md-4 col-sm-3 m-auto align-items-center sm-mb-3 cart-lable">
                              <img
                                src={ResImage(item.image, 300)}
                                alt={item.name}
                                className="img-fluid rounded img-thumbnail sm-mb-3"
                              ></img>{" "}
                            </div>
                            <div className="col-9 col-md-8 col-sm-9 m-auto align-items-center sm-mb-3 cart-lable text-dark">
                              <Link
                                to={`/${item?.category?.name?.toLowerCase()}/${item?.brand?.name?.toLowerCase()}/${
                                  item.slug
                                }`}
                              >
                                <p className="m-auto h6 cart-lable text-light">
                                  {item.name}
                                </p>
                                <p className="m-auto h6 cart-lable text-light">
                                  {item.variantPrimary &&
                                    item.variantPrimary.name}
                                  {item.variant && (
                                    <> : {item.variant.variant.name}</>
                                  )}
                                </p>
                                <p className="m-auto h6 cart-lable text-light">
                                  {item.variantSecondary &&
                                    item.variantSecondary.name}
                                  {item.attribute && (
                                    <> : {item.attribute.variant.name}</>
                                  )}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col md={6} sm={12}>
                          <div className="row mt-3 text-light">
                            <div className="col col-6">
                              Quantity : {item.quantity}
                              <span className="d-block">
                                Unit Price : {IndianNumber(item.unitPrice)}
                              </span>
                              <span className="d-block">
                                Tax : {IndianNumber(item.tax)} (
                                {item.category.tax}%)
                              </span>
                            </div>
                            <div className="col col-4 m-auto">
                              ₹{" "}
                              {/* {IndianNumber(
                                item.attribute.price * item.quantity
                              )} */}
                              {IndianNumber((item.attribute?.price || item.price) * item.quantity)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3 bg-dark text-light border border-secondary border-1">
            <Card.Header className="bg-secondary text-white">
              <Card.Title>Payment Method</Card.Title>
            </Card.Header>
            <Card.Body className="bg-dark">
              {isStoreManager === false ? (
                <>
                  <div className="mb-3">
                    <Form.Check
                      className="text-light"
                      variant="success"
                      type="radio"
                      id="Online"
                      label="Online"
                      value="Online"
                      checked={paymentMethod === "Online"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Check
                      className="text-light"
                      type="radio"
                      id="COD"
                      label="Cash On Delivery"
                      value="COD"
                      checked={paymentMethod === "COD"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3">
                    <Form.Check
                      className="text-light"
                      type="radio"
                      id="COD"
                      label="Store Payment"
                      value="COD"
                      checked={paymentMethod === "COD"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Check
                      className="text-light"
                      variant="success"
                      type="radio"
                      id="Online"
                      label="Online"
                      value="Online"
                      checked={paymentMethod === "Online"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>

          <Card className="bg-dark text-light border border-secondary border-1 my-3">
            <Card.Header className="bg-secondary text-white">
              <Card.Title>Apply Coupan</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup.Item>
                <Form.Group className="mb-3">
                  <Form.Label>Promo Code:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter promo code"
                    value={promoCode}
                    readOnly={promocodeInputDisabled}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="success"
                  onClick={checkValidPromocode}
                >
                  Apply
                </Button>
                <Button
                  className="mx-2"
                  type="button"
                  variant="danger"
                  onClick={removePromocode}
                >
                  Remove
                </Button>
              </ListGroup.Item>

              <ListGroup.Item>
                <h6 className="mt-3">
                  Applied Promo Code:{" "}
                  {appliedPromocode
                    ? `${appliedPromocode} (${promoPercentage}%)`
                    : "None"}
                </h6>
              </ListGroup.Item>
            </Card.Body>
          </Card>

          <Card className="bg-dark text-light border border-secondary border-1">
            <Card.Header className="bg-secondary text-white">
              <Card.Title>Order Summary</Card.Title>
            </Card.Header>
            <Card.Body>
              {userInfo && userInfo.isAdmin && (
                <ListGroup variant="flush">
                  {/* Existing list items */}
                  {/* ... */}

                  {/* Custom price input field */}
                  <ListGroup.Item className="bg-dark">
                    <Row>
                      <Col lg={12} sm-={12}>
                        <b>Store Price :</b>
                      </Col>
                      <Col lg={12} sm={12} className="mt-3">
                        {/* Input field for the user to enter a custom price */}
                        <Form.Control
                          type="number"
                          placeholder="Enter Custom Price"
                          value={customPrice}
                          onChange={handleCustomPriceChange}
                        />
                      </Col>
                      <Col lg={12} sm={12} className="mt-3">
                        {/* Button to update the total price */}
                        <button
                          className="btn btn-success w-100"
                          onClick={handleUpdateTotalPrice(customPrice)}
                        >
                          Update Total Price
                        </button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              )}
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-dark text-light border-bottom border-secondary border-bottom-1">
                  <Row>
                    <Col>Sub-Total</Col>
                    {/* <Col>₹  {IndianNumber((100 * cart.itemsPrice/(100+12)).toFixed(2))}</Col> */}
                    <Col>₹ {IndianNumber(cart.itemsPrice)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark text-light border-bottom border-secondary border-bottom-1">
                  <Row>
                    <Col>Shipping</Col>
                    <Col>₹ {IndianNumber(cart.shippingPrice)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark text-light border-bottom border-secondary border-bottom-1">
                  <Row>
                    <Col>Tax</Col>
                    {/* <Col>₹ {(cart.itemsPrice - cart.itemsPrice/(1+12/100)).toFixed(2)}</Col> */}
                    <Col>₹ {IndianNumber(cart.taxPrice)}</Col>
                  </Row>
                </ListGroup.Item>
                {promoCode && (
                  <>
                    <ListGroup.Item className="bg-dark text-light border-bottom border-secondary border-bottom-1">
                      <Row>
                        <Col>Discount</Col>
                        <Col>
                          ₹{" "}
                          {IndianNumber(
                            (Number(promoPercentage) / 100) * cart.totalPrice
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </>
                )}
                <ListGroup.Item className="bg-dark text-light border-bottom border-secondary border-bottom-1">
                  <Row>
                    <Col>
                      <strong> Order Total</strong>
                    </Col>
                    <Col>
                      {/* <strong>₹ {IndianNumber(cart.totalPrice)}</strong> */}
                      {promoCode && (
                        <strong>
                          ₹{" "}
                          {isNaN(parseFloat(customPrice))
                            ? IndianNumber(cart.totalPrice)
                            : IndianNumber(
                                cart.totalPrice -
                                  (Number(promoPercentage) / 100) *
                                    cart.totalPrice +
                                  parseFloat(customPrice)
                              )}
                        </strong>
                      )}

                      {!promoCode && (
                        <strong>
                          ₹{" "}
                          {isNaN(parseFloat(customPrice))
                            ? IndianNumber(cart.totalPrice)
                            : IndianNumber(
                                cart.totalPrice + parseFloat(customPrice)
                              )}
                        </strong>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {isStoreManager === false && (
                  <ListGroup.Item className="bg-dark text-light mt-3">
                    <div className="d-grid">
                      {paymentMethod === "COD" ? (
                        <>
                          <Button
                            type="button"
                            onClick={placeOrderHandler}
                            disabled={cart.cartItems.length === 0}
                            variant="success"
                          >
                            Place Order
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="button"
                          onClick={placePaymentOrderHandler}
                          disabled={cart.cartItems.length === 0}
                          variant="success"
                        >
                          Pay
                        </Button>
                      )}
                    </div>
                    {loading && <LoadingBox></LoadingBox>}
                  </ListGroup.Item>
                )}
                {isStoreManager === true && (
                  <ListGroup.Item className="bg-dark text-light mt-3">
                    <div className="d-grid">
                      <Button
                        type="button"
                        onClick={placeStoreOrderHandler}
                        disabled={cart.cartItems.length === 0}
                        variant="success"
                      >
                        Place Store Order
                      </Button>
                    </div>
                    {loading && <LoadingBox></LoadingBox>}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        style={{ margin: "auto" }}
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Body className="bg-light text-dark">
          <PaymnetCCAvenue html={html}></PaymnetCCAvenue>
        </Modal.Body>
      </Modal>
    </div>
  );
}

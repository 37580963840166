import { Parser } from 'html-to-react'

export default function PaymnetCCAvenue(props) {
    const { html } = props;
    const cc = Parser().parse(html);
    return (
        <iframe
        title="my-iframe"
       
        srcDoc={html}
      ></iframe>
      //  <iframe src={"data:text/html,"+encodeURIComponent({cc})}/>

        // <div >

        //     {Parser().parse(html)}
        //   {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
        // </div>
      );
  //return <Alert variant={props.variant || 'info'}>{props.children}</Alert>;
}

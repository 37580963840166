import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Card, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "../components/Product";
import ProductMini from "../components/ProductMini";
import ProductMiniSmall from "../components/ProductMiniSmall";
import { Helmet } from "react-helmet-async";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Container from 'react-bootstrap/Container';
import seoMetaInfo from "../seoMetaInfo";
import discountBanner from "../images/discount-banner.jpg";

//import ProductCarousel from '../components/ProductCarousel'
import { Virtual, FreeMode, Scrollbar, Pagination, Autoplay, EffectCoverflow, Navigation, Mousewheel, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/virtual';
import "swiper/css/free-mode";
import 'swiper/css/scrollbar';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/grid";
import ScrollAnimation from 'react-animate-on-scroll';


const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, products: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  const [categories, setCategoriesli] = useState([]);
  const [promos, setPromosli] = useState([]);
  useEffect(() => {


    const fetchData = async () => {


      dispatch({ type: "FETCH_REQUEST" });
      try {
        const promosresult = await axios.get("/api/promos");
        setPromosli(promosresult.data);


        const params = {
          type: 'isFeatured'
        };

        const result = await axios.get(
          '/api/products', {
          params: {
            type: 'isFeatured'
          }
        }
        );


        const categoriesresult = await axios.get("/api/categories");
        setCategoriesli(categoriesresult.data);

        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }

      // 
    };
    fetchData();
  }, []);

  // Create array with 1000 slides
  const slides = Array.from({ length: 1000 }).map(
    (el, index) => `Slide ${index + 1}`
  );



  const responsive = {
    640: {
      slidesPerView: 2.2,
      spaceBetween: 5,
      slidesPerGroupSkip: 2
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
      slidesPerGroupSkip: 3
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesPerGroupSkip: 4
    }
  };

  const responsive2 = {
    640: {
      slidesPerView: 2,
      spaceBetween: 10,
      slidesPerGroupSkip: 2
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
      slidesPerGroupSkip: 3
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroupSkip: 4
    }
  };
  const { metaTitle, metaDescription } = seoMetaInfo['homePage'] || {};
  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Swiper
        className="mySwiper height500 d-none d-lg-block d-md-block home-hero-section"
        pagination={{
          dynamicBullets: false, clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        navigation

        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }}
        modules={[FreeMode, Pagination, Navigation, Mousewheel, Autoplay]} spaceBetween={20} slidesPerView={1} >
        {promos.filter((x) => x.type === "homeMainSlider").map((item, index) => (
          <SwiperSlide className="swiperMainSlide">
            <Link to={{ pathname: `${item.link}` }}>
              <img src={item.image}></img>
            </Link>

          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className="mySwiper d-lg-none d-md-none d-sm-block home-hero-section"
        pagination={{
          dynamicBullets: false, clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        navigation

        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }}
        modules={[FreeMode, Pagination, Navigation, Mousewheel, Autoplay]} spaceBetween={20} slidesPerView={1} >
        {promos.filter((x) => x.type === "homeMainSlider").map((item, index) => (
          <SwiperSlide className="swiperMainSlide">
            <Link to={{ pathname: `${item.link}` }}>
              <img src={item.imagePotrait} style={{ maxHeight: '90vh !important' }}></img>
            </Link>

          </SwiperSlide>
        ))}
      </Swiper>

      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>

          <Container >
            <div className="mt-3 text-uppercase">
              <Link className='text-link' style={{ fontSize: 'medium' }} to={{ pathname: '/search', search: `category=Racket` }}>
                <span>
                  Rackets
                </span>
                <span style={{ float: 'right', fontSize: 'small' }}>
                  View All
                  <i className="fa fa-arrow-right fa-sm ms-1"></i>
                </span>
              </Link>
              <Row>
                {promos.filter((x) => (x.type === "homeCategory" && x.refSlug === "Racket")).map((item, index) => (
                  <div className="col col-lg-6 col-sm-12 my-1">
                    <Link to={{ pathname: `${item.link}` }}>
                      <img style={{ width: '-webkit-fill-available', maxHeight: '250px' }} src={item.image} />
                    </Link>
                  </div>
                ))}
              </Row>
              <Swiper
                className="mySwiper mt-3"
                style={{ paddingBottom: "10px" }}
                breakpoints={responsive}
                freeMode={true}
                mousewheel={true}
                scrollbar={{
                  hide: true,
                }}
                // pagination={{
                //   dynamicBullets: true, clickable: true,
                // }}

                navigation
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                modules={[FreeMode, Navigation, Scrollbar, Mousewheel]} spaceBetween={10} slidesPerView={2.05}  >
                {products.filter((x) => x.category.name === "Racket").map((product, index) => (
                  <SwiperSlide key={product.slug}>

                    <Product product={product}></Product>
                  </SwiperSlide>
                ))}

              </Swiper>

            </div>


            <div className="mt-3 text-uppercase">
              <Link className="text-link" to={{ pathname: '/search', search: `category=shuttle` }}>
                <span>
                  Shuttle
                </span>
                <span style={{ float: 'right', fontSize: 'small' }}>
                  View All
                  <i className="fa fa-arrow-right fa-sm ms-1"></i>
                </span>
              </Link>
              <Row>
                {promos.filter((x) => (x.type === "homeCategory" && x.refSlug === "shuttle")).map((item, index) => (
                  <div className="col col-lg-6 col-sm-12 my-1">
                    <Link to={{ pathname: `${item.link}` }}>
                      <img style={{ width: '-webkit-fill-available', maxHeight: '250px' }} src={item.image} />
                    </Link>
                  </div>
                ))}
              </Row>
              <Swiper
                className="mySwiper mt-3"
                style={{ paddingBottom: "10px" }}
                breakpoints={responsive}
                freeMode={true}
                mousewheel={true}
                grid={{
                  rows: 1,
                  fill: "row",
                }}
                navigation
                scrollbar={{
                  hide: true,
                }}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}
                modules={[FreeMode, Navigation, Scrollbar, Mousewheel, Grid]} spaceBetween={10} slidesPerView={2.05} >
                {products.filter((x) => x.category.name === "Shuttle").map((product, index) => (
                  <SwiperSlide key={product.slug}>
                    <Product product={product}></Product>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>


            <div className="mt-3 text-uppercase">
              <Link className='text-link' to={{ pathname: '/search', search: `category=Shoe` }}>
                <span>
                  Shoes
                </span>
                <span style={{ float: 'right', fontSize: 'small' }}>
                  View All
                  <i className="fa fa-arrow-right fa-sm ms-1"></i>
                </span>
              </Link>
              <Row>
                {promos.filter((x) => (x.type === "homeCategory" && x.refSlug === "Shoe")).map((item, index) => (
                  <div className="col col-lg-6 col-sm-12 my-1">
                    <Link to={{ pathname: `${item.link}` }}>
                      <img style={{ width: '-webkit-fill-available', maxHeight: '250px' }} src={item.image} />
                    </Link>
                  </div>
                ))}
              </Row>

              <Swiper
                className="mySwiper mt-3"
                style={{ paddingBottom: "10px" }}
                breakpoints={responsive}
                freeMode={true}
                mousewheel={true}
                scrollbar={{
                  hide: true,
                }}

                grid={{
                  rows: 1,
                  fill: "row",
                }}
                navigation
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                modules={[FreeMode, Navigation, Scrollbar, Mousewheel, Grid]} spaceBetween={10} slidesPerView={2.05}  >
                {products.filter((x) => x.category.name === "Shoe").map((product, index) => (
                  <SwiperSlide key={product.slug}>
                    <Product product={product}></Product>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>


            <div className="mt-3 text-uppercase">
              <Link className="text-link" to={{ pathname: '/search', search: `category=Apparel` }}>
                <span>
                  Apparel
                </span>
                <span style={{ float: 'right', fontSize: 'small' }}>
                  View All
                  <i className="fa fa-arrow-right fa-sm ms-1"></i>
                </span>
              </Link>
              <Row>
                {promos.filter((x) => (x.type === "homeCategory" && x.refSlug === "Apparel")).map((item, index) => (
                  <div className="col col-lg-6 col-sm-12 my-1">
                    <Link to={{ pathname: `${item.link}` }}>
                      <img style={{ width: '-webkit-fill-available', maxHeight: '250px' }} src={item.image} />
                    </Link>
                  </div>
                ))}
              </Row>
              <Swiper
                className="mySwiper mt-3"
                style={{ paddingBottom: "10px" }}
                breakpoints={responsive}
                freeMode={true}
                mousewheel={true}
                grid={{
                  rows: 1,
                  fill: "row",
                }}
                navigation
                scrollbar={{
                  hide: true,
                }}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}
                modules={[FreeMode, Navigation, Scrollbar, Mousewheel, Grid]} spaceBetween={10} slidesPerView={2.05} >
                {products.filter((x) => x.category.name === "Apparel").map((product, index) => (
                  <SwiperSlide key={product.slug}>
                    <Product product={product}></Product>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <h1 style={{ fontSize: "0" }}>Explore Top-Quality Sports Gear at Sportspalace.in</h1>
          </Container>
        </>
      )}

    </div>
  );
}
export default HomeScreen;

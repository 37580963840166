import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./screens/HomeScreen";

import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Store } from "./Store";
import FaqScreen from "./screens/StaticContentScreens/FaqScreen";
import ShippingPolicyScreen from "./screens/StaticContentScreens/ShippingPolicyScreen";
import AboutScreen from "./screens/StaticContentScreens/AboutScreen";
import TermsConditionsScreen from "./screens/StaticContentScreens/TermsConditionsScreen";
import RefundPolicyScreen from "./screens/StaticContentScreens/RefundPolicyScreen";
import ContactScreen from "./screens/StaticContentScreens/ContactScreen";
import PrivacyPolicyScreen from "./screens/StaticContentScreens/PrivacyPolicyScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import SigninScreen from "./screens/SigninScreen";
import ShippingAddressScreen from "./screens/ShippingAddressScreen";
import SignupScreen from "./screens/SignupScreen";
import PaymentMethodScreen from "./screens/PaymentMethodScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import InvoiceScreen from "./screens/invoiceScreen";
import OrderHistoryScreen from "./screens/OrderHistoryScreen";
import ProfileScreen from "./screens/ProfileScreen";
import Button from "react-bootstrap/Button";
import { getError } from "./utils";
import axios from "axios";
import SearchBox from "./components/SearchBox";
import AutoSearch from "./components/AutoSearchBox";
import SearchScreen from "./screens/SearchScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import DashboardScreen from "./screens/DashboardScreen";
import DashboardUserScreen from "./screens/DashboardUserScreen";
import ProductCreateScreen from "./screens/Products/ProductCreateScreen";
import ProductListScreen from "./screens/Products/ProductListScreen";
import ProductFeaturedScreen from "./screens/Products/ProductFeaturedScreen";
import ProductDetailScreen from "./screens/Products/ProductDetailScreen";
import AdminRoute from "./components/AdminRoute";
import ContentCreateScreen from "./screens/Content/ContentCreateScreen";
import ContentEditScreen from "./screens/Content/ContentEditScreen";
import ContentListScreen from "./screens/Content/ContentListScreen";
import PromoCreateScreen from "./screens/Promo/PromoCreateScreen";
import PromoEditScreen from "./screens/Promo/PromoEditScreen";
import PromoListScreen from "./screens/Promo/PromoListScreen";
import BrandCreateScreen from "./screens/BrandCreateScreen";
import BrandListScreen from "./screens/BrandListScreen";
import BrandEditScreen from "./screens/BrandEditScreen";
import CategoryCreateScreen from "./screens/CategoryCreateScreen";
import CategoryListScreen from "./screens/CategoryListScreen";
import CategoryEditScreen from "./screens/CategoryEditScreen";
import VariantGroupListScreen from "./screens/Variant/VariantGroupListScreen";
import VariantGroupCreateScreen from "./screens/Variant/VariantGroupCreateScreen";
import SpecsCreateScreen from "./screens/Specs/SpecsCreate.Screen";
import SpecsListsScreen from "./screens/Specs/SpecsListScreen";

import VariantListScreen from "./screens/Variant/VariantListScreen";
import VariantCreateScreen from "./screens/Variant/VariantCreateScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import MapScreen from "./screens/MapScreen";
import ForgetPasswordScreen from "./screens/ForgetPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import ProductScreen from "./screens/ProductScreen";
import PaymentReturnScreen from "./screens/PaymentReturnScreen";
import ProductCategoryScreenPage from "./screens/ProductCategoryScreenPage";
import SubCategoryPageScreens from "./screens/subCategoryPageScreens";
import NotFound from "./components/NotFound";
import SubCategoryBrnadPage from "./screens/SubCategroryBrandPage";

import DarkImage from "./logo-dark.png";
// import InfoModal from './components/InfoModal';

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/scrollToTop";
import "animate.css/animate.min.css";
import "css-pro-layout/dist/css/css-pro-layout.min.css";

import Navigation from "./components/Navigation";
import { useScroll } from "./components/UseScroll";
import Toggle from "./components/Toggle";
import PromoCodeCreateScreen from "./screens/Promocode/PromoCodeCreateScreen";
import PromoCodeEditScreen from "./screens/Promocode/PromoCodeEditScreen";
import PromoCodeListScreen from "./screens/Promocode/PromoCodeListScreen";
import NavigationForDesktop from "./components/NavigationForDesktop";
import NavigationForMobile from "./components/NavigationForMobile";
import { da } from "date-fns/locale";
import { Application } from "./components/Application";
import ThankYouPage from "./components/ThankYou";

function App() {
  const [isDark, setIsDark] = useState(false);

  const { scrollY, x, scrollDirection } = useScroll();
  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
    },
    hidden: {
      visibility: "hidden",
      transition: "all 0.5s",
      transform: "translateY(-100%)",
    },
  };
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { show, fullBox, cart, userInfo, sidebarIsOpen } = state;
  const [showMobileMenuRight, setShowMobileMenuRight] = useState(false);

  const { navIsOpen } = state;
  const handleNavShow = () => {
    if (navIsOpen === true) {
      ctxDispatch({ type: "SET_NAV_OFF" });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      ctxDispatch({ type: "SET_NAV_ON" });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const handleClose = () => {
    ctxDispatch({ type: "SET_MODALSHOW_OFF" });
  };

  const handleShow = () => {
    ctxDispatch({ type: "SET_MODALSHOW_ON" });
    console.log("hello i am getting clicked");
  };

  const handleScrollTop = () => {
    <ScrollToTop />;
  };

  const handleSidebarIsOpen = () => {
    if (sidebarIsOpen === true) {
      ctxDispatch({ type: "SET_SIDEBAR_OFF" });
    } else {
      ctxDispatch({ type: "SET_SIDEBAR_ON" });
    }
  };
  const handleSideBarClose = () => {
    ctxDispatch({ type: "SET_SIDEBAR_OFF" });
  };

  const handleSideBarShow = () => {
    ctxDispatch({ type: "SET_SIDEBAR_ON" });
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesresult = await axios.get("/api/categories");
        setCategories(categoriesresult.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);

  const [visible, setVisible] = useState(false); // State to manage the visibility of off-canvas menu

  const toggleOffCanvas = () => {
    setVisible(!visible); // Toggle the visibility of off-canvas menu
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div
        className={
          sidebarIsOpen
            ? fullBox
              ? "site-container active-cont d-flex flex-column full-box"
              : "site-container active-cont d-flex flex-column"
            : fullBox
              ? "site-container d-flex flex-column full-box"
              : "site-container d-flex flex-column"
        }
      >
        <ToastContainer
          newestOnTop="false"
          position="bottom-center"
          limit={5}
          enableMultiContainer="true"
          autoClose="1000"
        />

        <div data-theme={isDark ? "dark" : "light"}>
          <nav
            class="navbar fixed-top navbar-expand navbar-dark bg-dark"
            style={
              scrollY <= 100 || scrollDirection === "down"
                ? styles.active
                : styles.hidden
            }
          >
            <div class="container-fluid clearfix">
              <div class="col-lg-2 col-4">
                <Link to="/" className="navbar-brand">
                  <img
                    src={
                      isDark
                        ? "https://res.cloudinary.com/dzfznnc5l/image/upload/v1688282908/natax6xm7aztxo3rf7gc.png"
                        : DarkImage
                    }
                    class="d-inline-block align-text-top logo"
                  />
                </Link>
              </div>
              <NavigationForDesktop
                handleShow={handleShow}
                cart={cart}
                userInfo={userInfo}
                isDark={isDark}
                setIsDark={setIsDark}
                handleNavShow={handleNavShow}
                visible={visible}
                setVisible={setVisible}
                toggleOffCanvas={toggleOffCanvas}
                DarkImage={DarkImage}
              />
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              dialogClassName="fullscreen-modal"
              scrollable="scrollable"

            >
              <AutoSearch />
            </Modal>
          </nav>
          <div className="d-block d-lg-none">
            <NavigationForMobile
              handleShow={handleShow}
              isDark={isDark}
              setIsDark={setIsDark}
              cart={cart}
              userInfo={userInfo}
              showMobileMenuRight={showMobileMenuRight}
              visible={visible}
              setVisible={setVisible}
              toggleOffCanvas={toggleOffCanvas}
              DarkImage={DarkImage}
            />
          </div>
        </div>

        <div
          className={
            sidebarIsOpen
              ? "active-nav side-navbar d-flex justify-content-between flex-wrap flex-column"
              : "side-navbar d-flex justify-content-between flex-wrap flex-column"
          }
        ></div>

        <main
          className=""
          style={{ minHeight: "80vh" }}
          data-theme={isDark ? "dark" : "light"}
        >
          <Routes>
            <Route path="/product/:slug" element={<ProductScreen />} />
            {/* <Route
              path="/:category/:brand/:slug"
              element={<ProductScreen />}
            /> */}
            <Route path="/shipping-policy" element={<ShippingPolicyScreen />} />
            <Route path="/refund-policy" element={<RefundPolicyScreen />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route
              path="/terms-conditions"
              element={<TermsConditionsScreen />}
            />
            <Route path="/about-us" element={<AboutScreen />} />
            <Route path="/contact-us" element={<ContactScreen />} />
            <Route path="/faq" element={<FaqScreen />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route path="/search" element={<SearchScreen />} />
            <Route path="/signin" element={<LoginScreen />} />

            <Route path="/application" element={<Application />} />
            <Route path="/application" element={<Application />} />
            <Route path="/thank-you" element={<ThankYouPage />} />


            <Route
              path="/badminton-rackets" element={<ProductCategoryScreenPage categoryprops="racket" />}
            />
            <Route
              path="/badminton-shoes" element={<ProductCategoryScreenPage categoryprops="shoe" />}
            />
            <Route
              path="/badminton-shuttlecocks" element={<ProductCategoryScreenPage categoryprops="shuttle" />}
            />
            <Route
              path="/badminton-apparel" element={<ProductCategoryScreenPage categoryprops="apparel" />}
            />
            <Route
              path="/badminton-string" element={<ProductCategoryScreenPage categoryprops="string" />}
            />
            <Route
              path="/badminton-bags" element={<ProductCategoryScreenPage categoryprops="bags" />}
            />

            {/* Accessories Hiding */}
            {/* <Route
              path="/accessories" element={<ProductCategoryScreenPage categoryprops="accessories" />}
            />
            <Route
              path="/accessories/li-ning" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="accessories" />}
            />
            <Route
              path="/accessories/yonex" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="accessories" />}
            /> */}
            <Route
              path="/yonex/badminton-rackets" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="racket" url="badminton-rackets" />}
            />
            <Route
              path="/li-ning/badminton-rackets" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="racket" url="badminton-rackets" />}
            />
            <Route
              path="/yonex/badminton-shoes" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="shoe" url="badminton-shoes" />}
            />
            <Route
              path="/li-ning/badmiton-shoes" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="shoe" url="badminton-shoes" />}
            />
            <Route
              path="/yonex/shuttlecocks" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="shuttle" url="badminton-shuttlecocks" />}
            />
            <Route
              path="/li-ning/shuttlecocks" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="shuttle" url="badminton-shuttlecocks" />}
            />
            <Route
              path="/yonex/badminton-apparel" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="apparel" url="badminton-apparel" />}
            />
            <Route
              path="/li-ning/badminton-apparel" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="apparel" url="badminton-apparel" />}
            />
            <Route
              path="/yonex/badminton-string" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="string" url="badminton-string" />}
            />
            <Route
              path="/li-ning/badminton-string" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="string" url="badminton-string" />}
            />
            <Route
              path="/yonex/badminton-bags" element={<SubCategoryPageScreens brandsProps="Yonex" catgo="bags" url="badminton-bags" />}
            />
            <Route
              path="/li-ning/badminton-bags" element={<SubCategoryPageScreens brandsProps="Li-Ning" catgo="bags" url="badminton-bags" />}
            />
            <Route
              path="/attacking-badminton-rackets" element={<SubCategoryBrnadPage brandsProps="Head Heavy" catgo="racket" url="badminton-rackets" />}
            />
            <Route
              path="/defensive-badminton-rackets" element={<SubCategoryBrnadPage brandsProps="Head Light" catgo="racket" url="badminton-rackets" />}
            />
            <Route
              path="/balanced-badminton-rackets" element={<SubCategoryBrnadPage brandsProps="Even Balance" catgo="racket" url="badminton-rackets" />}
            />
            <Route
              path="/professional-badminton-shoes" element={<SubCategoryBrnadPage brandsProps="Professional" catgo="shoe" url="badminton-shoes" />}
            />
            <Route
              path="/intermediate-badminton-shoes" element={<SubCategoryBrnadPage brandsProps="Intermediate" catgo="shoe" url="badminton-shoes" />}
            />
            <Route
              path="/nylon-shuttlecocks" element={<SubCategoryBrnadPage brandsProps="Nylon" catgo="shuttle"  url="badminton-shuttlecocks" />}
            />
            <Route
              path="/feather-shuttlecocks" element={<SubCategoryBrnadPage brandsProps="Feather" catgo="shuttle" url="badminton-shuttlecocks" />}
            />
            <Route
              path="/badminton-apparel-t-shirt" element={<SubCategoryBrnadPage brandsProps="T-Shirt" catgo="apparel" url="badminton-apparel" />}
            />
            <Route
              path="/badminton-apparel-short" element={<SubCategoryBrnadPage brandsProps="Short" catgo="apparel" url="badminton-apparel" />}
            />
            <Route
              path="/badminton-apparel-polo" element={<SubCategoryBrnadPage brandsProps="Polo" catgo="apparel" url="badminton-apparel" />}
            />



            <Route path="/forget-password" element={<ForgetPasswordScreen />} />
            <Route
              path="/reset-password/:token"
              element={<ResetPasswordScreen />}
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfileScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/map"
              element={
                <ProtectedRoute>
                  <MapScreen />
                </ProtectedRoute>
              }
            />
            <Route path="/checkout" element={<PlaceOrderScreen />} />
            <Route
              path="/order/:id"
              element={
                <ProtectedRoute>
                  <OrderScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/invoice/:id" element={<InvoiceScreen />}></Route>
            <Route
              path="/orderhistory"
              element={
                <ProtectedRoute>
                  <OrderHistoryScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/shipping" element={<ShippingAddressScreen />}></Route>
            <Route path="/payment" element={<PaymentMethodScreen />}></Route>
            {/* Admin Routes */}
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <DashboardUserScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/paymentreturn/:status"
              element={
                <ProtectedRoute>
                  <PaymentReturnScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/admin/dashboard"
              element={
                <AdminRoute>
                  <DashboardScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/variantGroups"
              element={
                <AdminRoute>
                  <VariantGroupListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/variantGroups/create"
              element={
                <AdminRoute>
                  <VariantGroupCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/variants"
              element={
                <AdminRoute>
                  <VariantListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/variants/create"
              element={
                <AdminRoute>
                  <VariantCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/specs/create"
              element={
                <AdminRoute>
                  <SpecsCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/specs"
              element={
                <AdminRoute>
                  <SpecsListsScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/product/create"
              element={
                <AdminRoute>
                  <ProductCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/product/list"
              element={
                <AdminRoute>
                  <ProductListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/product/featured"
              element={
                <AdminRoute>
                  <ProductFeaturedScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/product/edit/:id"
              element={
                <AdminRoute>
                  <ProductEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/product/:id"
              element={
                <AdminRoute>
                  <ProductDetailScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/orders"
              element={
                <AdminRoute>
                  <OrderListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/users"
              element={
                <AdminRoute>
                  <UserListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/brands"
              element={
                <AdminRoute>
                  <BrandListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/brandCreate"
              element={
                <AdminRoute>
                  <BrandCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/brand/edit/:id"
              element={
                <AdminRoute>
                  <BrandEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/categories"
              element={
                <AdminRoute>
                  <CategoryListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/categoryCreate"
              element={
                <AdminRoute>
                  <CategoryCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/category/edit/:id"
              element={
                <AdminRoute>
                  <CategoryEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promo/create"
              element={
                <AdminRoute>
                  <PromoCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promo/edit/:id"
              element={
                <AdminRoute>
                  <PromoEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promos"
              element={
                <AdminRoute>
                  <PromoListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promocode/create"
              element={
                <AdminRoute>
                  <PromoCodeCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promocode/edit/:id"
              element={
                <AdminRoute>
                  <PromoCodeEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/promocode"
              element={
                <AdminRoute>
                  <PromoCodeListScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/content/create"
              element={
                <AdminRoute>
                  <ContentCreateScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/content/edit/:id"
              element={
                <AdminRoute>
                  <ContentEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="/admin/contents"
              element={
                <AdminRoute>
                  <ContentListScreen />
                </AdminRoute>
              }
            ></Route>

            <Route
              path="/admin/user/:id"
              element={
                <AdminRoute>
                  <UserEditScreen />
                </AdminRoute>
              }
            ></Route>
            <Route exact path="/" element={<HomeScreen />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <div
          style={{ borderTop: "solid #27292b 1px" }}
          data-theme={isDark ? "dark" : "light"}
        ></div>

        <footer
          className="py-4 footerdark"
          data-theme={isDark ? "dark" : "light"}
        >
          <div className="container">
            <div
              className="row"
              style={{
                width: "-webkit-fill-available",
                maxWidth: "100%",
                margin: "auto",
              }}
            >
              <div className="col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-3">
                <h4 className="text-white mb-2 text-uppercase fw-bold fs-6">
                  Information
                </h4>
                <div className="footer-link d-flex flex-column">
                  <Link
                    to="/privacy-policy"
                    className="text-secondary mb-1 no-underline"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="/refund-policy"
                    className="text-secondary mb-1 no-underline"
                  >
                    Refund Policy
                  </Link>
                  <Link
                    to="/shipping-policy"
                    className="text-secondary mb-1 no-underline"
                  >
                    Shipping Policy
                  </Link>
                  <Link
                    to="/terms-conditions"
                    className="text-secondary mb-1 no-underline"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-3">
                <h4 className="text-white mb-2 text-uppercase fw-bold fs-6">
                  Account
                </h4>
                <div className="footer-link d-flex flex-column">
                  <Link
                    to="/about-us"
                    className="text-secondary mb-1 no-underline"
                  >
                    About Us
                  </Link>
                  <Link to="/faq" className="text-secondary mb-1 no-underline">
                    Faq
                  </Link>
                  <Link
                    to="/contact-us"
                    className="text-secondary mb-1 no-underline"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-3">
                <h4 className="text-white mb-2 text-uppercase fw-bold fs-6">
                  Quick Links
                </h4>
                <div className="footer-link no-underline d-flex flex-column">
                  <Link
                    to="/badminton-rackets"
                    className="text-secondary mb-1 no-underline"
                  >
                    Rackets
                  </Link>
                  <Link
                    to="/badminton-shuttlecocks"
                    className="text-secondary mb-1 no-underline"
                  >
                    Shuttles
                  </Link>
                  <Link to="/badminton-shoes" className="text-secondary mb-1 no-underline">
                    Shoes
                  </Link>
                  <Link
                    to="/badminton-apparel"
                    className="text-secondary mb-1 no-underline"
                  >
                    Apparel
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-3">
                <h4 className="text-white mb-2 text-uppercase fw-bold fs-6">
                  Contact Us
                </h4>
                <div className="w-100">
                  <a
                    href="tel:+91 9010613693"
                    className="mt-3 d-block mb-1 text-secondary"
                  >
                    +91 9010613693
                  </a>
                  <a
                    href="mailto:sportspalaceagway@gmail.com"
                    className="mt-1 d-block mb-0 text-secondary text-break"
                  >
                    sportspalaceagway@gmail.com
                  </a>
                  <h4 className="text-white mb-2 text-uppercase fw-bold fs-6 mt-3"></h4>
                  
                  {/* 
                  
                  <div class="social-links-footer">
                    <div class="footer-insta">
                      <a href="#">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                    <div class="footer-insta">
                      <a href="#">
                        <i class="fab fa-facebook"></i>
                      </a>
                    </div>
                    <div class="footer-insta">
                      <a href="#">
                        <i class="fab fa-whatsapp"></i>
                      </a>
                    </div>
                    <div class="footer-insta">
                      <a href="#">
                        <i class="fab fa-telegram"></i>
                      </a>
                    </div>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </footer>
        <footer
          className="py-4 footerdark"
          style={{ borderTop: "solid gray 1px" }}
          data-theme={isDark ? "dark" : "light"}
        >
          <div className="container-xxl">
            <div className="row">
              <div className="col-12">
                <p className="text-center mb-0 text-white footer-copyright">
                  &copy; {new Date().getFullYear()} - Agway Sports Pvt Ltd
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;

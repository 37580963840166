import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import { toast } from 'react-toastify';

export default function PaymentReturnScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { status } = useParams();
  useEffect(() => {
    if (status === 'Success') {
        ctxDispatch({ type: 'CART_CLEAR' });
        
        localStorage.removeItem('cartItems');
        toast.success('Payment Success');
      navigate('/orders');
    }
    else {
        toast.error('Payment ' + status);
        navigate('/checkout');
    }
  }, []);

  return (
    <div>
      
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Card, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Store } from "../Store";
import { toast } from 'react-toastify';
import { getError } from "../utils";
import ListGroup from 'react-bootstrap/ListGroup';

export default function ProductSpecs(props) {
    const { state } = useContext(Store);
    const { userInfo } = state;
    const { product } = props;
    const [specsList, setSpecsList] = useState([]);
    const [productSpecsList, setProductSpecsList] = useState([]);
    const [catspecsList, setCatspecsList] = useState([]);

    const fetchProductData = async () => {

        try {
            // const { data } = await axios.get(`/api/products/${product._id}`);
            setProductSpecsList(product.specs);
        } catch (err) {

        }
    };
 
    const fetchCategoryData = async () => {
        try {
            const { data } = await axios.get(`/api/categories/catDetails/${product.category._id}`);
           
            var array = [];
           
            data.specs.map((item) => {
                var val = null;
                if(product.specs.find(x => x.spec._id === item.spec._id)){
                    val = productSpecsList.find(x => x.spec._id === item.spec._id).value
                }
                array.push(
                    {
                        "spec": item.spec._id,
                        "label": item.spec.name,
                        "value": val
                    }
                );
            }); 
            setSpecsList(array);
        } catch (err) {

        }
    };
 
    const fetchData = async () => {
        try {
            const { data } = await axios.get(`/api/categories/catDetails/${product.category._id}`);
           
            var array = [];
            data.specs.forEach(item => {
               
                var val = null;
                if(product.specs.find(x => x.spec._id === item.spec._id)){
                    val = product.specs.find(x => x.spec._id === item.spec._id).value
                }
                array.push(
                    {
                        "spec": item.spec._id,
                        "label": item.spec.name,
                        "value": val
                    }
                );
            
           });
            setSpecsList(array);
        } catch (err) {

        }
    };

    useEffect(() => {
    
        fetchData();

    }, []);


    const handleChange = (e, index) => {
        const values = [...specsList];
        values[index].value = e.target.value;
        setSpecsList(values);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            var array = [];
            specsList.map((item) => {
                array.push(
                    {
                        "spec": item.spec,
                        "value": item.value
                    }
                );
        

            }); 
            await axios.post(
                `/api/products/${product._id}/specs`,
                {
                    items: array
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Updated Successfully');
        } catch (err) {
            toast.error(getError(err));

        }
    };

    return (

        <Row className="mt-1 mb-3">

            <Col md={5} sm={12}>
                <Card className="mt-3">
                    <Card.Header className="mt-auto">
                        <span className="mt-auto">
                            Specifications
                        </span>
                        <span>
                            <Button onClick={submitHandler} className="float-end" type="buttom" size="sm" variant='dark'>
                                Update
                            </Button>
                        </span>

                    </Card.Header>
                    <Card.Body className='border-0 p-0'>
                      
                        <ListGroup className='border-0 p-0'>
                            {specsList && specsList.map((item, index) => (
                                <ListGroup.Item className='bg-white text-dark'>
                                    <Row>
                                        <Col md={5} >{item.label}</Col>
                                     

                                        
                                        <Col md={7}>
                                            <Form.Control
                                                key={index}
                                                index={index}
                                                value={item.value}
                                                onChange={(e) => handleChange(e, index)}
                                                required
                                            /></Col>

                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    );
}

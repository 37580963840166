import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom"; 

export const Application = () => {
  const [validated, setValidated] = useState(false);
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    coachGame: "Tennis",
    academyName: "",
    academyAddress: "",
    contactNumber: "",
    panCardNo: "",
    city: "",
    state: "",
    noOfStudents: "",
    noOfTournamentPlayers: "",
    referredBy: "",
    email: "",
    password: "",
  });
  //   console.log(formData);
  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "gender") {
      // Handle gender select input
      setFormData({ ...formData, gender: value });
    } else if (id === "tennis" || id === "squash" || id === "badminton") {
      // Handle coach game radio inputs
      setFormData({ ...formData, coachGame: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };
  console.log(formData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("/api/application", formData);
      if (response.status === 200) {
        // Handle success
        toast.success("Application submitted successfully!", {
          onClose: () => {
            // Navigate to ThankYouPage after success message is closed
            navigate("/thank-you");
          },
        });
        console.log("Data saved successfully!");
      } else {
        // Handle error
        console.error("Failed to save data.");
      }
    } catch (error) {
        toast.error("Failed to store application data");
      console.error("Error:", error);
    }
    setValidated(true);
  };

  return (
    <div className="shadow container mt-3 mb-3 ">
      {/* <ToastContainer />   */}
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
          <div className="right-img-block">
            <div className="form-new-image">
              <div className="block-row text-center">
                <div className="img-row text-center">
                  <img
                    src="https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/EXTRA.webp"
                    alt="Extra Discount"
                    width="70"
                    height="70"
                    className="ls-is-cached lazyloaded b-error"
                  />
                  <noscript>
                    <img
                      src="https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/EXTRA.webp"
                      width="70"
                      height="70"
                    />
                  </noscript>
                </div>
                <h2>Extra Discount</h2>
                <p style={{ textAlign: "center" }}>
                  As a part of TEAM Sports Palace, you will be eligible to get
                  benefits of additional discounts on top of discounted prices
                  on the website. This additional discount would be available in
                  the category which you choose to become a member, i.e. Tennis,
                  Squash or Badminton.
                </p>
              </div>
              <div className="block-row text-center">
                <div className="img-row text-center">
                  <img
                    src={
                      "https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/INCENTIVE.webp"
                    }
                    width="70"
                    height="70"
                    alt="incentives"
                  />
                </div>
                <h2>Incentive</h2>
                <p style={{ fontWeight: 400, textAlign: "center" }}>
                  Members of Team Sports Palace get incentives for promoting the
                  players to buy from Sports Palace using their code at additional
                  discounted prices. These incentives will be in terms of points
                  linked to the purchases made through the portal using the
                  member's code.
                </p>
              </div>
              <div className="block-row full text-center">
                <div className="img-row text-center">
                  <img
                    src="https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/SPONSOR.webp"
                    alt="sponsorship"
                    width="70"
                    height="70"
                  />
                </div>
                <h2>Sponsorship</h2>
                <p style={{ textAlign: "center" }}>
                  Members of Team Sports Palace have a mutually beneficial
                  relationship. You support us and we will support you. The more
                  you help generate sales through using your code, more benefits
                  will flow and you may become eligible for HEAD equipment
                  sponsorship.
                </p>
              </div>
              <div
                className="block-row text-center"
                style={{
                  textAlign: "center",
                }}
              >
                <div className="img-row text-center">
                  <img
                    src="https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/PROMOTION.webp"
                    alt="promotion"
                    width="70"
                    height="70"
                  />
                </div>
                <h2>Promotion</h2>
                <p>
                  Whenever your player or student buys the product under your
                  discount code, he/she will be eligible for the additional
                  discount. So, your academy/club also gets promoted among the
                  students for the additional discount benefit they will get
                  through your code.
                </p>
              </div>
              <div className="block-row text-center ">
                <div className="img-row text-center">
                  <img
                    src="https://cdnmedia.racquets4u.com/media/iopt/wysiwyg/BRAND.webp"
                    alt="branding"
                    width="70"
                    height="70"
                  />
                </div>
                <h2>Branding</h2>
                <p style={{ textAlign: "center" }}>
                  Your academy/club will get branding material from Sports
                  Palace once a minimum level of purchases is made through your
                  code. These branding materials will include banners, Green
                  shades, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="p-3"
          >
            <fieldset className="fieldset create info mt-3 mb-3">
              <legend className="legend d-flex flex-direction-column justify-content-start align-items-center">
                <span className="text-left" style={{margin:"10px 25px"}}><b>Personal Information</b></span>
              </legend>
              <br />
              <Form.Group
                as={Row}
                className="d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  First Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={formData.firstName} // Add value attribute
                    onChange={handleChange}
                    required
                    id="firstName"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a first name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Last Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    id="lastName"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a last name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Date of Birth <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                    id="dob"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid date of birth.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Gender <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                    id="gender"
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Not Specified">Not Specified</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a gender.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </fieldset>

            {/* Coach Information */}
            <fieldset className="fieldset create info text-left mt-3 ">
              <legend className="legend d-flex flex-direction-column justify-content-start align-items-center">
                <span  className="text-left" style={{margin:"10px 25px"}}><b>Coach Information</b></span>
              </legend>
              <br />

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Game <span className="text-danger">*</span>
                </Form.Label>
                <Col
                  sm={8}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Form.Check
                    type="radio"
                    label="Tennis"
                    id="tennis"
                    name="coachGame"
                    value="Tennis"
                    className="px-3"
                    checked={formData.coachGame === "Tennis"} // Check if Tennis is selected
                    onChange={handleChange} // Add onChange event handler
                    required
                  />
                  <Form.Check
                    type="radio"
                    label="Squash"
                    id="squash"
                    name="coachGame"
                    value="Squash"
                    className="px-3"
                    checked={formData.coachGame === "Squash"} // Check if Squash is selected
                    onChange={handleChange} // Add onChange event handler
                    required
                  />
                  <Form.Check
                    type="radio"
                    label="Badminton"
                    id="badminton"
                    name="coachGame"
                    value="Badminton"
                    className="px-3"
                    checked={formData.coachGame === "Badminton"} // Check if Badminton is selected
                    onChange={handleChange} // Add onChange event handler
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select a game.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Add more Form Groups for other coach information */}
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Academy Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Academy Name"
                    required
                    value={formData.academyName}
                    onChange={handleChange}
                    id="academyName"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the academy name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Academy Address <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Academy Address"
                    required
                    value={formData.academyAddress}
                    onChange={handleChange}
                    id="academyAddress"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the academy name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Contact Number <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="tel"
                    required
                    value={formData.contactNumber}
                    onChange={handleChange}
                    id="contactNumber"
                    placeholder="Contact Number"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the academy name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Pan Card No <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    required
                    value={formData.panCardNo}
                    onChange={handleChange}
                    id="panCardNo" // Correct id
                    placeholder="Pan Card No"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  City <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    required
                    value={formData.city}
                    onChange={handleChange}
                    id="city" // Correct id
                    placeholder="City"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the city.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  State <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    required
                    value={formData.state}
                    onChange={handleChange}
                    id="state"
                    placeholder="State"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the academy name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  No. of Students <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    required
                    value={formData.noOfStudents}
                    onChange={handleChange}
                    id="noOfStudents"
                    placeholder="No Of Students"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the number of students.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  No. of Tournament Players{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    required
                    value={formData.noOfTournamentPlayers}
                    onChange={handleChange}
                    id="noOfTournamentPlayers"
                    placeholder="No Of Tournament Players"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the number of tournament players.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Referred By {" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    value={formData.referredBy}
                    onChange={handleChange}
                    id="referredBy"
                    placeholder="Referred By"
                  />
                </Col>
              </Form.Group>

              {/* Add more Form Groups for other coach information */}
            </fieldset>

            {/* Sign-in Information */}
            <fieldset
              className="fieldset create account mt-3"
              data-hasrequired="* Required Fields"
            >
              <legend className="legend d-flex flex-direction-column justify-content-start align-items-center">
                <span  className="text-left" style={{margin:"10px 25px"}}><b>Sign-in Information</b></span>
              </legend>
              <br />
              <Form.Group
                as={Row}
                className="d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    id="email"
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                <Form.Label column sm={3}>
                  Password <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    id="password"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Add more Form Groups for other sign-in information */}

              {/* Captcha */}
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-direction-column justify-content-center align-items-center"
              >
                {/* <Col sm={{ span: 10, offset: 2 }}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </Col> */}
              </Form.Group>
            </fieldset>
            
            <div className="actions-toolbar col-lg-12 mt-3 mb-3  ">
              <div className="success">
                <Button type="submit" className="btn btn-success col-lg-12 ">
                  Create an Account
                </Button>
              </div>
              <div className="success">{/* Back button goes here */}</div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

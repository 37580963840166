import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

export default function UserEditScreen() {
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: userId } = params;
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [defaultOtp, setDefaultOtp] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStoreManager, setIsStoreManager] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setName(data.name);
        setEmail(data.email);
        setMobile(data.mobile);
        setDefaultOtp(data.defaultOtp);
        setIsAdmin(data.isAdmin);
        setIsStoreManager(data.isStoreManager);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userId, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `/api/users/${userId}`,
        { _id: userId, name, email, mobile, defaultOtp, isAdmin, isStoreManager },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('User updated successfully');
      navigate('/admin/users');
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  return (
    <Container className="container">
      <Helmet>
        <title>Edit User ${userId}</title>
      </Helmet>


      <Row className="mt-3 mb-3">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className='text-center' to="/">
              <i className="fas fa-home"></i> Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className='text-center' to="/admin/users">Users</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <h6>Edit User : {name}</h6>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Row className="mt-3 mb-3 bg-light text-dark">
          <Col md={6} sm={12} className='p-3 '>
            <Form onSubmit={submitHandler}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  value={mobile}
                  type="number"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="otp">
                <Form.Label>Default Otp</Form.Label>
                <Form.Control
                  value={defaultOtp}
                  type="number"
                  onChange={(e) => setDefaultOtp(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}

                />
              </Form.Group>

              <Form.Check
                className="mb-3"
                type="checkbox"
                id="isAdmin"
                label="isAdmin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />

              <Form.Check
                className="mb-3"
                type="checkbox"
                id="isStoreManager"
                label="isStoreManager"
                checked={isStoreManager}
                onChange={(e) => setIsStoreManager(e.target.checked)}
              />

              <div className="mb-3">
                <Button disabled={loadingUpdate} type="submit">
                  Update
                </Button>
                {loadingUpdate && <LoadingBox></LoadingBox>}
              </div>
            </Form>
          </Col>
        </Row>

      )}
    </Container>
  );
}

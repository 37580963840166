import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Card, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Store } from "../Store";
import { toast } from 'react-toastify';
import { getError } from "../utils";

import Variant from "./Variant";

export default function ProductVariant(props) {
    const { state } = useContext(Store);
    const { userInfo } = state;
    const { product } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [variantGroupsList, setVariantGroupsList] = useState('');
    const [variantsList, setVariantsList] = useState('');
    const [variation, setVariation] = useState('');
    const [sku, setSku] = useState('');
    const [price, setPrice] = useState('');
    const [priceMrp, setPriceMrp] = useState('');
    const [productVariations, setProductVariations] = useState('');
    
    function order(a, b) {
        return a.variant.name > b.variant.name ;
    }
    
    const fetchProductData = async () => {

        try {
            const { data } = await axios.get(`/api/products/${product._id}`);
            setProductVariations(data.variations);
        } catch (err) {

        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/api/variants/`, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
                setVariantsList(data);
            } catch (err) { }
        };
        fetchData();
        setProductVariations(product.variations);
    }, []);

    const submitHandler = async () => {
        try {
            await axios.put(
                `/api/products/${product._id}/variant`,
                {
                    variation,
                    type: 'add'
                },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            toast.success('Variant Created Successfully');
            fetchProductData();
        } catch (err) {
            toast.error(getError(err));

        }
    };

    return (
        <>
            <Card className="mt-3">
                <Card.Header className="mt-auto">
                    <span className="mt-auto">
                        Variations
                    </span>
                    <span>
                        <Button className="float-end" type="button" size='sm' variant='dark' onClick={handleShow}>
                            <i className="fas fa-plus-circle me-1"></i>
                            Add New Variant
                        </Button>
                    </span>

                </Card.Header>

            </Card>
            {productVariations && productVariations.map((item) => (

                <Row className="mb-3">
                    <Variant variant={item} productId={product._id} />
                </Row>
            ))
            }

            <Modal style={{ margin: 'auto' }} aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} size="md" centered >
                <Modal.Header closeButton className="text-dark">
                    <Modal.Title><h6>Add New Variant</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark">
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="variant">
                            <Form.Label>Variant</Form.Label>
                            <Form.Control as="select"
                                custom
                                onChange={(e) => setVariation(e.target.value)}
                                required
                            >
                                <option >Select Variant</option>
                                {variantsList.length > 0 && variantsList.filter(x => x.variantGroup._id === product.variantPrimary._id).sort().map((item) => {
                                    return (
                                        <option key={item} value={item._id}>
                                            {item.name}
                                        </option>
                                    )
                                })
                                }
                            </Form.Control>
                        </Form.Group>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" size="sm" onClick={submitHandler}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../../Store';
import { getError } from '../../utils';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Button from 'react-bootstrap/Button';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import SideBar from '../../components/SideBar';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};

export default function ProductCreateScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
    });
  const [categoriesList, setCategoriesList] = useState('');
  const [brandsList, setBrandsList] = useState('');
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [sku, setSku] = useState('');
  const [hsn, setHsn] = useState('');
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [price, setPrice] = useState('');
  const [priceMrp, setPriceMrp] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/categories/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setCategoriesList(data);
      } catch (err) { }

      try {
        const { data } = await axios.get(`/api/brands/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setBrandsList(data);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) { }
    };
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.post(
        `/api/products/create`,
        {
          name,
          slug,
          image,
          images,
          brand,
          category,
          description,
          quantity,
          price,
          priceMrp,
          hsn,

        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Category Created Successfully');
      navigate('/admin/product/list');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });

      if (forImages) {
        setImages([...images, data.secure_url]);
      } else {
        setImage(data.secure_url);
      }

      toast.success('Image uploaded successfully.');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };
  const deleteFileHandler = async (fileName, f) => {

    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully');
  };
  return (
    <Container className='ps-1 pe-3 mw-100'>

      <Helmet>
        <title>Create New Product</title>
      </Helmet>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>
        <Col md={10}>
          <Row className="mt-3 mb-3">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className='text-center' to="/">
                  <i className="fas fa-home"></i> Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className='text-center' to="/admin/product/list">Products</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Create New Product
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <h5>Create New Product</h5>

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <div className='text-dark bg-light p-3 rounded'>

              <Form onSubmit={submitHandler}>
                <Row className="mt-3 mb-3">
                  <Col md={6} sm={12} className='px-5'>
                    <Row>
                    <Col md={6} sm={12}>
                    <Form.Group className="mb-3" controlId="category">
                      <Form.Label>Category</Form.Label>
                      <Form.Control as="select"
                        custom
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        <option >Select Category</option>
                        {categoriesList.length > 0 && categoriesList.map((item) => {
                          return (
                            <option key={item} value={item._id}>
                              {item.name}
                            </option>
                          )
                        })
                        }
                      </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      
                    <Form.Group className="mb-3" controlId="brand">
                      <Form.Label>Brand</Form.Label>
                      <Form.Control as="select"
                        custom
                        onChange={(e) => setBrand(e.target.value)}
                        required
                      >
                        <option >Select Brand</option>
                        {brandsList.length > 0 && brandsList.map((item) => {
                          return (
                            <option key={item} value={item._id}>
                              {item.name}
                            </option>
                          )
                        })
                        }
                      </Form.Control>
                    </Form.Group>

                      </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="slug">
                      <Form.Label>Slug</Form.Label>
                      <Form.Control
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="sku">
                      <Form.Label>Sku</Form.Label>
                      <Form.Control
                        value={sku}
                        onChange={(e) => setSku(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="sku">
                      <Form.Label>Hsn</Form.Label>
                      <Form.Control
                        value={hsn}
                        onChange={(e) => setHsn(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        as="textarea" rows={3}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="quantity">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} sm={12} className='px-5'>
                  <Row>
                    <Col md={6} sm={12} >
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>M.R.P</Form.Label>
                      <Form.Control
                        value={priceMrp}
                        onChange={(e) => setPriceMrp(e.target.value)}
                      />
                    </Form.Group>
                    </Col>
                    <Col md={6} sm={12} >
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="image">
                      <Form.Label>Image</Form.Label>
                      <img
                        src={image}
                        className="img-fluid rounded img-thumbnail"
                      ></img>{' '}
                      <Form.Control
                        disabled='true'
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="imageFile">
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control type="file" onChange={uploadFileHandler} />
                      {loadingUpload && <LoadingBox></LoadingBox>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="additionalImage">
                      <Form.Label>Additional Images</Form.Label>
                      {images.length === 0 && <MessageBox>No image</MessageBox>}
                      <ListGroup variant="flush">
                        {images.length > 0 && images.map((x) => (
                          <ListGroup.Item className='bg-light' key={x}>
                            <img
                              src={x}
                              className="img-fluid rounded img-thumbnail"
                            ></img>{' '}

                            <Button variant="light" onClick={() => deleteFileHandler(x)}>
                              <i className="fa fa-times-circle"></i>
                            </Button>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="additionalImageFile">
                      <Form.Label>Upload Aditional Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(e) => uploadFileHandler(e, true)}
                      />
                    </Form.Group>


                  </Col>

                </Row>
                <Row className="mt-3 mb-3 px-5 w-50">
                  <Button disabled={loadingUpdate} type="submit" variant='success'>
                    Submit
                  </Button>
                  {loadingUpdate && <LoadingBox></LoadingBox>}
                </Row>
              </Form>
            </div>

          )}
        </Col>
      </Row>

    </Container>
  );
}

import React, { useContext, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import axios from 'axios';
import { Link } from 'react-router-dom';
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };

    default:
      return state;
  }
};

export default function ProfileScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState(userInfo.name);
  const [mobile, setMobile] = useState(userInfo.mobile);
  const [email, setEmail] = useState(userInfo.email);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });
  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        '/api/users/profile',
        {
          name,
          email,
          mobile,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success('User updated successfully');
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
      });
      toast.error(getError(err));
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 my-lg-0 my-md-1">
          <div id="sidebarr" className="bg-dark d-none d-lg-block">
            <div className="h4 text-white">Account</div>
            <ul>
              <li>
                <Link to="/orders">
                  <a className="text-decoration-none d-flex align-items-start">
                    <div className="fas fa-box-open pt-2 me-3"></div>
                    <div className="d-flex flex-column">
                      <div className="link">My Orders</div>
                      <div className="link-desc">View & Manage orders</div>
                    </div>
                  </a>
                </Link>

              </li>

              <li>
                <Link to="/profile">
                  <a className="button text-decoration-none d-flex align-items-start">
                    <div className="far fa-user pt-2 me-3"></div>
                    <div className="d-flex flex-column">
                      <div className="link">My Profile</div>
                      <div className="link-desc">Profile and Address Details</div>
                    </div>
                  </a>
                </Link>

              </li>
              <li>
                <Link>
                  <a onClick={signoutHandler} className="text-decoration-none d-flex align-items-start">
                    <div className="fas fa-sign-out-alt pt-2 me-3"></div>
                    <div className="d-flex flex-column">
                      <div className="link">Log-Out</div>
                    </div>
                  </a>
                </Link>

              </li>
            </ul>
          </div>

          <div className="d-lg-none row px-3 py-1 border-1 ">
            <div className='col col-4'>
              <Link to="/orders">
                <button type="button" className="btn btn-sm btn-outline-secondary">

                  My Orders</button>
              </Link>
            </div>

            <div className='col col-4'>
              <Link to="/profile">
                <button type="button" className="btn btn-sm btn-outline-secondary">
                  My Profile</button>
              </Link>
            </div>

            <div className='col col-4' onClick={signoutHandler}>
              <Link>
                <button type="button" className="btn btn-sm btn-outline-secondary">
                  Log Out</button>
              </Link>

            </div>
          </div>
        </div>
        <div className="col-lg-9 my-lg-0 my-1">
          <div id="main-content" className="bg-dark text-light">
            <div className="container small-container">
              <Helmet>
                <title>User Profile</title>
              </Helmet>
              <h4 className="my-3">User Profile</h4>
              <form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="number"
                    value={mobile}
                    disabled
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
       
                <div className="mb-3">
                  <Button variant='success' type="submit">Update</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
